/**
 * @fileoverview gRPC-Web generated client stub for cherry.item
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as cherry_item_item_pb from '../../cherry/item/item_pb';


export class ItemServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateItemCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_item_item_pb.ItemCategory,
    (request: cherry_item_item_pb.ItemCategory) => {
      return request.serializeBinary();
    },
    cherry_item_item_pb.ItemCategory.deserializeBinary
  );

  createItemCategory(
    request: cherry_item_item_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null): Promise<cherry_item_item_pb.ItemCategory>;

  createItemCategory(
    request: cherry_item_item_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemCategory) => void): grpcWeb.ClientReadableStream<cherry_item_item_pb.ItemCategory>;

  createItemCategory(
    request: cherry_item_item_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.item.ItemService/CreateItemCategory',
        request,
        metadata || {},
        this.methodInfoCreateItemCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.item.ItemService/CreateItemCategory',
    request,
    metadata || {},
    this.methodInfoCreateItemCategory);
  }

  methodInfoFindItemCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_item_item_pb.ItemCategory,
    (request: cherry_item_item_pb.ItemCategoryQuery) => {
      return request.serializeBinary();
    },
    cherry_item_item_pb.ItemCategory.deserializeBinary
  );

  findItemCategory(
    request: cherry_item_item_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_item_item_pb.ItemCategory>;

  findItemCategory(
    request: cherry_item_item_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemCategory) => void): grpcWeb.ClientReadableStream<cherry_item_item_pb.ItemCategory>;

  findItemCategory(
    request: cherry_item_item_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.item.ItemService/FindItemCategory',
        request,
        metadata || {},
        this.methodInfoFindItemCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.item.ItemService/FindItemCategory',
    request,
    metadata || {},
    this.methodInfoFindItemCategory);
  }

  methodInfoUpdateItemCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_item_item_pb.ItemCategory,
    (request: cherry_item_item_pb.ItemCategory) => {
      return request.serializeBinary();
    },
    cherry_item_item_pb.ItemCategory.deserializeBinary
  );

  updateItemCategory(
    request: cherry_item_item_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null): Promise<cherry_item_item_pb.ItemCategory>;

  updateItemCategory(
    request: cherry_item_item_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemCategory) => void): grpcWeb.ClientReadableStream<cherry_item_item_pb.ItemCategory>;

  updateItemCategory(
    request: cherry_item_item_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.item.ItemService/UpdateItemCategory',
        request,
        metadata || {},
        this.methodInfoUpdateItemCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.item.ItemService/UpdateItemCategory',
    request,
    metadata || {},
    this.methodInfoUpdateItemCategory);
  }

  methodInfoFindItemCategoryList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_item_item_pb.ItemCategoryListed,
    (request: cherry_item_item_pb.ItemCategoryQuery) => {
      return request.serializeBinary();
    },
    cherry_item_item_pb.ItemCategoryListed.deserializeBinary
  );

  findItemCategoryList(
    request: cherry_item_item_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_item_item_pb.ItemCategoryListed>;

  findItemCategoryList(
    request: cherry_item_item_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemCategoryListed) => void): grpcWeb.ClientReadableStream<cherry_item_item_pb.ItemCategoryListed>;

  findItemCategoryList(
    request: cherry_item_item_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemCategoryListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.item.ItemService/FindItemCategoryList',
        request,
        metadata || {},
        this.methodInfoFindItemCategoryList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.item.ItemService/FindItemCategoryList',
    request,
    metadata || {},
    this.methodInfoFindItemCategoryList);
  }

  methodInfoFindItemCategoryTree = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_item_item_pb.ItemCategoryListed,
    (request: cherry_item_item_pb.ItemCategoryQuery) => {
      return request.serializeBinary();
    },
    cherry_item_item_pb.ItemCategoryListed.deserializeBinary
  );

  findItemCategoryTree(
    request: cherry_item_item_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_item_item_pb.ItemCategoryListed>;

  findItemCategoryTree(
    request: cherry_item_item_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemCategoryListed) => void): grpcWeb.ClientReadableStream<cherry_item_item_pb.ItemCategoryListed>;

  findItemCategoryTree(
    request: cherry_item_item_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemCategoryListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.item.ItemService/FindItemCategoryTree',
        request,
        metadata || {},
        this.methodInfoFindItemCategoryTree,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.item.ItemService/FindItemCategoryTree',
    request,
    metadata || {},
    this.methodInfoFindItemCategoryTree);
  }

  methodInfoCreateItemAttr = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_item_item_pb.ItemAttr,
    (request: cherry_item_item_pb.ItemAttr) => {
      return request.serializeBinary();
    },
    cherry_item_item_pb.ItemAttr.deserializeBinary
  );

  createItemAttr(
    request: cherry_item_item_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null): Promise<cherry_item_item_pb.ItemAttr>;

  createItemAttr(
    request: cherry_item_item_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemAttr) => void): grpcWeb.ClientReadableStream<cherry_item_item_pb.ItemAttr>;

  createItemAttr(
    request: cherry_item_item_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemAttr) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.item.ItemService/CreateItemAttr',
        request,
        metadata || {},
        this.methodInfoCreateItemAttr,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.item.ItemService/CreateItemAttr',
    request,
    metadata || {},
    this.methodInfoCreateItemAttr);
  }

  methodInfoFindItemAttr = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_item_item_pb.ItemAttr,
    (request: cherry_item_item_pb.ItemAttrQuery) => {
      return request.serializeBinary();
    },
    cherry_item_item_pb.ItemAttr.deserializeBinary
  );

  findItemAttr(
    request: cherry_item_item_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_item_item_pb.ItemAttr>;

  findItemAttr(
    request: cherry_item_item_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemAttr) => void): grpcWeb.ClientReadableStream<cherry_item_item_pb.ItemAttr>;

  findItemAttr(
    request: cherry_item_item_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemAttr) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.item.ItemService/FindItemAttr',
        request,
        metadata || {},
        this.methodInfoFindItemAttr,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.item.ItemService/FindItemAttr',
    request,
    metadata || {},
    this.methodInfoFindItemAttr);
  }

  methodInfoUpdateItemAttr = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_item_item_pb.ItemAttr,
    (request: cherry_item_item_pb.ItemAttr) => {
      return request.serializeBinary();
    },
    cherry_item_item_pb.ItemAttr.deserializeBinary
  );

  updateItemAttr(
    request: cherry_item_item_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null): Promise<cherry_item_item_pb.ItemAttr>;

  updateItemAttr(
    request: cherry_item_item_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemAttr) => void): grpcWeb.ClientReadableStream<cherry_item_item_pb.ItemAttr>;

  updateItemAttr(
    request: cherry_item_item_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemAttr) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.item.ItemService/UpdateItemAttr',
        request,
        metadata || {},
        this.methodInfoUpdateItemAttr,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.item.ItemService/UpdateItemAttr',
    request,
    metadata || {},
    this.methodInfoUpdateItemAttr);
  }

  methodInfoFindItemAttrList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_item_item_pb.ItemAttrListed,
    (request: cherry_item_item_pb.ItemAttrQuery) => {
      return request.serializeBinary();
    },
    cherry_item_item_pb.ItemAttrListed.deserializeBinary
  );

  findItemAttrList(
    request: cherry_item_item_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_item_item_pb.ItemAttrListed>;

  findItemAttrList(
    request: cherry_item_item_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemAttrListed) => void): grpcWeb.ClientReadableStream<cherry_item_item_pb.ItemAttrListed>;

  findItemAttrList(
    request: cherry_item_item_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_item_item_pb.ItemAttrListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.item.ItemService/FindItemAttrList',
        request,
        metadata || {},
        this.methodInfoFindItemAttrList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.item.ItemService/FindItemAttrList',
    request,
    metadata || {},
    this.methodInfoFindItemAttrList);
  }

}

