import config from '@/config'
import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload, Typography } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import React, { useState } from 'react'

const OssUpload: React.FC = () => {
  const [fileList, setFileList] = useState([])
  const [urls, setUrls] = useState([])
  function handleChange(info: UploadChangeParam<UploadFile<any>>) {
    let fileList = [...info.fileList]
    const urls = []
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.fileUrl
        urls.push(`<img alt="商品介绍图" src="${file.response.fileUrl}" />`)
      }
      return file
    })
    setFileList(fileList)
    setUrls(urls)
  }
  return (
    <div>
      <div>
        <Upload
          onChange={handleChange}
          action={`/_files/upload?x-token-value=${localStorage.getItem(config.authKey)}`}
          fileList={fileList}
          directory>
          <Button icon={<UploadOutlined />}>上传资源文件夹</Button>
        </Upload>
      </div>
      {urls.length ? (
        <div>
          {urls.map((item, index) => (
            <>
              <span>{index + 1}</span>
              <Typography.Paragraph copyable>{item}</Typography.Paragraph>
            </>
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default OssUpload
