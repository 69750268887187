import config from '@/config'
import { LOGIN } from '@/router/config/login/path'
import { message } from 'antd'
import grpc from 'grpc-web'
import { getLocalStorage } from '../storage'
import { getHistory } from './getHistory'
import { Base64 } from 'js-base64'

export const SimpleUnaryInterceptor = function() {}

SimpleUnaryInterceptor.prototype.intercept = function(request: grpc.Request<any, any>, invoker) {
  const metadata = request.getMetadata()
  const token = getLocalStorage(config.authKey)
  metadata['Authorization'] = `Bearer ${token}`
  return invoker(request).then(
    (res: grpc.UnaryResponse<any, any>) => res,
    (err: grpc.Error) => {
      if (err.code === grpc.StatusCode.UNAUTHENTICATED) {
        // 未登录
        message.warn('登录已失效，请重新登录')
        const history = getHistory()
        history.push(LOGIN)
      } else {
        message.error((err as any).metadata?.errMsg ? Base64.decode((err as any).metadata.errMsg) : err.message)
      }
    },
  )
}
