/**
 * @fileoverview gRPC-Web generated client stub for cherry.market
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as cherry_market_market_pb from '../../cherry/market/market_pb';


export class MarketServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoFindMarketList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_market_market_pb.MarketListed,
    (request: cherry_market_market_pb.MarketQuery) => {
      return request.serializeBinary();
    },
    cherry_market_market_pb.MarketListed.deserializeBinary
  );

  findMarketList(
    request: cherry_market_market_pb.MarketQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_market_market_pb.MarketListed>;

  findMarketList(
    request: cherry_market_market_pb.MarketQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_market_market_pb.MarketListed) => void): grpcWeb.ClientReadableStream<cherry_market_market_pb.MarketListed>;

  findMarketList(
    request: cherry_market_market_pb.MarketQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_market_market_pb.MarketListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.market.MarketService/FindMarketList',
        request,
        metadata || {},
        this.methodInfoFindMarketList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.market.MarketService/FindMarketList',
    request,
    metadata || {},
    this.methodInfoFindMarketList);
  }

  methodInfoFindMarketMonitorList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_market_market_pb.MarketMonitorListed,
    (request: cherry_market_market_pb.MarketMonitorQuery) => {
      return request.serializeBinary();
    },
    cherry_market_market_pb.MarketMonitorListed.deserializeBinary
  );

  findMarketMonitorList(
    request: cherry_market_market_pb.MarketMonitorQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_market_market_pb.MarketMonitorListed>;

  findMarketMonitorList(
    request: cherry_market_market_pb.MarketMonitorQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_market_market_pb.MarketMonitorListed) => void): grpcWeb.ClientReadableStream<cherry_market_market_pb.MarketMonitorListed>;

  findMarketMonitorList(
    request: cherry_market_market_pb.MarketMonitorQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_market_market_pb.MarketMonitorListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.market.MarketService/FindMarketMonitorList',
        request,
        metadata || {},
        this.methodInfoFindMarketMonitorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.market.MarketService/FindMarketMonitorList',
    request,
    metadata || {},
    this.methodInfoFindMarketMonitorList);
  }

}

