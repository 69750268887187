import React from 'react'
import { Steps } from 'antd'
import styles from './style.module.scss'
import { IRouteConfig } from '@/router/typing'
import { Route, Switch } from 'react-router-dom'
import RouteWithSubRoutes from '@/router/routeWithSubRoute'
import NoDataPage from '@/pages/other/404'
import useSearchParams from '@/hooks/useSearchParams'

const steps = [
  {
    title: '设置商品信息',
  },
  {
    title: '设置规格',
  },
  {
    title: '设置SKU',
  },
]

interface IProps {
  routes?: IRouteConfig[]
}

const GoodsAddLayout: React.FC<IProps> = ({ routes }) => {
  const current = useSearchParams('step')
  return (
    <>
      <Steps current={Number(current)}>
        {steps.map(step => (
          <Steps.Step key={step.title} title={step.title} />
        ))}
      </Steps>
      <div className={styles.sectionWrapper}>
        <Switch>
          {routes.map(route => (
            <RouteWithSubRoutes key={route.path} {...route} />
          ))}
          <Route component={NoDataPage} />
        </Switch>
      </div>
    </>
  )
}

export default GoodsAddLayout
