import { Model } from 'wbd-frontend-kit'
import { ProductCategoryQuery, ProductCategoryListed, ProductCategory } from '@/proto/cherry/product/product_pb'
import makeRequest from '@/utils/request/makeRequest'

import { productServiceClient } from '@/clients'

export type IProductCategory = ProductCategory.AsObject
export interface IProductCategoryTableRow {
  key: number | string
  parentKey: number | string
  name: string
  level: number
  status?: number | string
  children?: IProductCategoryTableRow[]
}

export enum Status {
  Enable = 1,
  Disable = 99,
}

export const namespace = 'member-provider-classification-management'
export interface ICategoryManage {
  categorys: IProductCategoryTableRow[]
}

const initState: ICategoryManage = {
  categorys: [],
}

function formatCatoryTableData(categorys: IProductCategory[]) {
  const result = []
  if (!categorys || categorys.length === 0) {
    return result
  }
  for (const category of categorys) {
    if (category.parentId === 0) {
      result.push({
        parentKey: category.parentId,
        key: category.id,
        level: 1,
        name: category.name,
        status: category.status,
        children: [],
      })
    }
  }
  for (const record of categorys) {
    if (record.parentId !== 0) {
      const item = result.find(item => item.key === record.parentId)
      if (item) {
        item.children.push({ key: record.id, level: 2, name: record.name, parentKey: item.key, status: record.status })
      }
    }
  }
  return result
}

export default {
  namespace,
  state: initState,
  effects: {
    *fetchProductCategoryList({ payload: { parentId, merchantId } }, { call, put }) {
      try {
        const req = makeRequest<ProductCategoryQuery>(ProductCategoryQuery, { parentId, merchantId })
        const res: ProductCategoryListed = yield call(productServiceClient.findProductCategoryList.bind(productServiceClient), req)
        const obj = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            categorys: formatCatoryTableData(obj.recordsList),
          },
        })
      } catch (error) {}
    },
    // 新增一个一级分类或二级分类
    *createProductCategory({ payload }, { call, put }) {
      try {
        const req = makeRequest<ProductCategory>(ProductCategory, payload)
        const res: ProductCategory = yield call(productServiceClient.createProductCategory.bind(productServiceClient), req)
        const obj = res.toObject()
        // 新建成功，重新请求数据
        if (obj.id) {
          yield put({
            type: 'fetchProductCategoryList',
            payload: {
              parentId: '-1',
              merchantId: payload.merchantId,
            },
          })
        }
      } catch (error) {}
    },
    // 更改名称或状态
    *updateProductCategory({ payload }, { call, put }) {
      try {
        const req = makeRequest<ProductCategory>(ProductCategory, payload)
        const res: ProductCategory = yield call(productServiceClient.updateProductCategory.bind(productServiceClient), req)
        const obj = res.toObject()
        // 新建成功，重新请求数据
        if (obj.id) {
          yield put({
            type: 'fetchProductCategoryList',
            payload: {
              parentId: '-1',
              merchantId: payload.merchantId,
            },
          })
        }
      } catch (error) {}
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload }
    },
  },
} as Model
