import history from 'history'
let instance: any

export function setHistory(his) {
  instance = his
}
/**
 * 在model和组件以外的环境使用react-router-dom提供的history
 * 在router初始化后保存一份history的引用
 */
export function getHistory(): history.History<unknown> {
  return instance
}
