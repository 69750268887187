import { basicServiceClient, productServiceClient } from '@/clients'
import {
  ItemCategoryQuery,
  ItemCategoryListed,
  ItemCategory,
  ProductCategoryQuery,
  ProductCategoryListed,
  ProductCategory,
  BrandQuery,
  BrandListed,
  Brand,
} from '@/proto/cherry/product/product_pb'
import { AreaQuery, AreaListed } from '@/proto/cherry/basic/basic_pb'
import makeRequest from '@/utils/request/makeRequest'
import { Model } from 'wbd-frontend-kit'
export const namespace = 'global'

interface ICategoryCascader {
  label: string
  value: string | number
  children?: ICategoryCascader[]
}
export interface IState {
  itemCategorys: ICategoryCascader[]
  productCategorys: ICategoryCascader[]
  areas: ICategoryCascader[]
  brands: Brand.AsObject[]
  selectedKeys: any[]
  openKeys: any[]
}

const initState: IState = {
  itemCategorys: [],
  productCategorys: [],
  areas: [],
  brands: [],
  selectedKeys: [],
  openKeys: [],
}

function formatCategorysData(categorys: ItemCategory.AsObject[] | ProductCategory.AsObject[]) {
  const result: ICategoryCascader[] = []
  if (!categorys || !categorys.length) {
    return result
  }
  for (const row of categorys) {
    if (row.parentId === 0) {
      result.push({ label: row.name, value: row.id, children: [{ label: '全部', value: 0 }] })
    }
  }
  for (const record of categorys) {
    if (record.parentId !== 0) {
      const parent = result.find(item => item.value === record.parentId)
      if (parent) {
        parent.children.push({ label: record.name, value: record.id })
      }
    }
  }
  return result
}

export default {
  namespace,
  state: initState,
  effects: {
    // 获取标准分类
    *queryItemCategoryList(_, { call, put }) {
      const req = makeRequest<ItemCategoryQuery>(ItemCategoryQuery, { status: 1, parentId: '-1', pageSize: 9999 })
      try {
        const res: ItemCategoryListed = yield call(productServiceClient.findItemCategoryList.bind(productServiceClient), req)
        const obj = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            itemCategorys: formatCategorysData(obj.recordsList),
          },
        })
      } catch (error) {}
    },
    // 获取销售分类
    *queryProductCategoryList({ payload }, { call, put }) {
      const req = makeRequest<ProductCategoryQuery>(ProductCategoryQuery, { status: 1, parentId: '-1', ...payload })
      try {
        const res: ProductCategoryListed = yield call(productServiceClient.findProductCategoryList.bind(productServiceClient), req)
        const obj = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            productCategorys: formatCategorysData(obj.recordsList),
          },
        })
      } catch (error) {}
    },
    // 获取产地信息
    *queryAreaList({ payload, callback }, { call, put, select }) {
      const req = makeRequest<AreaQuery>(AreaQuery, payload)
      const { areas }: IState = yield select(state => state[namespace])
      try {
        const res: AreaListed = yield call(basicServiceClient.findAreaList.bind(basicServiceClient), req)
        const obj = res.toObject()
        // todo: area接口部分完成，待调试
        if (payload.parentId === 0) {
          yield put({
            type: 'setState',
            payload: {
              areas: obj.recordsList.map(item => ({ label: item.currentValue, value: item.id, isLeaf: item.leaf })),
            },
          })
        } else {
          callback(obj.recordsList.map(item => ({ label: item.currentValue, value: item.id, isLeaf: item.leaf })))
          yield put({
            type: 'setState',
            pyload: {
              areas: [...areas],
            },
          })
        }
      } catch (error) {}
    },
    // 获取品牌列表
    *queryBrandList(_, { call, put }) {
      const req = makeRequest<BrandQuery>(BrandQuery, {})
      try {
        const res: BrandListed = yield call(productServiceClient.findBrandList.bind(productServiceClient), req)
        const obj = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            brands: obj.recordsList,
          },
        })
      } catch (error) {}
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload }
    },
  },
} as Model
