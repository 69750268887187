import clientWrapper from './utils/request/clientWrapper'
import { BasicServiceClient } from './proto/cherry/basic/BasicServiceClientPb'
import { EnterpriseServiceClient } from './proto/cherry/enterprise/EnterpriseServiceClientPb'
import { ItemServiceClient } from './proto/cherry/item/ItemServiceClientPb'
import { MarketServiceClient } from './proto/cherry/market/MarketServiceClientPb'
import { MsgServiceClient } from './proto/cherry/msg/MsgServiceClientPb'
import { ProductServiceClient } from './proto/cherry/product/ProductServiceClientPb'
import { SignServiceClient } from './proto/cherry/sign/SignServiceClientPb'
import { UserServiceClient } from './proto/cherry/user/UserServiceClientPb'
export const basicServiceClient = clientWrapper<BasicServiceClient>(BasicServiceClient)
export const enterpriseServiceClient = clientWrapper<EnterpriseServiceClient>(EnterpriseServiceClient)
export const itemServiceClient = clientWrapper<ItemServiceClient>(ItemServiceClient)
export const marketServiceClient = clientWrapper<MarketServiceClient>(MarketServiceClient)
export const msgServiceClient = clientWrapper<MsgServiceClient>(MsgServiceClient)
export const productServiceClient = clientWrapper<ProductServiceClient>(ProductServiceClient)
export const signServiceClient = clientWrapper<SignServiceClient>(SignServiceClient)
export const userServiceClient = clientWrapper<UserServiceClient>(UserServiceClient)
