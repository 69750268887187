import React from 'react'
import { namespace } from './model'
import styles from './style.module.scss'
import bg from './images/bg.png'
import { Button, Form, Input } from 'antd'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PROVIDER_MANAGEMENT } from '@/router/config/member-manage/path'

interface IFormData {
  name: string
  password: string
}

export const Login: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const loading = useSelector(state => (state as any).loading.effects[`${namespace}/login`])
  function handleFinish({ name, password }: IFormData) {
    dispatch({
      type: `${namespace}/login`,
      payload: {
        name,
        password,
      },
      callback: () => {
        history.push(PROVIDER_MANAGEMENT)
      },
    })
  }
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.left}>
        <div className={styles.top}>
          <div className={styles.title}>
            <p>全果链运营平台</p>
          </div>
          <Form<IFormData> className={styles.form} onFinish={handleFinish}>
            <Form.Item name="name" rules={[{ required: true, message: '请输入账户名' }]}>
              <Input prefix={<UserOutlined style={{ color: '#0A55EE' }} />} placeholder="请输入账户名" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: '请输入密码' }]}>
              <Input.Password prefix={<LockOutlined style={{ color: '#0A55EE' }} />} placeholder="请输入密码" />
            </Form.Item>
            <Form.Item style={{ paddingTop: 24 }}>
              <Button type="primary" block htmlType="submit" loading={loading}>
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className={styles.copyright}>
          <p>Copyright©2021 洪九果品股份有限公司</p>
        </div>
      </div>
      <img src={bg} alt="cover" className={styles.right} />
    </div>
  )
}

export default Login
