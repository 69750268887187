import { execFunction, Model } from 'wbd-frontend-kit'
import { Spu, SpuQuery } from '@/proto/cherry/product/product_pb'
import { StallQuery, StallListed, Stall } from '@/proto/cherry/enterprise/enterprise_pb'
import makeRequest from '@/utils/request/makeRequest'

import { enterpriseServiceClient, productServiceClient } from '@/clients'

export type ISpu = Spu.AsObject
export type IShop = Stall.AsObject

export const namespace = 'member-provider-goods-detail'
export interface ISpuDetail {
  spu: ISpu
  shops: IShop[]
}
const initState: ISpuDetail = {
  spu: new Spu().toObject(),
  shops: [],
}

export default {
  namespace,
  state: initState,
  effects: {
    *findSpu({ payload }, { call, put }) {
      try {
        const req = makeRequest<SpuQuery>(SpuQuery, payload)
        const res: Spu = yield call(productServiceClient.findSpu.bind(productServiceClient), req)
        const spu = res.toObject()
        const newSpu = {
          ...spu,
          imgUrl: [
            {
              name: 'avatar.jpg',
              url: spu.imgUrl,
              status: 'done',
              uid: '-1',
            },
          ],
        }
        yield put({
          type: 'setState',
          payload: {
            spu: newSpu,
          },
        })
      } catch (error) {}
    },
    *findShop({ payload }, { call, put }) {
      try {
        const req = makeRequest<StallQuery>(StallQuery, payload)
        const res: StallListed = yield call(enterpriseServiceClient.findStallList.bind(enterpriseServiceClient), req)
        const shops = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            shops: shops.recordsList,
          },
        })
      } catch (error) {}
    },
    *updateSpu({ payload, callback }, { call, put }) {
      try {
        const req = makeRequest<Spu>(Spu, payload)
        const res: Spu = yield call(productServiceClient.updateSpu.bind(productServiceClient), req)
        const spu = res.toObject()
        if (spu.id) {
          execFunction(callback)
        }
        // yield put({
        //   type: 'setState',
        //   payload: {
        //     spu,
        //   },
        // })
      } catch (error) {}
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload }
    },
  },
} as Model
