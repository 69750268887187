import React, { ReactText, useEffect, useMemo, useState } from 'react'
import { Layout, Spin, Menu, Breadcrumb, Space, Avatar, Dropdown } from 'antd'
import { MenuUnfoldOutlined, MenuFoldOutlined, FileOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons'
import { IRouteConfig } from '../../router/typing'
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import styles from './style.module.scss'
import RouteWithSubRoutes from '@/router/routeWithSubRoute'
import NoDataPage from '@/pages/other/404'
import { IDispatch, IDvaLoading } from '@/typings/model'
import { connect, useDispatch, useSelector } from 'react-redux'
import MenuConfig, { IMenuItem } from './menu-config'
import { findRouteConfigByPathName } from '@/router/utils/findRouteConfig'
import uniq from 'lodash/uniq'
import logo from './images/logo.png'
import logoSmall from './images/logo_img.png'
import config from '@/config'
import { LOGIN } from '@/router/config/login/path'
import { namespace, IState } from '@/pages/login/model'
import { namespace as globalNs, IState as GlobalState } from '@/models/global'
import useSearchParams from '@/hooks/useSearchParams'

const { Header, Sider, Content } = Layout
interface IProps extends IDispatch, IDvaLoading {
  routes: IRouteConfig[]
  isLogin?: boolean
  userAccount?: string
}

// 找到openKeys，这里可能有bug
function findOpenKeysBySelectedKeys(key: string, menus: IMenuItem[] = MenuConfig, parentKeys: string[] = []) {
  // console.log(key, parentKeys)
  for (const menu of menus) {
    if (menu.key === key && !menu.children) {
      return parentKeys
    }
    if (menu.children) {
      const keys = findOpenKeysBySelectedKeys(key, menu.children, [...parentKeys, menu.key])
      if (keys && keys.length > 0) {
        return keys
      }
    }
  }
  return []
}

// 通过path查找选中菜单，即便是未配置菜单的子页面
function findSelectedKeys(pathName: string, menus: IMenuItem[] = MenuConfig) {
  for (const menu of menus) {
    const finded = new RegExp(`^${menu.key}.*$`).test(pathName)
    if (!menu.children && finded) {
      return menu.key
    }
    if (menu.children) {
      // fixed: 只有匹配到了才返回值
      const key = findSelectedKeys(pathName, menu.children)
      if (key) {
        return key
      }
    }
  }
}
/**
 * 生成面包屑重定向参数
 * @param keys searchParams key列表
 * @param params 当前路由searchParams object
 */
function genBreadcrumbRedirectParams(keys: string[], params: any) {
  if (!keys || keys.length === 0) {
    return ''
  }
  const paramsStr = keys
    .map(key => {
      if (params[key]) {
        return `${key}=${params[key]}`
      }
      return ''
    })
    .filter(Boolean)
    .join('&')
  return paramsStr && `?${paramsStr}`
}

const BasicLayout: React.FC<IProps> = ({ loading, routes }) => {
  const [collapsed, setCollapsed] = useState(false)
  const { selectedKeys, openKeys }: GlobalState = useSelector(state => state[globalNs])
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const currentRoute = useMemo(() => findRouteConfigByPathName(location.pathname), [location.pathname])
  const params = useSearchParams()
  const { userInfo }: IState = useSelector(state => state[namespace])
  function setSelectedKeys(selectedKeys: any[]) {
    dispatch({
      type: `${globalNs}/setState`,
      payload: {
        selectedKeys,
      },
    })
  }
  function setOpenKeys(openKeys: any[]) {
    dispatch({
      type: `${globalNs}/setState`,
      payload: {
        openKeys,
      },
    })
  }
  useEffect(() => {
    const selectedKey = findSelectedKeys(location.pathname)
    const pathname = location.pathname
    const initialOpenKeys = findOpenKeysBySelectedKeys(pathname)
    setSelectedKeys([selectedKey])
    setOpenKeys(uniq([...openKeys, ...initialOpenKeys]))
  }, [location.pathname])
  useEffect(() => {
    dispatch({
      type: `${namespace}/fetchUserInfo`,
    })
  }, [])
  function toggleCollapsed() {
    setCollapsed(!collapsed)
  }
  function genMenuItem(menu: IMenuItem) {
    const MenuIcon = menu.icon || FileOutlined
    if (!menu.children) {
      return (
        <Menu.Item icon={<MenuIcon />} key={menu.key}>
          <Link to={menu.key}>{menu.title}</Link>
        </Menu.Item>
      )
    }
    return (
      <Menu.SubMenu icon={<MenuIcon />} key={menu.key} title={menu.title}>
        {menu.children.map(child => genMenuItem(child))}
      </Menu.SubMenu>
    )
  }
  function handleOpenChange(openKeys: ReactText[]) {
    setOpenKeys(openKeys)
  }
  function handleMenuClick(e) {
    if (e.key === '1') {
      localStorage.removeItem(config.authKey)
      history.push(LOGIN)
    }
  }
  // todo: 全局loading样式优化，否则可能会出现过多的内容遮挡
  return (
    <Layout className={styles.layoutContainer}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        {!collapsed ? (
          <div className={styles.title}>
            <img alt="logo" src={logo} />
          </div>
        ) : (
          <div className={styles.titleSmall}>
            <img alt="logo" src={logoSmall} />
          </div>
        )}
        <Menu
          theme="dark"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={handleOpenChange}
          selectedKeys={selectedKeys}
          // onSelect={({ selectedKeys }) => setSelectedKeys([...selectedKeys])}
        >
          {MenuConfig.map(genMenuItem)}
        </Menu>
      </Sider>
      <Layout>
        <Header className={styles.header}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, { className: styles.trigger, onClick: toggleCollapsed })}
          <Dropdown
            overlay={
              <Menu onClick={handleMenuClick}>
                <Menu.Item key="1" icon={<LogoutOutlined />}>
                  退出
                </Menu.Item>
              </Menu>
            }>
            <Space className={styles.userWrapper}>
              <Avatar icon={userInfo?.avatarUrl ? <img src={userInfo.avatarUrl} alt="avatar" /> : <UserOutlined />} />
              <span>{userInfo?.name}</span>
            </Space>
          </Dropdown>
        </Header>
        <Content className={styles.content}>
          <div className={styles.breadcrumbContainer}>
            {currentRoute?.breadcrumb && (
              <Breadcrumb>
                {currentRoute.breadcrumb.map(item => {
                  const searchParams = genBreadcrumbRedirectParams(item.params, params)
                  return (
                    <Breadcrumb.Item key={item.label}>
                      {item.redirect ? <Link to={`${item.redirect}${searchParams}`}>{item.label}</Link> : item.label}
                    </Breadcrumb.Item>
                  )
                })}
              </Breadcrumb>
            )}
          </div>
          <div id="pageWrap" className={styles.contentContainer}>
            {loading.global && (
              <div className={styles.loading}>
                <Spin tip="加载中..." size="large" />
              </div>
            )}
            <Switch>
              {routes.map(route => (
                <RouteWithSubRoutes key={route.path} {...route} />
              ))}
              <Route component={NoDataPage} />
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

const mapStateToProps = models => ({
  loading: models.loading,
})

export default connect(mapStateToProps)(BasicLayout)
