import { GOODS_ADD_BASIC, GOODS_DETAIL, SKU_MANAGEMENT, SPEC_MANAGEMENT } from '@/router/config/member-manage/path'
import { Button, Cascader, Input, Select, Space, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import React, { ReactText, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { usePagination } from 'wbd-frontend-kit'
import styles from './style.module.scss'
import { namespace, IState } from '@/models/global'
import { namespace as spuListNs, ISpuManage, ISpu } from './model'
import useSearchParams from '@/hooks/useSearchParams'

const Option = Select.Option

function getCategoryIdsList(ids: ReactText[]) {
  if (ids[0] === 0) {
    return [0, 0, 0]
  }
  if (ids.length === 2) {
    return [...ids, 0]
  }
  return ids
}

const GoodsManage: React.FC = () => {
  const columns: ColumnsType<ISpu> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: '商品销售名称',
      render: (val, record) => <Link to={`${GOODS_DETAIL}?goodsId=${record.id}&merchantId=${id}`}>{val}</Link>,
    },
    {
      key: 'shop',
      dataIndex: 'stallName',
      title: '所属档口',
    },
    {
      key: 'classify1',
      dataIndex: 'itemCategoryNamesList',
      title: '一级平台分类',
      render: val => (val ? val[0] : ''),
    },
    {
      key: 'classify2',
      dataIndex: 'itemCategoryNamesList',
      title: '二级平台分类',
      render: val => (val ? val[1] : ''),
    },
    {
      key: 'launchCount',
      dataIndex: 'skuLaunchCount',
      title: '上架SKU数',
      align: 'right',
    },
    {
      key: 'enableCount',
      dataIndex: 'skuEnableCount',
      title: '启用SKU数',
      align: 'right',
    },
    {
      key: 'operations',
      title: '操作',
      dataIndex: 'id',
      align: 'center',
      render: goodsId => (
        <>
          <Link to={`${GOODS_DETAIL}?goodsId=${goodsId}&merchantId=${id}`}>商品详情</Link> |{' '}
          <Link to={`${SPEC_MANAGEMENT}?goodsId=${goodsId}&merchantId=${id}`}>规格管理</Link> |{' '}
          <Link to={`${SKU_MANAGEMENT}?goodsId=${goodsId}&merchantId=${id}`}>SKU管理</Link>
        </>
      ),
    },
  ]
  const [status, setStatus] = useState('0')
  const [classify, setClassify] = useState<ReactText[]>([])
  const [keywords, setKeywords] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    dispatch({
      type: `${namespace}/queryItemCategoryList`,
    })
  }, [])
  const [page, size, handleChange, handleShowSizeChange, reset] = usePagination({ page: 1, size: 10 })
  const { itemCategorys }: IState = useSelector(state => state[namespace])
  const { spus, totalCount, merchant }: ISpuManage = useSelector(state => state[spuListNs])
  const id = useSearchParams('merchantId')
  useEffect(() => {
    dispatch({
      type: `${spuListNs}/fetchSpuList`,
      payload: {
        merchantId: id,
        pageCurrent: page,
        pageSize: size,
        itemCategoryIdsList: getCategoryIdsList(classify),
        name: keywords,
        status,
        sortBysList: ['create_at/desc'],
      },
    })
  }, [page, size, status, classify, keywords])
  useEffect(() => {
    dispatch({
      type: `${spuListNs}/findMerchant`,
      payload: {
        id,
      },
    })
  }, [])
  function handleSearch(val: string) {
    reset()
    setKeywords(val)
  }
  function redirectToGoodsAdd() {
    history.push(`${GOODS_ADD_BASIC}?merchantId=${id}&step=0`)
  }
  return (
    <>
      <Typography.Title level={4}>供应商名称：{merchant?.name}</Typography.Title>
      <Space>
        <div className={styles.item}>
          <span>商品状态：</span>
          <Select
            onChange={val => {
              setStatus(val)
              reset()
            }}
            value={status}>
            <Option value="0">全部</Option>
            <Option value="1">启用</Option>
            <Option value="99">禁用</Option>
          </Select>
        </div>
        <div className={styles.item}>
          <span>平台分类：</span>
          <Cascader
            value={classify}
            onChange={val => {
              setClassify(val)
              reset()
            }}
            options={itemCategorys}
            placeholder="请选择平台分类"
          />
        </div>
        <Input.Search className={styles.search} placeholder="请输入商品名称" enterButton="搜索" onSearch={handleSearch} />
        <Button type="primary" onClick={redirectToGoodsAdd}>
          新增商品
        </Button>
      </Space>
      <Table<ISpu>
        className={styles.table} // tips: 由于pageSize的sizeChanger展开时存在shadow，因此需要在表格右端留出足够的间隔
        columns={columns}
        dataSource={spus}
        pagination={{
          current: page,
          pageSize: size,
          onChange: handleChange,
          onShowSizeChange: handleShowSizeChange,
          total: totalCount,
          showSizeChanger: true,
          showTotal: total => <p>共有{total}条记录</p>,
        }}
      />
    </>
  )
}

export default GoodsManage
