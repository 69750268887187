// import config from '@/config'
import { SimpleUnaryInterceptor } from './interceptors'

function clientWrapper<T>(
  ClientConstractor: any,
  credentials: null | { [index: string]: string } = null,
  options: null | { [index: string]: any } = { unaryInterceptors: [new SimpleUnaryInterceptor()] },
): T {
  const client = new ClientConstractor('', credentials, options)
  return client
}

export default clientWrapper
