import { execFunction, Model } from 'wbd-frontend-kit'
import {
  ItemAttr,
  ItemAttrListed,
  ItemAttrQuery,
  Sku,
  SkuAttr,
  SkuCreateReq,
  SkuListed,
  SkuQuery,
  Spu,
  SpuAttrOption,
  SpuAttrs,
  SpuAttrsReq,
  SpuAttrType,
  SpuQuery,
} from '@/proto/cherry/product/product_pb'
import makeRequest from '@/utils/request/makeRequest'

import { productServiceClient } from '@/clients'
import { message } from 'antd'

export type ISpu = Spu.AsObject
export type ISku = Sku.AsObject
export type IItemAttr = ItemAttr.AsObject

export const namespace = 'member-provider-goods-spec'
export interface ISpuSpec {
  spu: ISpu
  skus: ISku[]
  itemAttrList: IItemAttr[]
}

const initState: ISpuSpec = {
  spu: null,
  skus: [],
  itemAttrList: [],
}

export default {
  namespace,
  state: initState,
  effects: {
    *findSpu({ payload }, { call, put }) {
      try {
        const req = makeRequest<SpuQuery>(SpuQuery, payload)
        const res: Spu = yield call(productServiceClient.findSpu.bind(productServiceClient), req)
        const spu = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            spu,
          },
        })
      } catch (error) {}
    },
    *updateSpu({ payload, callback }, { call }) {
      try {
        const req = new SpuAttrsReq()
        req.setSpuId(payload.id)
        req.setAttrsList(
          payload.attrsList.map(attr =>
            makeRequest(SpuAttrType, { ...attr, optionsList: attr.optionsList.map(option => makeRequest(SpuAttrOption, option)) }),
          ),
        )
        const res: SpuAttrs = yield call(productServiceClient.saveSpuAttrs.bind(productServiceClient), req)
        const attrs = res.toObject()
        if (attrs) {
          message.success('修改商品规格成功！')
          execFunction(callback)
        }
      } catch (error) {}
    },
    *findSkuList({ payload }, { call, put }) {
      try {
        const req = makeRequest<SkuQuery>(SkuQuery, payload)
        const res: SkuListed = yield call(productServiceClient.findSkuList.bind(productServiceClient), req)
        const skus = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            skus: skus.recordsList,
          },
        })
      } catch (error) {}
    },
    *createSkuList({ payload, callback }, { call }) {
      try {
        const req = makeRequest<SkuCreateReq>(SkuCreateReq, {})
        for (const sku of payload.skusList) {
          req.addSkus(
            makeRequest<Sku>(Sku, { ...sku, attrsList: sku.attrsList.map(attr => makeRequest(SkuAttr, attr)) }),
          )
        }
        yield call(productServiceClient.createSkus.bind(productServiceClient), req)
        execFunction(callback)
      } catch (error) {}
    },
    *findAttrs({ payload }, { call, put }) {
      const req = makeRequest<ItemAttrQuery>(ItemAttrQuery, payload)
      try {
        const res: ItemAttrListed = yield call(productServiceClient.findItemAttrList.bind(productServiceClient), req)
        const attrs = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            itemAttrList: attrs.recordsList,
          },
        })
      } catch (error) {}
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload }
    },
  },
} as Model
