import { Popconfirm, Switch } from 'antd'
import React, { useEffect, useState } from 'react'

interface IProps {
  onConfirm: (callback?: () => void) => void
  checked: boolean
  disable?: boolean
}

const StatusChanger: React.FC<IProps> = ({ checked, onConfirm, disable }) => {
  const [visible, setVisible] = useState(checked)
  useEffect(() => {
    setVisible(checked)
  }, [checked])
  function handleConfirm() {
    onConfirm(() => setVisible(!visible))
  }
  return (
    <Popconfirm
      disabled={disable}
      title={`是否${visible ? '禁用' : '启用'}该商品`}
      okText={visible ? '禁用' : '启用'}
      cancelText="取消"
      onConfirm={handleConfirm}>
      <Switch disabled={disable} checked={visible} checkedChildren="启用" unCheckedChildren="禁用" />
    </Popconfirm>
  )
}

export default StatusChanger
