import useSearchParams from '@/hooks/useSearchParams'
import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Input, Cascader, Select, Upload, Button, Modal } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './style.module.scss'
import { namespace, ISpuDetail } from './model'
import { namespace as globalNs, IState } from '@/models/global'
import { CascaderOptionType } from 'antd/lib/cascader'
import { Dict } from 'wbd-frontend-kit'
import config from '@/config'
import { useHistory } from 'react-router-dom'
import { SPEC_MANAGEMENT } from '@/router/config/member-manage/path'
import E from 'wangeditor'

let editor: E

const FormItem = Form.Item

const GoodsDetail: React.FC = () => {
  const { merchantId, goodsId } = useSearchParams<Dict>()
  const dispatch = useDispatch()
  const { spu, shops }: ISpuDetail = useSelector(state => state[namespace])
  const { itemCategorys, productCategorys, areas, brands }: IState = useSelector(state => state[globalNs])
  const history = useHistory()
  useEffect(() => {
    editor = new E('#goodsDesc')
    editor.config.zIndex = 0
    editor.config.onchange = (newHtml: string) => {
      form.setFieldsValue({ detailContent: newHtml })
    }
    editor.create()
    return () => {
      editor.destroy()
    }
  }, [])
  useEffect(() => {
    dispatch({
      type: `${namespace}/findSpu`,
      payload: {
        id: goodsId,
      },
    })
    dispatch({
      type: `${namespace}/findShop`,
      payload: {
        merchantIdsList: [merchantId],
      },
    })
    dispatch({
      type: `${globalNs}/queryItemCategoryList`,
    })
    dispatch({
      type: `${globalNs}/queryProductCategoryList`,
      payload: {
        merchantIdsList: [merchantId],
      },
    })
    dispatch({
      type: `${globalNs}/queryAreaList`,
      payload: {
        parentId: 0,
      },
    })
    dispatch({
      type: `${globalNs}/queryBrandList`,
    })
  }, [])
  const normFile = (e: any) => {
    // 图片的限制的话可以考虑这里来判断
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }
  const [form] = Form.useForm()
  const onPreview = async file => {
    let src = file.url
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow.document.write(image.outerHTML)
  }
  const loadData = (selectedOptions: CascaderOptionType[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1] // 取得选中的一个
    targetOption.loading = true
    dispatch({
      type: `${globalNs}/queryAreaList`,
      payload: {
        parentId: targetOption.value,
      },
      callback: (records: any[]) => {
        targetOption.loading = false
        targetOption.children = records
      },
    })
  }
  useEffect(() => {
    form.setFieldsValue({ ...spu, brandId: spu.brandId === 0 ? undefined : spu.brandId })
    editor.txt.html(spu.detailContent)
  }, [spu])
  function handleFinish(vals) {
    const payload = {
      ...vals,
      imgUrl: vals.imgUrl[0]?.url || vals.imgUrl[0]?.response?.fileUrl,
    }
    dispatch({
      type: `${namespace}/updateSpu`,
      payload: payload,
      callback: () => {
        Modal.confirm({
          title: '操作结果',
          content: '商品信息修改成功！是否跳转到规格管理页面？',
          icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
          okText: '跳转',
          onOk: () => {
            history.push(`${SPEC_MANAGEMENT}?goodsId=${goodsId}&merchantId=${merchantId}`)
          },
        })
      },
    })
  }
  return (
    <Form form={form} wrapperCol={{ xs: 24 }} onFinish={handleFinish}>
      <FormItem label="供应商名称">
        <span>{spu?.merchantName}</span>
      </FormItem>
      <FormItem hidden name="id" wrapperCol={{ span: 6 }}>
        <Input type="text" />
      </FormItem>
      <FormItem label="销售名称" name="name" wrapperCol={{ span: 6 }} rules={[{ required: true, message: '请输入销售名称' }]}>
        <Input type="text" placeholder="请输入销售名称" />
      </FormItem>
      <FormItem
        label="标准分类"
        name="itemCategoryIdsList"
        wrapperCol={{ span: 5 }}
        rules={[{ required: true, message: '请选择标准商品分类' }]}>
        <Cascader disabled options={itemCategorys} placeholder="请选择标准商品分类" />
      </FormItem>
      <FormItem label="所属档口" name="stallId" wrapperCol={{ span: 5 }} rules={[{ required: true, message: '请选择所属档口' }]}>
        <Select placeholder="请选择所属档口">
          {shops.map(shop => (
            <Select.Option value={shop.id}>{shop.contactAddress}</Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem label="销售分类" name="categoryIdsList" wrapperCol={{ span: 4 }} rules={[{ required: true, message: '请选择销售分类' }]}>
        <Cascader options={productCategorys} placeholder="请选择销售分类" />
      </FormItem>
      <FormItem
        name="imgUrl"
        label="商品头图"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: '请上传商品头图' }]}>
        <Upload
          maxCount={1}
          onPreview={onPreview}
          name="file"
          action={`/_files/upload?x-token-value=${localStorage.getItem(config.authKey)}`}
          listType="picture-card">
          <PlusOutlined />
        </Upload>
      </FormItem>
      {/* <FormItem name="detailContent" label="商品描述" wrapperCol={{ span: 6 }} rules={[{ required: true, message: '请输入商品描述' }]}>
        <Input.TextArea rows={6} placeholder="请填写商品描述" />
      </FormItem> */}
      <FormItem
        label="商品描述"
        wrapperCol={{ span: 12 }}
        required
        rules={[{ required: true, message: '请输入商品描述' }]}
        name="detailContent">
        <div id="goodsDesc"></div>
      </FormItem>
      <FormItem
        wrapperCol={{ span: 4 }}
        name="placeAreaIdsList"
        label={
          <>
            <span className={styles.visible}>* 商品</span>产地
          </>
        }>
        <Cascader options={areas} placeholder="请选择产地信息" loadData={loadData} />
      </FormItem>
      <FormItem
        wrapperCol={{ span: 2 }}
        name="brandId"
        label={
          <>
            <span className={styles.visible}>* 商品</span>品牌
          </>
        }>
        <Select placeholder="请选择品牌">
          {brands.map(brand => (
            <Select.Option value={brand.id}>{brand.name}</Select.Option>
          ))}
        </Select>
      </FormItem>
      <FormItem wrapperCol={{ offset: 2, span: 6 }}>
        <Button htmlType="submit" type="primary">
          保存商品信息
        </Button>
      </FormItem>
    </Form>
  )
}

export default GoodsDetail
