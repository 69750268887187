import { Button, Input, Popconfirm, Switch, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './style.module.scss'
import { namespace, ICategoryManage, Status } from './model'
import useSearchParams from '@/hooks/useSearchParams'

let currentIndex = 0

const EditableCell: React.FC<any> = ({ children, editable, handleSave, dataIndex, record, ...restProps }) => {
  const [editing, setEditing] = useState(false)
  const ref = useRef<Input>(null)
  useEffect(() => {
    if (!record) return
    if (record.key === `local${currentIndex}`) {
      setEditing(true)
      currentIndex++
    }
  }, [record])
  useEffect(() => {
    if (editing) {
      ref.current.focus()
    }
  }, [editing])
  function toggleEdit() {
    setEditing(!editing)
  }
  function handleSaveClassify(e) {
    toggleEdit()
    handleSave(record.key, e.target.value, record)
  }
  let childNode = children
  if (editable) {
    childNode = editing ? (
      <div>
        <Input
          ref={ref}
          defaultValue={record[dataIndex]}
          placeholder="请输入分类名称"
          onPressEnter={handleSaveClassify}
          onBlur={handleSaveClassify}
        />
      </div>
    ) : (
      <div className={styles.editableCell} onClick={toggleEdit}>
        {children}
      </div>
    )
  }
  return <td {...restProps}>{childNode}</td>
}

interface IEditStatus {
  record: any
}

const EditStatus: React.FC<IEditStatus> = ({ record }) => {
  const dispatch = useDispatch()
  const id = useSearchParams('id')
  const [checked, setChecked] = useState(record.status === Status.Enable)
  useEffect(() => {
    setChecked(record.status === Status.Enable)
  }, [record.status])
  function handleChangeStatus(record: any) {
    const payload = {
      merchantId: id,
      status: record.status === Status.Enable ? Status.Disable : Status.Enable,
      id: record.key,
    }
    dispatch({
      type: `${namespace}/updateProductCategory`,
      payload,
    })
  }
  return (
    <Popconfirm
      title={`确认${record.status === Status.Enable ? '禁用' : '启用'}该分类${record.level === 2 ? '(选项)' : ''}`}
      okText="确认"
      cancelText="取消"
      // onCancel={}
      onConfirm={() => handleChangeStatus(record)}>
      <Switch checked={checked} />
    </Popconfirm>
  )
}

const ClassificationManagement: React.FC = () => {
  const dispatch = useDispatch()
  const id = useSearchParams('merchantId')
  const { categorys }: ICategoryManage = useSelector(state => state[namespace])
  const loading = useSelector(state => state['loading'].effects[`${namespace}/fetchProductCategoryList`])
  function fetchTableData() {
    dispatch({
      type: `${namespace}/fetchProductCategoryList`,
      payload: {
        parentId: '-1',
        merchantId: id,
      },
    })
  }
  useEffect(() => {
    fetchTableData()
  }, [])
  const columns: any = [
    { key: 'name', title: '分类名称', dataIndex: 'name', editable: true, width: 200 },
    {
      key: 'operation',
      title: '操作',
      render: record => {
        return (
          <>
            <EditStatus record={record} />
            {record.level === 1 && (
              <Button type="primary" size="small" style={{ marginLeft: 6 }} onClick={() => handleAddSubRow(record.key)}>
                新增二级分类
              </Button>
            )}
          </>
        )
      },
      width: 40,
    },
  ]
  function handleSave(key: string, value: string, record: any) {
    if (!key || !value) {
      return
    }
    let finded = false
    // 双重循环不需要用到递归
    for (const row of categorys) {
      if (finded) {
        break
      }
      if (key === row.key) {
        row.name = value
        finded = true
        break
      }
      if (row.children) {
        for (const subRow of row.children) {
          if (subRow.key === key) {
            subRow.name = value
            finded = true
            break
          }
        }
      }
    }
    dispatch({
      type: `${namespace}/setState`,
      payload: {
        categorys: [...categorys],
      },
    })
    if (String(key).startsWith('local')) {
      // 表示新增
      const payload = {
        merchantId: id,
        name: value,
        status: record.status,
      }
      // 新增二级菜单
      if (record.level === 2) {
        payload['parentId'] = record.parentKey
      }
      dispatch({
        type: `${namespace}/createProductCategory`,
        payload,
      })
    } else {
      const payload = {
        merchantId: id,
        name: value,
        id: key,
      }
      if (record.level === 2) {
        payload['parentId'] = record.parentKey
      }
      dispatch({
        type: `${namespace}/updateProductCategory`,
        payload,
      })
    }
  }
  function handleAddSubRow(pkey: string) {
    if (!pkey) {
      return
    }
    const newData = categorys.map(row => {
      if (row.key === pkey) {
        const subRow = { key: `local${++currentIndex}`, name: '', level: 2, parentKey: row.key, status: 1 }
        if (row.children) {
          row.children.push(subRow)
        } else {
          row.children = [subRow]
        }
      }
      return { ...row }
    })
    dispatch({
      type: `${namespace}/setState`,
      payload: {
        categorys: [...newData],
      },
    })
  }
  function handleAddRow() {
    const row = { key: `local${++currentIndex}`, name: '', level: 1, parentKey: 0, status: 1 }
    dispatch({
      type: `${namespace}/setState`,
      payload: {
        categorys: [...categorys, row],
      },
    })
  }
  const component = {
    body: {
      cell: EditableCell,
    },
  }
  const cols = columns.map(col => {
    if (col.editable) {
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: handleSave,
        }),
      }
    }
    return col
  })
  return (
    <>
      <Button onClick={handleAddRow} className={styles.btnWithMarginBottom} type="primary">
        新增一级分类
      </Button>
      <Table
        loading={loading}
        expandable={{ defaultExpandAllRows: true }}
        components={component}
        className={styles.classifyTable}
        dataSource={categorys}
        columns={cols}
        pagination={false}
      />
    </>
  )
}

export default ClassificationManagement
