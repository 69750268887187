import { execFunction, Model } from 'wbd-frontend-kit'
import { SignIn, SignInToken } from '@/proto/cherry/sign/sign_pb'
import makeRequest from '@/utils/request/makeRequest'
import { setLocalStorage } from '@/utils/storage'
import config from '@/config'
import { signServiceClient, userServiceClient } from '@/clients'
import { UserQuery, User } from '@/proto/cherry/user/user_pb'

export const namespace = 'login'
export interface IState {
  userInfo: User.AsObject
}

const initState: IState = {
  userInfo: null,
}

export default {
  namespace,
  state: initState,
  effects: {
    *login({ payload: { name, password }, callback }, { call }) {
      try {
        const req = makeRequest<SignIn>(SignIn, { phone: name, password })
        const res: SignInToken = yield call(signServiceClient.login.bind(signServiceClient), req)
        const token = res.getToken()
        setLocalStorage(config.authKey, token)
        execFunction(callback)
      } catch (error) {}
    },
    *fetchUserInfo(_, { call, put }) {
      const req = makeRequest<UserQuery>(UserQuery, {})
      try {
        const res: User = yield call(userServiceClient.findUser.bind(userServiceClient), req)
        yield put({
          type: 'setState',
          payload: {
            userInfo: res.toObject(),
          },
        })
      } catch (error) {}
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload }
    },
  },
} as Model
