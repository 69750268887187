const gramTranJin = val => {
  if (!val || val === 0 || isNaN(val)) {
    return 0
  }
  const jin = val / 500
  return Math.round(jin * 100) / 100
}
const jinTranGram = val => {
  if (!val || val === 0 || isNaN(val)) {
    return 0
  }
  const gram = val * 500
  return Math.round(gram * 100) / 100
}
export { gramTranJin, jinTranGram }
