import { IRouteConfig } from '../../typing'
import Layout from '@/pages/layout'
import {
  GOODS_ADD,
  GOODS_ADD_BASIC,
  GOODS_ADD_SKUS,
  GOODS_ADD_SPECS,
  GOODS_DETAIL,
  INDEX,
  PROVIDER_CLASSIFICATION_MANAGEMENT,
  PROVIDER_GOODS_MANAGEMENT,
  PROVIDER_MANAGEMENT,
  SKU_MANAGEMENT,
  SPEC_MANAGEMENT,
} from './path'
import ProviderManage from '@/pages/member-manage/provider'
import Classification from '@/pages/member-manage/provider/classification'
import Goods from '@/pages/member-manage/provider/goods'
import GoodsDetail from '@/pages/member-manage/provider/goods/detail'
import SpecManage from '@/pages/member-manage/provider/goods/spec-manage'
import SkuManage from '@/pages/member-manage/provider/goods/sku-manage'
import GoodsAddLayout from '@/pages/member-manage/provider/goods/add'
import GoodsAddBasic from '@/pages/member-manage/provider/goods/add/sections/detail'
import GoodsAddSpecs from '@/pages/member-manage/provider/goods/add/sections/spec-manage'
import GoodsAddSkus from '@/pages/member-manage/provider/goods/add/sections/sku-manage'

const routes: IRouteConfig = {
  path: INDEX,
  component: Layout,
  title: '模块布局页',
  routes: [
    {
      path: PROVIDER_MANAGEMENT,
      component: ProviderManage,
      title: '供应商管理',
      exact: true,
      breadcrumb: [{ label: '会员管理' }, { label: '供应商管理' }],
      auth: true,
    },
    {
      path: PROVIDER_CLASSIFICATION_MANAGEMENT,
      component: Classification,
      title: '商户分类管理',
      exact: true,
      breadcrumb: [{ label: '会员管理' }, { label: '供应商管理', redirect: PROVIDER_MANAGEMENT }, { label: '商户分类管理' }],
      auth: true,
    },
    {
      path: PROVIDER_GOODS_MANAGEMENT,
      component: Goods,
      title: '商品管理',
      exact: true,
      breadcrumb: [{ label: '会员管理' }, { label: '供应商管理', redirect: PROVIDER_MANAGEMENT }, { label: '商品管理' }],
      auth: true,
    },
    {
      path: GOODS_DETAIL,
      component: GoodsDetail,
      title: '商品详情',
      exact: true,
      breadcrumb: [
        { label: '会员管理' },
        { label: '供应商管理', redirect: PROVIDER_MANAGEMENT },
        { label: '商品管理', redirect: PROVIDER_GOODS_MANAGEMENT, params: ['merchantId'] },
        { label: '商品详情' },
      ],
      auth: true,
    },
    {
      path: SPEC_MANAGEMENT,
      component: SpecManage,
      title: '规格管理',
      exact: true,
      breadcrumb: [
        { label: '会员管理' },
        { label: '供应商管理', redirect: PROVIDER_MANAGEMENT },
        { label: '商品管理', redirect: PROVIDER_GOODS_MANAGEMENT, params: ['merchantId'] },
        { label: '规格管理' },
      ],
      auth: true,
    },
    {
      path: SKU_MANAGEMENT,
      component: SkuManage,
      title: 'SKU管理',
      exact: true,
      breadcrumb: [
        { label: '会员管理' },
        { label: '供应商管理', redirect: PROVIDER_MANAGEMENT },
        { label: '商品管理', redirect: PROVIDER_GOODS_MANAGEMENT, params: ['merchantId'] },
        { label: 'SKU管理' },
      ],
      auth: true,
    },
    {
      path: GOODS_ADD,
      component: GoodsAddLayout,
      title: '新增商品',
      breadcrumb: [
        { label: '会员管理' },
        { label: '供应商管理', redirect: PROVIDER_MANAGEMENT },
        { label: '商品管理', redirect: PROVIDER_GOODS_MANAGEMENT, params: ['merchantId'] },
        { label: '添加商品' },
      ],
      routes: [
        {
          path: GOODS_ADD_BASIC,
          component: GoodsAddBasic,
          title: '基本信息',
          exact: true,
          breadcrumb: [
            { label: '会员管理' },
            { label: '供应商管理', redirect: PROVIDER_MANAGEMENT },
            { label: '商品管理', redirect: PROVIDER_GOODS_MANAGEMENT, params: ['merchantId'] },
            { label: '添加商品' },
          ],
          auth: true,
        },
        {
          path: GOODS_ADD_SPECS,
          component: GoodsAddSpecs,
          title: '规格管理',
          exact: true,
          breadcrumb: [
            { label: '会员管理' },
            { label: '供应商管理', redirect: PROVIDER_MANAGEMENT },
            { label: '商品管理', redirect: PROVIDER_GOODS_MANAGEMENT, params: ['merchantId'] },
            { label: '添加商品' },
          ],
          auth: true,
        },
        {
          path: GOODS_ADD_SKUS,
          component: GoodsAddSkus,
          title: 'SKU管理',
          exact: true,
          breadcrumb: [
            { label: '会员管理' },
            { label: '供应商管理', redirect: PROVIDER_MANAGEMENT },
            { label: '商品管理', redirect: PROVIDER_GOODS_MANAGEMENT, params: ['merchantId'] },
            { label: '添加商品' },
          ],
          auth: true,
        },
      ],
      auth: true,
    },
  ],
}

export default routes
