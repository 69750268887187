// source: cherry/enterprise/enterprise.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var cherry_cherry_pb = require('../../cherry/cherry_pb.js');
goog.object.extend(proto, cherry_cherry_pb);
goog.exportSymbol('proto.cherry.enterprise.Enterprise', null, global);
goog.exportSymbol('proto.cherry.enterprise.EnterpriseListed', null, global);
goog.exportSymbol('proto.cherry.enterprise.EnterpriseQuery', null, global);
goog.exportSymbol('proto.cherry.enterprise.Merchant', null, global);
goog.exportSymbol('proto.cherry.enterprise.MerchantListed', null, global);
goog.exportSymbol('proto.cherry.enterprise.MerchantQuery', null, global);
goog.exportSymbol('proto.cherry.enterprise.Stall', null, global);
goog.exportSymbol('proto.cherry.enterprise.StallListed', null, global);
goog.exportSymbol('proto.cherry.enterprise.StallQuery', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.enterprise.Enterprise = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cherry.enterprise.Enterprise, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.enterprise.Enterprise.displayName = 'proto.cherry.enterprise.Enterprise';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.enterprise.EnterpriseQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.enterprise.EnterpriseQuery.repeatedFields_, null);
};
goog.inherits(proto.cherry.enterprise.EnterpriseQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.enterprise.EnterpriseQuery.displayName = 'proto.cherry.enterprise.EnterpriseQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.enterprise.EnterpriseListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.enterprise.EnterpriseListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.enterprise.EnterpriseListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.enterprise.EnterpriseListed.displayName = 'proto.cherry.enterprise.EnterpriseListed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.enterprise.Merchant = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.enterprise.Merchant.repeatedFields_, null);
};
goog.inherits(proto.cherry.enterprise.Merchant, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.enterprise.Merchant.displayName = 'proto.cherry.enterprise.Merchant';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.enterprise.MerchantQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.enterprise.MerchantQuery.repeatedFields_, null);
};
goog.inherits(proto.cherry.enterprise.MerchantQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.enterprise.MerchantQuery.displayName = 'proto.cherry.enterprise.MerchantQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.enterprise.MerchantListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.enterprise.MerchantListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.enterprise.MerchantListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.enterprise.MerchantListed.displayName = 'proto.cherry.enterprise.MerchantListed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.enterprise.Stall = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cherry.enterprise.Stall, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.enterprise.Stall.displayName = 'proto.cherry.enterprise.Stall';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.enterprise.StallQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.enterprise.StallQuery.repeatedFields_, null);
};
goog.inherits(proto.cherry.enterprise.StallQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.enterprise.StallQuery.displayName = 'proto.cherry.enterprise.StallQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.enterprise.StallListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.enterprise.StallListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.enterprise.StallListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.enterprise.StallListed.displayName = 'proto.cherry.enterprise.StallListed';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.enterprise.Enterprise.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.enterprise.Enterprise.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.enterprise.Enterprise} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.Enterprise.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    creditCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    legalCardType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    legalCardNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    legalName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    regAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    contactAddress: jspb.Message.getFieldWithDefault(msg, 9, ""),
    contactPhone: jspb.Message.getFieldWithDefault(msg, 10, ""),
    contactPerson: jspb.Message.getFieldWithDefault(msg, 11, ""),
    status: jspb.Message.getFieldWithDefault(msg, 12, 0),
    createAt: jspb.Message.getFieldWithDefault(msg, 13, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    businessLicense: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.enterprise.Enterprise}
 */
proto.cherry.enterprise.Enterprise.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.enterprise.Enterprise;
  return proto.cherry.enterprise.Enterprise.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.enterprise.Enterprise} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.enterprise.Enterprise}
 */
proto.cherry.enterprise.Enterprise.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditCode(value);
      break;
    case 5:
      var value = /** @type {!proto.cherry.CardType} */ (reader.readEnum());
      msg.setLegalCardType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalCardNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLegalName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegAddress(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactAddress(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhone(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPerson(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBusinessLicense(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.enterprise.Enterprise.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.enterprise.Enterprise.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.enterprise.Enterprise} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.Enterprise.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreditCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLegalCardType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getLegalCardNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLegalName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRegAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getContactAddress();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getContactPhone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getContactPerson();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getBusinessLicense();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.enterprise.Enterprise.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.enterprise.Enterprise.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 3;
 * @return {string}
 */
proto.cherry.enterprise.Enterprise.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string credit_code = 4;
 * @return {string}
 */
proto.cherry.enterprise.Enterprise.prototype.getCreditCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setCreditCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional cherry.CardType legal_card_type = 5;
 * @return {!proto.cherry.CardType}
 */
proto.cherry.enterprise.Enterprise.prototype.getLegalCardType = function() {
  return /** @type {!proto.cherry.CardType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.cherry.CardType} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setLegalCardType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string legal_card_no = 6;
 * @return {string}
 */
proto.cherry.enterprise.Enterprise.prototype.getLegalCardNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setLegalCardNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string legal_name = 7;
 * @return {string}
 */
proto.cherry.enterprise.Enterprise.prototype.getLegalName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setLegalName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string reg_address = 8;
 * @return {string}
 */
proto.cherry.enterprise.Enterprise.prototype.getRegAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setRegAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string contact_address = 9;
 * @return {string}
 */
proto.cherry.enterprise.Enterprise.prototype.getContactAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setContactAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string contact_phone = 10;
 * @return {string}
 */
proto.cherry.enterprise.Enterprise.prototype.getContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setContactPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string contact_person = 11;
 * @return {string}
 */
proto.cherry.enterprise.Enterprise.prototype.getContactPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setContactPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 status = 12;
 * @return {number}
 */
proto.cherry.enterprise.Enterprise.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 create_at = 13;
 * @return {number}
 */
proto.cherry.enterprise.Enterprise.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 updated_at = 14;
 * @return {number}
 */
proto.cherry.enterprise.Enterprise.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional string business_license = 15;
 * @return {string}
 */
proto.cherry.enterprise.Enterprise.prototype.getBusinessLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Enterprise} returns this
 */
proto.cherry.enterprise.Enterprise.prototype.setBusinessLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.enterprise.EnterpriseQuery.repeatedFields_ = [2,5,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.enterprise.EnterpriseQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.enterprise.EnterpriseQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.EnterpriseQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    idsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sortBysList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    creditCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    fetchFieldsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.enterprise.EnterpriseQuery}
 */
proto.cherry.enterprise.EnterpriseQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.enterprise.EnterpriseQuery;
  return proto.cherry.enterprise.EnterpriseQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.enterprise.EnterpriseQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.enterprise.EnterpriseQuery}
 */
proto.cherry.enterprise.EnterpriseQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSortBys(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreditCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addFetchFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.enterprise.EnterpriseQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.enterprise.EnterpriseQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.EnterpriseQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSortBysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreditCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFetchFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 ids = 2;
 * @return {!Array<number>}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page_current = 3;
 * @return {number}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated string sort_bys = 5;
 * @return {!Array<string>}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.getSortBysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.setSortBysList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.addSortBys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.clearSortBysList = function() {
  return this.setSortBysList([]);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string short_name = 7;
 * @return {string}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string credit_code = 8;
 * @return {string}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.getCreditCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.setCreditCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated string fetch_fields = 9;
 * @return {!Array<string>}
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.getFetchFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.setFetchFieldsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.addFetchFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.EnterpriseQuery} returns this
 */
proto.cherry.enterprise.EnterpriseQuery.prototype.clearFetchFieldsList = function() {
  return this.setFetchFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.enterprise.EnterpriseListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.enterprise.EnterpriseListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.enterprise.EnterpriseListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.enterprise.EnterpriseListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.EnterpriseListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.enterprise.Enterprise.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.enterprise.EnterpriseListed}
 */
proto.cherry.enterprise.EnterpriseListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.enterprise.EnterpriseListed;
  return proto.cherry.enterprise.EnterpriseListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.enterprise.EnterpriseListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.enterprise.EnterpriseListed}
 */
proto.cherry.enterprise.EnterpriseListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.enterprise.Enterprise;
      reader.readMessage(value,proto.cherry.enterprise.Enterprise.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.enterprise.EnterpriseListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.enterprise.EnterpriseListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.enterprise.EnterpriseListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.EnterpriseListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.enterprise.Enterprise.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_current = 1;
 * @return {number}
 */
proto.cherry.enterprise.EnterpriseListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.EnterpriseListed} returns this
 */
proto.cherry.enterprise.EnterpriseListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.cherry.enterprise.EnterpriseListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.EnterpriseListed} returns this
 */
proto.cherry.enterprise.EnterpriseListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.enterprise.EnterpriseListed.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.EnterpriseListed} returns this
 */
proto.cherry.enterprise.EnterpriseListed.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Enterprise records = 4;
 * @return {!Array<!proto.cherry.enterprise.Enterprise>}
 */
proto.cherry.enterprise.EnterpriseListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.enterprise.Enterprise>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.enterprise.Enterprise, 4));
};


/**
 * @param {!Array<!proto.cherry.enterprise.Enterprise>} value
 * @return {!proto.cherry.enterprise.EnterpriseListed} returns this
*/
proto.cherry.enterprise.EnterpriseListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.enterprise.Enterprise=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.Enterprise}
 */
proto.cherry.enterprise.EnterpriseListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.enterprise.Enterprise, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.EnterpriseListed} returns this
 */
proto.cherry.enterprise.EnterpriseListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.enterprise.Merchant.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.enterprise.Merchant.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.enterprise.Merchant.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.enterprise.Merchant} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.Merchant.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enterpriseId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    marketId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    logoUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contactPhone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    contactPerson: jspb.Message.getFieldWithDefault(msg, 7, ""),
    contactAddress: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    stallsList: jspb.Message.toObjectList(msg.getStallsList(),
    proto.cherry.enterprise.Stall.toObject, includeInstance),
    enterpriseName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    marketName: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.enterprise.Merchant}
 */
proto.cherry.enterprise.Merchant.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.enterprise.Merchant;
  return proto.cherry.enterprise.Merchant.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.enterprise.Merchant} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.enterprise.Merchant}
 */
proto.cherry.enterprise.Merchant.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnterpriseId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMarketId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPerson(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactAddress(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 12:
      var value = new proto.cherry.enterprise.Stall;
      reader.readMessage(value,proto.cherry.enterprise.Stall.deserializeBinaryFromReader);
      msg.addStalls(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnterpriseName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.enterprise.Merchant.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.enterprise.Merchant.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.enterprise.Merchant} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.Merchant.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnterpriseId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getLogoUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContactPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContactPerson();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContactAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getStallsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.cherry.enterprise.Stall.serializeBinaryToWriter
    );
  }
  f = message.getEnterpriseName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMarketName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.enterprise.Merchant.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.enterprise.Merchant.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 enterprise_id = 3;
 * @return {number}
 */
proto.cherry.enterprise.Merchant.prototype.getEnterpriseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setEnterpriseId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 market_id = 4;
 * @return {number}
 */
proto.cherry.enterprise.Merchant.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string logo_url = 5;
 * @return {string}
 */
proto.cherry.enterprise.Merchant.prototype.getLogoUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setLogoUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contact_phone = 6;
 * @return {string}
 */
proto.cherry.enterprise.Merchant.prototype.getContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setContactPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contact_person = 7;
 * @return {string}
 */
proto.cherry.enterprise.Merchant.prototype.getContactPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setContactPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string contact_address = 8;
 * @return {string}
 */
proto.cherry.enterprise.Merchant.prototype.getContactAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setContactAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 status = 9;
 * @return {number}
 */
proto.cherry.enterprise.Merchant.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 create_at = 10;
 * @return {number}
 */
proto.cherry.enterprise.Merchant.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 updated_at = 11;
 * @return {number}
 */
proto.cherry.enterprise.Merchant.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated Stall stalls = 12;
 * @return {!Array<!proto.cherry.enterprise.Stall>}
 */
proto.cherry.enterprise.Merchant.prototype.getStallsList = function() {
  return /** @type{!Array<!proto.cherry.enterprise.Stall>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.enterprise.Stall, 12));
};


/**
 * @param {!Array<!proto.cherry.enterprise.Stall>} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
*/
proto.cherry.enterprise.Merchant.prototype.setStallsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.cherry.enterprise.Stall=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.Stall}
 */
proto.cherry.enterprise.Merchant.prototype.addStalls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.cherry.enterprise.Stall, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.clearStallsList = function() {
  return this.setStallsList([]);
};


/**
 * optional string enterprise_name = 13;
 * @return {string}
 */
proto.cherry.enterprise.Merchant.prototype.getEnterpriseName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setEnterpriseName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string market_name = 14;
 * @return {string}
 */
proto.cherry.enterprise.Merchant.prototype.getMarketName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Merchant} returns this
 */
proto.cherry.enterprise.Merchant.prototype.setMarketName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.enterprise.MerchantQuery.repeatedFields_ = [3,4,8,9,10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.enterprise.MerchantQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.enterprise.MerchantQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.enterprise.MerchantQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.MerchantQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    spuIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    skuIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
    fetchStalls: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    sortBysList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    stallIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    idsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    enterpriseIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    name: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.enterprise.MerchantQuery}
 */
proto.cherry.enterprise.MerchantQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.enterprise.MerchantQuery;
  return proto.cherry.enterprise.MerchantQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.enterprise.MerchantQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.enterprise.MerchantQuery}
 */
proto.cherry.enterprise.MerchantQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSpuIds(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSkuIds(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFetchStalls(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSortBys(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStallIds(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 11:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEnterpriseIds(values[i]);
      }
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.enterprise.MerchantQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.enterprise.MerchantQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.enterprise.MerchantQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.MerchantQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSpuIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getSkuIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getFetchStalls();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getSortBysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getStallIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getEnterpriseIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 spu_ids = 3;
 * @return {!Array<number>}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getSpuIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setSpuIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.addSpuIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.clearSpuIdsList = function() {
  return this.setSpuIdsList([]);
};


/**
 * repeated int64 sku_ids = 4;
 * @return {!Array<number>}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getSkuIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setSkuIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.addSkuIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.clearSkuIdsList = function() {
  return this.setSkuIdsList([]);
};


/**
 * optional int32 page_current = 5;
 * @return {number}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 page_size = 6;
 * @return {number}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool fetch_stalls = 7;
 * @return {boolean}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getFetchStalls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setFetchStalls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * repeated string sort_bys = 8;
 * @return {!Array<string>}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getSortBysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setSortBysList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.addSortBys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.clearSortBysList = function() {
  return this.setSortBysList([]);
};


/**
 * repeated int64 stall_ids = 9;
 * @return {!Array<number>}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getStallIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setStallIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.addStallIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.clearStallIdsList = function() {
  return this.setStallIdsList([]);
};


/**
 * repeated int64 ids = 10;
 * @return {!Array<number>}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * repeated int64 enterprise_ids = 11;
 * @return {!Array<number>}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getEnterpriseIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setEnterpriseIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.addEnterpriseIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.clearEnterpriseIdsList = function() {
  return this.setEnterpriseIdsList([]);
};


/**
 * optional string name = 12;
 * @return {string}
 */
proto.cherry.enterprise.MerchantQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.MerchantQuery} returns this
 */
proto.cherry.enterprise.MerchantQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.enterprise.MerchantListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.enterprise.MerchantListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.enterprise.MerchantListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.enterprise.MerchantListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.MerchantListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.enterprise.Merchant.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.enterprise.MerchantListed}
 */
proto.cherry.enterprise.MerchantListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.enterprise.MerchantListed;
  return proto.cherry.enterprise.MerchantListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.enterprise.MerchantListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.enterprise.MerchantListed}
 */
proto.cherry.enterprise.MerchantListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.enterprise.Merchant;
      reader.readMessage(value,proto.cherry.enterprise.Merchant.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.enterprise.MerchantListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.enterprise.MerchantListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.enterprise.MerchantListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.MerchantListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.enterprise.Merchant.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_current = 1;
 * @return {number}
 */
proto.cherry.enterprise.MerchantListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.MerchantListed} returns this
 */
proto.cherry.enterprise.MerchantListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.cherry.enterprise.MerchantListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.MerchantListed} returns this
 */
proto.cherry.enterprise.MerchantListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.enterprise.MerchantListed.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.MerchantListed} returns this
 */
proto.cherry.enterprise.MerchantListed.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Merchant records = 4;
 * @return {!Array<!proto.cherry.enterprise.Merchant>}
 */
proto.cherry.enterprise.MerchantListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.enterprise.Merchant>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.enterprise.Merchant, 4));
};


/**
 * @param {!Array<!proto.cherry.enterprise.Merchant>} value
 * @return {!proto.cherry.enterprise.MerchantListed} returns this
*/
proto.cherry.enterprise.MerchantListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.enterprise.Merchant=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.Merchant}
 */
proto.cherry.enterprise.MerchantListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.enterprise.Merchant, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.MerchantListed} returns this
 */
proto.cherry.enterprise.MerchantListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.enterprise.Stall.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.enterprise.Stall.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.enterprise.Stall} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.Stall.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    merchantId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    contactPhone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contactPerson: jspb.Message.getFieldWithDefault(msg, 6, ""),
    contactAddress: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    enterpriseId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.enterprise.Stall}
 */
proto.cherry.enterprise.Stall.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.enterprise.Stall;
  return proto.cherry.enterprise.Stall.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.enterprise.Stall} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.enterprise.Stall}
 */
proto.cherry.enterprise.Stall.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMerchantId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPerson(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactAddress(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnterpriseId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.enterprise.Stall.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.enterprise.Stall.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.enterprise.Stall} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.Stall.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMerchantId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getContactPhone();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContactPerson();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContactAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getEnterpriseId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.enterprise.Stall.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Stall} returns this
 */
proto.cherry.enterprise.Stall.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.enterprise.Stall.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Stall} returns this
 */
proto.cherry.enterprise.Stall.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 merchant_id = 3;
 * @return {number}
 */
proto.cherry.enterprise.Stall.prototype.getMerchantId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Stall} returns this
 */
proto.cherry.enterprise.Stall.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 status = 4;
 * @return {number}
 */
proto.cherry.enterprise.Stall.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Stall} returns this
 */
proto.cherry.enterprise.Stall.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string contact_phone = 5;
 * @return {string}
 */
proto.cherry.enterprise.Stall.prototype.getContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Stall} returns this
 */
proto.cherry.enterprise.Stall.prototype.setContactPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contact_person = 6;
 * @return {string}
 */
proto.cherry.enterprise.Stall.prototype.getContactPerson = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Stall} returns this
 */
proto.cherry.enterprise.Stall.prototype.setContactPerson = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contact_address = 7;
 * @return {string}
 */
proto.cherry.enterprise.Stall.prototype.getContactAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.enterprise.Stall} returns this
 */
proto.cherry.enterprise.Stall.prototype.setContactAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 create_at = 8;
 * @return {number}
 */
proto.cherry.enterprise.Stall.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Stall} returns this
 */
proto.cherry.enterprise.Stall.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.cherry.enterprise.Stall.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Stall} returns this
 */
proto.cherry.enterprise.Stall.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 enterprise_id = 10;
 * @return {number}
 */
proto.cherry.enterprise.Stall.prototype.getEnterpriseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.Stall} returns this
 */
proto.cherry.enterprise.Stall.prototype.setEnterpriseId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.enterprise.StallQuery.repeatedFields_ = [2,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.enterprise.StallQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.enterprise.StallQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.enterprise.StallQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.StallQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    merchantIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sortBysList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    idsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.enterprise.StallQuery}
 */
proto.cherry.enterprise.StallQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.enterprise.StallQuery;
  return proto.cherry.enterprise.StallQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.enterprise.StallQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.enterprise.StallQuery}
 */
proto.cherry.enterprise.StallQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMerchantIds(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSortBys(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.enterprise.StallQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.enterprise.StallQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.enterprise.StallQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.StallQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMerchantIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSortBysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.enterprise.StallQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 merchant_ids = 2;
 * @return {!Array<number>}
 */
proto.cherry.enterprise.StallQuery.prototype.getMerchantIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.setMerchantIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.addMerchantIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.clearMerchantIdsList = function() {
  return this.setMerchantIdsList([]);
};


/**
 * optional int32 page_current = 3;
 * @return {number}
 */
proto.cherry.enterprise.StallQuery.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 page_size = 4;
 * @return {number}
 */
proto.cherry.enterprise.StallQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated string sort_bys = 5;
 * @return {!Array<string>}
 */
proto.cherry.enterprise.StallQuery.prototype.getSortBysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.setSortBysList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.addSortBys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.clearSortBysList = function() {
  return this.setSortBysList([]);
};


/**
 * repeated int64 ids = 6;
 * @return {!Array<number>}
 */
proto.cherry.enterprise.StallQuery.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.StallQuery} returns this
 */
proto.cherry.enterprise.StallQuery.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.enterprise.StallListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.enterprise.StallListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.enterprise.StallListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.enterprise.StallListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.StallListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.enterprise.Stall.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.enterprise.StallListed}
 */
proto.cherry.enterprise.StallListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.enterprise.StallListed;
  return proto.cherry.enterprise.StallListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.enterprise.StallListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.enterprise.StallListed}
 */
proto.cherry.enterprise.StallListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.enterprise.Stall;
      reader.readMessage(value,proto.cherry.enterprise.Stall.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.enterprise.StallListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.enterprise.StallListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.enterprise.StallListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.enterprise.StallListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.enterprise.Stall.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_current = 1;
 * @return {number}
 */
proto.cherry.enterprise.StallListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.StallListed} returns this
 */
proto.cherry.enterprise.StallListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.cherry.enterprise.StallListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.StallListed} returns this
 */
proto.cherry.enterprise.StallListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.enterprise.StallListed.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.enterprise.StallListed} returns this
 */
proto.cherry.enterprise.StallListed.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Stall records = 4;
 * @return {!Array<!proto.cherry.enterprise.Stall>}
 */
proto.cherry.enterprise.StallListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.enterprise.Stall>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.enterprise.Stall, 4));
};


/**
 * @param {!Array<!proto.cherry.enterprise.Stall>} value
 * @return {!proto.cherry.enterprise.StallListed} returns this
*/
proto.cherry.enterprise.StallListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.enterprise.Stall=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.enterprise.Stall}
 */
proto.cherry.enterprise.StallListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.enterprise.Stall, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.enterprise.StallListed} returns this
 */
proto.cherry.enterprise.StallListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


goog.object.extend(exports, proto.cherry.enterprise);
