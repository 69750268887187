import { Model } from 'wbd-frontend-kit'
import { MerchantQuery, MerchantListed, Merchant } from '@/proto/cherry/enterprise/enterprise_pb'
import makeRequest from '@/utils/request/makeRequest'

import { enterpriseServiceClient } from '@/clients'

export type IMerchant = Merchant.AsObject

export const namespace = 'member-provider-list'
export interface IProviderList {
  providers: IMerchant[]
  totalCount: number
}

const initState: IProviderList = {
  providers: [],
  totalCount: 0,
}

export default {
  namespace,
  state: initState,
  effects: {
    *fetchProviderList({ payload: { pageCurrent, pageSize, keywords } }, { call, put }) {
      try {
        const req = makeRequest<MerchantQuery>(MerchantQuery, { pageCurrent, pageSize, name: keywords })
        const res: MerchantListed = yield call(enterpriseServiceClient.findMerchantList.bind(enterpriseServiceClient), req)
        const obj = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            providers: obj.recordsList,
            totalCount: obj.totalRecords,
          },
        })
      } catch (error) {}
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload }
    },
  },
} as Model
