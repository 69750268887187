import { execFunction, Model } from 'wbd-frontend-kit'
import { Spu, SpuListed, SpuQuery } from '@/proto/cherry/product/product_pb'
import makeRequest from '@/utils/request/makeRequest'

import { productServiceClient, enterpriseServiceClient } from '@/clients'
import { Merchant, MerchantQuery } from '@/proto/cherry/enterprise/enterprise_pb'

export type ISpu = Spu.AsObject
export type IMerchant = Merchant.AsObject

export enum Status {
  Enable = 1,
  Disable = 99,
}

export const namespace = 'member-provider-goods-list'
export interface ISpuManage {
  spus: ISpu[]
  totalCount: number
  merchant: IMerchant
}

const initState: ISpuManage = {
  spus: [],
  totalCount: 0,
  merchant: null,
}

export default {
  namespace,
  state: initState,
  effects: {
    *fetchSpuList({ payload }, { call, put }) {
      try {
        const req = makeRequest<SpuQuery>(SpuQuery, payload)
        const res: SpuListed = yield call(productServiceClient.findSpuList.bind(productServiceClient), req)
        const obj = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            spus: obj.recordsList,
            totalCount: obj.totalRecords,
          },
        })
      } catch (error) {}
    },
    *updateSpu({ payload, callback }, { call }) {
      try {
        const req = makeRequest<Spu>(Spu, payload)
        const res: Spu = yield call(productServiceClient.updateSpu.bind(productServiceClient), req)
        const obj = res.toObject()
        if (obj.id) {
          execFunction(callback)
        }
      } catch (error) {}
    },
    *findMerchant({ payload }, { call, put }) {
      try {
        const req = makeRequest<MerchantQuery>(MerchantQuery, payload)
        const res: Merchant = yield call(enterpriseServiceClient.findMerchant.bind(enterpriseServiceClient), req)
        const merchant = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            merchant,
          },
        })
      } catch (error) {}
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload }
    },
  },
} as Model
