import React, { useEffect, useState } from 'react'
import { Input, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import styles from './style.module.scss'
import { Link } from 'react-router-dom'
import { usePagination } from 'wbd-frontend-kit'
import { PROVIDER_CLASSIFICATION_MANAGEMENT, PROVIDER_GOODS_MANAGEMENT } from '@/router/config/member-manage/path'
import { IProviderList, namespace, IMerchant } from './model'
import { useDispatch, useSelector } from 'react-redux'

const columns: ColumnsType<IMerchant> = [
  {
    key: 'providerName',
    dataIndex: 'name',
    title: '供应商名称',
  },
  {
    key: 'marketName',
    dataIndex: 'marketName',
    title: '所属市场',
  },
  {
    key: 'companyName',
    dataIndex: 'enterpriseName',
    title: '所属企业',
  },
  {
    key: 'actions',
    title: '操作',
    align: 'center',
    render: (_, record) => (
      <>
        <Link className={styles.link} to={`${PROVIDER_GOODS_MANAGEMENT}?merchantId=${record.id}`}>
          商品管理
        </Link>
        |
        <Link className={styles.link} to={`${PROVIDER_CLASSIFICATION_MANAGEMENT}?merchantId=${record.id}`}>
          店铺分类管理
        </Link>
      </>
    ),
  },
]

const HomePage = () => {
  const [keywords, setKeywords] = useState<string>('')
  const [page, size, handleChange, handleShowSizeChange, reset] = usePagination({ page: 1, size: 10 })
  const { providers, totalCount }: IProviderList = useSelector(state => state[namespace])
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: `${namespace}/fetchProviderList`,
      payload: {
        pageCurrent: page,
        pageSize: size,
        keywords,
      },
    })
  }, [page, size, keywords])
  const handleSearch = (val: string) => {
    reset()
    setKeywords(val)
  }
  return (
    <>
      <div className={styles.searchBar}>
        <Input.Search placeholder="请输入供应商名称" allowClear enterButton="搜索" onSearch={handleSearch} />
      </div>
      <div style={{ paddingRight: 28 }}>
        <Table<IMerchant>
          columns={columns}
          dataSource={providers}
          pagination={{
            total: totalCount,
            current: page,
            pageSize: size,
            onChange: handleChange,
            onShowSizeChange: handleShowSizeChange,
            showSizeChanger: true,
          }}
        />
      </div>
    </>
  )
}

export default HomePage
