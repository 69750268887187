/**
 * @fileoverview gRPC-Web generated client stub for cherry.product
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as cherry_product_product_pb from '../../cherry/product/product_pb';


export class ProductServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoCreateBrand = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.Brand,
    (request: cherry_product_product_pb.Brand) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.Brand.deserializeBinary
  );

  createBrand(
    request: cherry_product_product_pb.Brand,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.Brand>;

  createBrand(
    request: cherry_product_product_pb.Brand,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Brand) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.Brand>;

  createBrand(
    request: cherry_product_product_pb.Brand,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Brand) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/CreateBrand',
        request,
        metadata || {},
        this.methodInfoCreateBrand,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/CreateBrand',
    request,
    metadata || {},
    this.methodInfoCreateBrand);
  }

  methodInfoUpdateBrand = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.Brand,
    (request: cherry_product_product_pb.Brand) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.Brand.deserializeBinary
  );

  updateBrand(
    request: cherry_product_product_pb.Brand,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.Brand>;

  updateBrand(
    request: cherry_product_product_pb.Brand,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Brand) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.Brand>;

  updateBrand(
    request: cherry_product_product_pb.Brand,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Brand) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/UpdateBrand',
        request,
        metadata || {},
        this.methodInfoUpdateBrand,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/UpdateBrand',
    request,
    metadata || {},
    this.methodInfoUpdateBrand);
  }

  methodInfoFindBrandList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.BrandListed,
    (request: cherry_product_product_pb.BrandQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.BrandListed.deserializeBinary
  );

  findBrandList(
    request: cherry_product_product_pb.BrandQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.BrandListed>;

  findBrandList(
    request: cherry_product_product_pb.BrandQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.BrandListed) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.BrandListed>;

  findBrandList(
    request: cherry_product_product_pb.BrandQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.BrandListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindBrandList',
        request,
        metadata || {},
        this.methodInfoFindBrandList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindBrandList',
    request,
    metadata || {},
    this.methodInfoFindBrandList);
  }

  methodInfoFindBrand = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.Brand,
    (request: cherry_product_product_pb.BrandQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.Brand.deserializeBinary
  );

  findBrand(
    request: cherry_product_product_pb.BrandQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.Brand>;

  findBrand(
    request: cherry_product_product_pb.BrandQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Brand) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.Brand>;

  findBrand(
    request: cherry_product_product_pb.BrandQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Brand) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindBrand',
        request,
        metadata || {},
        this.methodInfoFindBrand,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindBrand',
    request,
    metadata || {},
    this.methodInfoFindBrand);
  }

  methodInfoCreateItemAttr = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ItemAttr,
    (request: cherry_product_product_pb.ItemAttr) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ItemAttr.deserializeBinary
  );

  createItemAttr(
    request: cherry_product_product_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ItemAttr>;

  createItemAttr(
    request: cherry_product_product_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemAttr) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ItemAttr>;

  createItemAttr(
    request: cherry_product_product_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemAttr) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/CreateItemAttr',
        request,
        metadata || {},
        this.methodInfoCreateItemAttr,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/CreateItemAttr',
    request,
    metadata || {},
    this.methodInfoCreateItemAttr);
  }

  methodInfoFindItemAttr = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ItemAttr,
    (request: cherry_product_product_pb.ItemAttrQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ItemAttr.deserializeBinary
  );

  findItemAttr(
    request: cherry_product_product_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ItemAttr>;

  findItemAttr(
    request: cherry_product_product_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemAttr) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ItemAttr>;

  findItemAttr(
    request: cherry_product_product_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemAttr) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindItemAttr',
        request,
        metadata || {},
        this.methodInfoFindItemAttr,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindItemAttr',
    request,
    metadata || {},
    this.methodInfoFindItemAttr);
  }

  methodInfoUpdateItemAttr = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ItemAttr,
    (request: cherry_product_product_pb.ItemAttr) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ItemAttr.deserializeBinary
  );

  updateItemAttr(
    request: cherry_product_product_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ItemAttr>;

  updateItemAttr(
    request: cherry_product_product_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemAttr) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ItemAttr>;

  updateItemAttr(
    request: cherry_product_product_pb.ItemAttr,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemAttr) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/UpdateItemAttr',
        request,
        metadata || {},
        this.methodInfoUpdateItemAttr,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/UpdateItemAttr',
    request,
    metadata || {},
    this.methodInfoUpdateItemAttr);
  }

  methodInfoFindItemAttrList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ItemAttrListed,
    (request: cherry_product_product_pb.ItemAttrQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ItemAttrListed.deserializeBinary
  );

  findItemAttrList(
    request: cherry_product_product_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ItemAttrListed>;

  findItemAttrList(
    request: cherry_product_product_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemAttrListed) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ItemAttrListed>;

  findItemAttrList(
    request: cherry_product_product_pb.ItemAttrQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemAttrListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindItemAttrList',
        request,
        metadata || {},
        this.methodInfoFindItemAttrList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindItemAttrList',
    request,
    metadata || {},
    this.methodInfoFindItemAttrList);
  }

  methodInfoCreateItemCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ItemCategory,
    (request: cherry_product_product_pb.ItemCategory) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ItemCategory.deserializeBinary
  );

  createItemCategory(
    request: cherry_product_product_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ItemCategory>;

  createItemCategory(
    request: cherry_product_product_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemCategory) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ItemCategory>;

  createItemCategory(
    request: cherry_product_product_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/CreateItemCategory',
        request,
        metadata || {},
        this.methodInfoCreateItemCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/CreateItemCategory',
    request,
    metadata || {},
    this.methodInfoCreateItemCategory);
  }

  methodInfoFindItemCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ItemCategory,
    (request: cherry_product_product_pb.ItemCategoryQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ItemCategory.deserializeBinary
  );

  findItemCategory(
    request: cherry_product_product_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ItemCategory>;

  findItemCategory(
    request: cherry_product_product_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemCategory) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ItemCategory>;

  findItemCategory(
    request: cherry_product_product_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindItemCategory',
        request,
        metadata || {},
        this.methodInfoFindItemCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindItemCategory',
    request,
    metadata || {},
    this.methodInfoFindItemCategory);
  }

  methodInfoUpdateItemCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ItemCategory,
    (request: cherry_product_product_pb.ItemCategory) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ItemCategory.deserializeBinary
  );

  updateItemCategory(
    request: cherry_product_product_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ItemCategory>;

  updateItemCategory(
    request: cherry_product_product_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemCategory) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ItemCategory>;

  updateItemCategory(
    request: cherry_product_product_pb.ItemCategory,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/UpdateItemCategory',
        request,
        metadata || {},
        this.methodInfoUpdateItemCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/UpdateItemCategory',
    request,
    metadata || {},
    this.methodInfoUpdateItemCategory);
  }

  methodInfoFindItemCategoryList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ItemCategoryListed,
    (request: cherry_product_product_pb.ItemCategoryQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ItemCategoryListed.deserializeBinary
  );

  findItemCategoryList(
    request: cherry_product_product_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ItemCategoryListed>;

  findItemCategoryList(
    request: cherry_product_product_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemCategoryListed) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ItemCategoryListed>;

  findItemCategoryList(
    request: cherry_product_product_pb.ItemCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ItemCategoryListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindItemCategoryList',
        request,
        metadata || {},
        this.methodInfoFindItemCategoryList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindItemCategoryList',
    request,
    metadata || {},
    this.methodInfoFindItemCategoryList);
  }

  methodInfoCreateProductCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ProductCategory,
    (request: cherry_product_product_pb.ProductCategory) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ProductCategory.deserializeBinary
  );

  createProductCategory(
    request: cherry_product_product_pb.ProductCategory,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ProductCategory>;

  createProductCategory(
    request: cherry_product_product_pb.ProductCategory,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ProductCategory) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ProductCategory>;

  createProductCategory(
    request: cherry_product_product_pb.ProductCategory,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ProductCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/CreateProductCategory',
        request,
        metadata || {},
        this.methodInfoCreateProductCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/CreateProductCategory',
    request,
    metadata || {},
    this.methodInfoCreateProductCategory);
  }

  methodInfoFindProductCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ProductCategory,
    (request: cherry_product_product_pb.ProductCategoryQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ProductCategory.deserializeBinary
  );

  findProductCategory(
    request: cherry_product_product_pb.ProductCategoryQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ProductCategory>;

  findProductCategory(
    request: cherry_product_product_pb.ProductCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ProductCategory) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ProductCategory>;

  findProductCategory(
    request: cherry_product_product_pb.ProductCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ProductCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindProductCategory',
        request,
        metadata || {},
        this.methodInfoFindProductCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindProductCategory',
    request,
    metadata || {},
    this.methodInfoFindProductCategory);
  }

  methodInfoUpdateProductCategory = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ProductCategory,
    (request: cherry_product_product_pb.ProductCategory) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ProductCategory.deserializeBinary
  );

  updateProductCategory(
    request: cherry_product_product_pb.ProductCategory,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ProductCategory>;

  updateProductCategory(
    request: cherry_product_product_pb.ProductCategory,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ProductCategory) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ProductCategory>;

  updateProductCategory(
    request: cherry_product_product_pb.ProductCategory,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ProductCategory) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/UpdateProductCategory',
        request,
        metadata || {},
        this.methodInfoUpdateProductCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/UpdateProductCategory',
    request,
    metadata || {},
    this.methodInfoUpdateProductCategory);
  }

  methodInfoFindProductCategoryList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.ProductCategoryListed,
    (request: cherry_product_product_pb.ProductCategoryQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.ProductCategoryListed.deserializeBinary
  );

  findProductCategoryList(
    request: cherry_product_product_pb.ProductCategoryQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.ProductCategoryListed>;

  findProductCategoryList(
    request: cherry_product_product_pb.ProductCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ProductCategoryListed) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.ProductCategoryListed>;

  findProductCategoryList(
    request: cherry_product_product_pb.ProductCategoryQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.ProductCategoryListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindProductCategoryList',
        request,
        metadata || {},
        this.methodInfoFindProductCategoryList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindProductCategoryList',
    request,
    metadata || {},
    this.methodInfoFindProductCategoryList);
  }

  methodInfoCreateSpu = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.Spu,
    (request: cherry_product_product_pb.Spu) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.Spu.deserializeBinary
  );

  createSpu(
    request: cherry_product_product_pb.Spu,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.Spu>;

  createSpu(
    request: cherry_product_product_pb.Spu,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Spu) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.Spu>;

  createSpu(
    request: cherry_product_product_pb.Spu,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Spu) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/CreateSpu',
        request,
        metadata || {},
        this.methodInfoCreateSpu,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/CreateSpu',
    request,
    metadata || {},
    this.methodInfoCreateSpu);
  }

  methodInfoFindSpu = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.Spu,
    (request: cherry_product_product_pb.SpuQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.Spu.deserializeBinary
  );

  findSpu(
    request: cherry_product_product_pb.SpuQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.Spu>;

  findSpu(
    request: cherry_product_product_pb.SpuQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Spu) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.Spu>;

  findSpu(
    request: cherry_product_product_pb.SpuQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Spu) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindSpu',
        request,
        metadata || {},
        this.methodInfoFindSpu,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindSpu',
    request,
    metadata || {},
    this.methodInfoFindSpu);
  }

  methodInfoUpdateSpu = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.Spu,
    (request: cherry_product_product_pb.Spu) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.Spu.deserializeBinary
  );

  updateSpu(
    request: cherry_product_product_pb.Spu,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.Spu>;

  updateSpu(
    request: cherry_product_product_pb.Spu,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Spu) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.Spu>;

  updateSpu(
    request: cherry_product_product_pb.Spu,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Spu) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/UpdateSpu',
        request,
        metadata || {},
        this.methodInfoUpdateSpu,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/UpdateSpu',
    request,
    metadata || {},
    this.methodInfoUpdateSpu);
  }

  methodInfoFindSpuList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.SpuListed,
    (request: cherry_product_product_pb.SpuQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.SpuListed.deserializeBinary
  );

  findSpuList(
    request: cherry_product_product_pb.SpuQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.SpuListed>;

  findSpuList(
    request: cherry_product_product_pb.SpuQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.SpuListed) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.SpuListed>;

  findSpuList(
    request: cherry_product_product_pb.SpuQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.SpuListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindSpuList',
        request,
        metadata || {},
        this.methodInfoFindSpuList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindSpuList',
    request,
    metadata || {},
    this.methodInfoFindSpuList);
  }

  methodInfoSaveSpuAttrs = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.SpuAttrs,
    (request: cherry_product_product_pb.SpuAttrsReq) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.SpuAttrs.deserializeBinary
  );

  saveSpuAttrs(
    request: cherry_product_product_pb.SpuAttrsReq,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.SpuAttrs>;

  saveSpuAttrs(
    request: cherry_product_product_pb.SpuAttrsReq,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.SpuAttrs) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.SpuAttrs>;

  saveSpuAttrs(
    request: cherry_product_product_pb.SpuAttrsReq,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.SpuAttrs) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/SaveSpuAttrs',
        request,
        metadata || {},
        this.methodInfoSaveSpuAttrs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/SaveSpuAttrs',
    request,
    metadata || {},
    this.methodInfoSaveSpuAttrs);
  }

  methodInfoCreateSkus = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.SkuListed,
    (request: cherry_product_product_pb.SkuCreateReq) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.SkuListed.deserializeBinary
  );

  createSkus(
    request: cherry_product_product_pb.SkuCreateReq,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.SkuListed>;

  createSkus(
    request: cherry_product_product_pb.SkuCreateReq,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.SkuListed) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.SkuListed>;

  createSkus(
    request: cherry_product_product_pb.SkuCreateReq,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.SkuListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/CreateSkus',
        request,
        metadata || {},
        this.methodInfoCreateSkus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/CreateSkus',
    request,
    metadata || {},
    this.methodInfoCreateSkus);
  }

  methodInfoUpdateSku = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.Sku,
    (request: cherry_product_product_pb.Sku) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.Sku.deserializeBinary
  );

  updateSku(
    request: cherry_product_product_pb.Sku,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.Sku>;

  updateSku(
    request: cherry_product_product_pb.Sku,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Sku) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.Sku>;

  updateSku(
    request: cherry_product_product_pb.Sku,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.Sku) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/UpdateSku',
        request,
        metadata || {},
        this.methodInfoUpdateSku,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/UpdateSku',
    request,
    metadata || {},
    this.methodInfoUpdateSku);
  }

  methodInfoFindSkuList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_product_product_pb.SkuListed,
    (request: cherry_product_product_pb.SkuQuery) => {
      return request.serializeBinary();
    },
    cherry_product_product_pb.SkuListed.deserializeBinary
  );

  findSkuList(
    request: cherry_product_product_pb.SkuQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_product_product_pb.SkuListed>;

  findSkuList(
    request: cherry_product_product_pb.SkuQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_product_product_pb.SkuListed) => void): grpcWeb.ClientReadableStream<cherry_product_product_pb.SkuListed>;

  findSkuList(
    request: cherry_product_product_pb.SkuQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_product_product_pb.SkuListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.product.ProductService/FindSkuList',
        request,
        metadata || {},
        this.methodInfoFindSkuList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.product.ProductService/FindSkuList',
    request,
    metadata || {},
    this.methodInfoFindSkuList);
  }

}

