import config from '@/config'
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Divider, Modal, Tooltip, Tree, Form, Input, Upload, Space, message, Affix } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { namespace, ISysClassfication } from './model'
import styles from './style.module.scss'

const FormItem = Form.Item

const normFile = (e: any) => {
  // 图片的限制的话可以考虑这里来判断
  if (Array.isArray(e)) {
    return e
  }
  return e && e.fileList
}

const onPreview = async file => {
  let src = file.url
  if (!src) {
    src = await new Promise(resolve => {
      const reader = new FileReader()
      reader.readAsDataURL(file.originFileObj)
      reader.onload = () => resolve(reader.result)
    })
  }
  const image = new Image()
  image.src = src
  const imgWindow = window.open(src)
  imgWindow.document.write(image.outerHTML)
}

function getImageUrl(imgObj: any[]) {
  if (!imgObj || imgObj.length === 0) {
    return ''
  }
  const img = imgObj[0]
  return img.url || img.response.fileUrl
}

const SysClassfication: React.FC = () => {
  const dispatch = useDispatch()
  const [selectedKeys, setSelectedKeys] = useState([])
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const { itemCategorys, itemCategory }: ISysClassfication = useSelector(state => state[namespace])
  useEffect(() => {
    dispatch({
      type: `${namespace}/queryItemCategoryList`,
    })
  }, [])
  // 获取分类信息
  useEffect(() => {
    if (!selectedKeys[0]) {
      return
    }
    dispatch({
      type: `${namespace}/queryItemCategory`,
      payload: {
        id: selectedKeys[0],
      },
    })
  }, [selectedKeys])
  function handleDelete() {
    Modal.confirm({
      title: '请确认',
      content: '删除该分类选项后将不可恢复，且已使用该分类的商品将下架！',
      onOk: () => {
        dispatch({
          type: `${namespace}/deleteItemCategory`,
          payload: {
            id: selectedKeys[0],
            deleted: 1,
          },
        })
      },
    })
  }
  function handleOpenModal(type: string) {
    setVisible(true)
    type === 'edit' ? form.setFieldsValue(itemCategory) : form.resetFields()
  }
  function handleFinish(values) {
    const imgUrl = getImageUrl(values.imgUrl)
    if (values.id) {
      // 修改逻辑
      dispatch({
        type: `${namespace}/updateItemCategory`,
        payload: {
          ...values,
          imgUrl,
        },
        callback: () => {
          setVisible(false)
          message.success('修改分类成功！')
          dispatch({
            type: `${namespace}/queryItemCategoryList`,
          })
        },
      })
    } else {
      dispatch({
        type: `${namespace}/createItemCategory`,
        payload: {
          parentId: itemCategory?.id || 0,
          name: values.name,
          imgUrl,
        },
        callback: () => {
          setVisible(false)
          message.success('创建分类成功！')
          dispatch({
            type: `${namespace}/queryItemCategoryList`,
          })
        },
      })
    }
  }
  return (
    <div>
      <div className={styles.actionRow}>
        <Button style={{ marginRight: 12 }} type="primary" onClick={() => handleOpenModal('new')}>
          添加一级分类
        </Button>
      </div>
      <Divider />
      <div className={styles.content}>
        <div className={styles.treeArea}>
          <Tree onSelect={keys => setSelectedKeys(keys)} treeData={itemCategorys} />
        </div>
        {itemCategory ? (
          <Affix offsetTop={200}>
            <Card
              className={styles.goodsCard}
              cover={
                <div>
                  <img width="100%" alt="example" src={(itemCategory.imgUrl as any)[0].url} />
                </div>
              }
              actions={[
                <Tooltip title="修改">
                  <Button type="link" icon={<EditOutlined key="edit" onClick={() => handleOpenModal('edit')} />} />
                </Tooltip>,
                <Tooltip title="删除">
                  <Button type="link" icon={<CloseOutlined key="del" />} onClick={handleDelete} />
                </Tooltip>,
                <Tooltip title="添加子分类">
                  <Button type="link" icon={<PlusOutlined key="addChildren" onClick={() => handleOpenModal('new')} />} />
                </Tooltip>,
              ]}>
              <Card.Meta title={itemCategory.name} />
            </Card>
          </Affix>
        ) : null}
      </div>
      <Modal title="分类详情" footer={false} visible={visible}>
        <Form form={form} labelCol={{ span: 4 }} onFinish={handleFinish}>
          <FormItem label="名称" name="name" rules={[{ required: true, message: '请输入分类名称' }]}>
            <Input placeholder="请输入商品名称" />
          </FormItem>
          <FormItem hidden name="id">
            <Input />
          </FormItem>
          <FormItem name="imgUrl" label="分类图片" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload
              maxCount={1}
              onPreview={onPreview}
              name="file"
              action={`/_files/upload?x-token-value=${localStorage.getItem(config.authKey)}`}
              listType="picture-card">
              <PlusOutlined />
            </Upload>
          </FormItem>
          <FormItem noStyle>
            <div className={styles.modalActionBar}>
              <Space>
                <Button type="primary" htmlType="submit">
                  确认
                </Button>
                <Button onClick={() => setVisible(false)}>取消</Button>
              </Space>
            </div>
          </FormItem>
        </Form>
      </Modal>
    </div>
  )
}

export default SysClassfication
