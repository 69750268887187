import React from 'react'

const NoDataPage: React.FC = () => {
  return (
    <div>
      <p>路由未匹配 缺省页</p>
    </div>
  )
}

export default NoDataPage
