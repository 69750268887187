import { useLocation } from 'react-router-dom'
import { Dict } from 'wbd-frontend-kit'

type returned = string | Dict

const useSearchParams = <T = returned>(key?: string): T => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  if (key) {
    return params.get(key) as any
  }
  const result = {}
  params.forEach((val, key) => (result[key] = val))
  return result as any
}

export default useSearchParams
