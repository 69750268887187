// source: cherry/user/user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.cherry.user.DevicePlatform', null, global);
goog.exportSymbol('proto.cherry.user.User', null, global);
goog.exportSymbol('proto.cherry.user.UserContactDTO', null, global);
goog.exportSymbol('proto.cherry.user.UserContactListedDTO', null, global);
goog.exportSymbol('proto.cherry.user.UserCredentialDTO', null, global);
goog.exportSymbol('proto.cherry.user.UserDevice', null, global);
goog.exportSymbol('proto.cherry.user.UserDeviceListed', null, global);
goog.exportSymbol('proto.cherry.user.UserListed', null, global);
goog.exportSymbol('proto.cherry.user.UserQuery', null, global);
goog.exportSymbol('proto.cherry.user.UserSex', null, global);
goog.exportSymbol('proto.cherry.user.UserStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.user.UserQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.user.UserQuery.repeatedFields_, null);
};
goog.inherits(proto.cherry.user.UserQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.user.UserQuery.displayName = 'proto.cherry.user.UserQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.user.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.user.User.repeatedFields_, null);
};
goog.inherits(proto.cherry.user.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.user.User.displayName = 'proto.cherry.user.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.user.UserCredentialDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cherry.user.UserCredentialDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.user.UserCredentialDTO.displayName = 'proto.cherry.user.UserCredentialDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.user.UserContactDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cherry.user.UserContactDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.user.UserContactDTO.displayName = 'proto.cherry.user.UserContactDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.user.UserListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.user.UserListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.user.UserListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.user.UserListed.displayName = 'proto.cherry.user.UserListed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.user.UserContactListedDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.user.UserContactListedDTO.repeatedFields_, null);
};
goog.inherits(proto.cherry.user.UserContactListedDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.user.UserContactListedDTO.displayName = 'proto.cherry.user.UserContactListedDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.user.UserDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cherry.user.UserDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.user.UserDevice.displayName = 'proto.cherry.user.UserDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.user.UserDeviceListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.user.UserDeviceListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.user.UserDeviceListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.user.UserDeviceListed.displayName = 'proto.cherry.user.UserDeviceListed';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.user.UserQuery.repeatedFields_ = [5,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.user.UserQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.user.UserQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.user.UserQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    idsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 6, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sortBysList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    fetchFieldsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.user.UserQuery}
 */
proto.cherry.user.UserQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.user.UserQuery;
  return proto.cherry.user.UserQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.user.UserQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.user.UserQuery}
 */
proto.cherry.user.UserQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addSortBys(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addFetchFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.user.UserQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.user.UserQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.user.UserQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getSortBysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getFetchFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.user.UserQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.cherry.user.UserQuery.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.cherry.user.UserQuery.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.cherry.user.UserQuery.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated int64 ids = 5;
 * @return {!Array<number>}
 */
proto.cherry.user.UserQuery.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page_current = 6;
 * @return {number}
 */
proto.cherry.user.UserQuery.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 page_size = 7;
 * @return {number}
 */
proto.cherry.user.UserQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string sort_bys = 8;
 * @return {!Array<string>}
 */
proto.cherry.user.UserQuery.prototype.getSortBysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.setSortBysList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.addSortBys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.clearSortBysList = function() {
  return this.setSortBysList([]);
};


/**
 * repeated string fetch_fields = 9;
 * @return {!Array<string>}
 */
proto.cherry.user.UserQuery.prototype.getFetchFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.setFetchFieldsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.addFetchFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.user.UserQuery} returns this
 */
proto.cherry.user.UserQuery.prototype.clearFetchFieldsList = function() {
  return this.setFetchFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.user.User.repeatedFields_ = [15,16,17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.user.User.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.user.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.user.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sex: jspb.Message.getFieldWithDefault(msg, 6, 0),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    sessionUserId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    enterpriseId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    contactPhone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 13, ""),
    contactAddress: jspb.Message.getFieldWithDefault(msg, 14, ""),
    permissionsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    merchantIdsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    enterpriseIdsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.user.User}
 */
proto.cherry.user.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.user.User;
  return proto.cherry.user.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.user.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.user.User}
 */
proto.cherry.user.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {!proto.cherry.user.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {!proto.cherry.user.UserSex} */ (reader.readEnum());
      msg.setSex(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSessionUserId(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnterpriseId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhone(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactAddress(value);
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPermissions(values[i]);
      }
      break;
    case 16:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMerchantIds(values[i]);
      }
      break;
    case 17:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEnterpriseIds(values[i]);
      }
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.user.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.user.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.user.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getSex();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getSessionUserId();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getEnterpriseId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getContactPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getContactAddress();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      15,
      f
    );
  }
  f = message.getMerchantIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      16,
      f
    );
  }
  f = message.getEnterpriseIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      17,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.user.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.cherry.user.User.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.cherry.user.User.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.cherry.user.User.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional UserStatus status = 5;
 * @return {!proto.cherry.user.UserStatus}
 */
proto.cherry.user.User.prototype.getStatus = function() {
  return /** @type {!proto.cherry.user.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.cherry.user.UserStatus} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional UserSex sex = 6;
 * @return {!proto.cherry.user.UserSex}
 */
proto.cherry.user.User.prototype.getSex = function() {
  return /** @type {!proto.cherry.user.UserSex} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.cherry.user.UserSex} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setSex = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.cherry.user.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 create_at = 8;
 * @return {number}
 */
proto.cherry.user.User.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.cherry.user.User.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 session_user_id = 10;
 * @return {number}
 */
proto.cherry.user.User.prototype.getSessionUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setSessionUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 enterprise_id = 11;
 * @return {number}
 */
proto.cherry.user.User.prototype.getEnterpriseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setEnterpriseId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string contact_phone = 12;
 * @return {string}
 */
proto.cherry.user.User.prototype.getContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setContactPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string contact_email = 13;
 * @return {string}
 */
proto.cherry.user.User.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string contact_address = 14;
 * @return {string}
 */
proto.cherry.user.User.prototype.getContactAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setContactAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated int32 permissions = 15;
 * @return {!Array<number>}
 */
proto.cherry.user.User.prototype.getPermissionsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setPermissionsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.addPermissions = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.clearPermissionsList = function() {
  return this.setPermissionsList([]);
};


/**
 * repeated int64 merchant_ids = 16;
 * @return {!Array<number>}
 */
proto.cherry.user.User.prototype.getMerchantIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setMerchantIdsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.addMerchantIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.clearMerchantIdsList = function() {
  return this.setMerchantIdsList([]);
};


/**
 * repeated int64 enterprise_ids = 17;
 * @return {!Array<number>}
 */
proto.cherry.user.User.prototype.getEnterpriseIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setEnterpriseIdsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.addEnterpriseIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.clearEnterpriseIdsList = function() {
  return this.setEnterpriseIdsList([]);
};


/**
 * optional string avatar_url = 18;
 * @return {string}
 */
proto.cherry.user.User.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.User} returns this
 */
proto.cherry.user.User.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.user.UserCredentialDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.user.UserCredentialDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.user.UserCredentialDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserCredentialDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    saltPassword: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.user.UserCredentialDTO}
 */
proto.cherry.user.UserCredentialDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.user.UserCredentialDTO;
  return proto.cherry.user.UserCredentialDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.user.UserCredentialDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.user.UserCredentialDTO}
 */
proto.cherry.user.UserCredentialDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSaltPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.user.UserCredentialDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.user.UserCredentialDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.user.UserCredentialDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserCredentialDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSaltPassword();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.user.UserCredentialDTO.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserCredentialDTO} returns this
 */
proto.cherry.user.UserCredentialDTO.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.cherry.user.UserCredentialDTO.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserCredentialDTO} returns this
 */
proto.cherry.user.UserCredentialDTO.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.cherry.user.UserCredentialDTO.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserCredentialDTO} returns this
 */
proto.cherry.user.UserCredentialDTO.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.cherry.user.UserCredentialDTO.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserCredentialDTO} returns this
 */
proto.cherry.user.UserCredentialDTO.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.cherry.user.UserCredentialDTO.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserCredentialDTO} returns this
 */
proto.cherry.user.UserCredentialDTO.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string salt_password = 6;
 * @return {string}
 */
proto.cherry.user.UserCredentialDTO.prototype.getSaltPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserCredentialDTO} returns this
 */
proto.cherry.user.UserCredentialDTO.prototype.setSaltPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.user.UserContactDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.user.UserContactDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.user.UserContactDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserContactDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contactPhone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactAddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    enterpriseId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    enterpriseName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    avatarUrl: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.user.UserContactDTO}
 */
proto.cherry.user.UserContactDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.user.UserContactDTO;
  return proto.cherry.user.UserContactDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.user.UserContactDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.user.UserContactDTO}
 */
proto.cherry.user.UserContactDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactAddress(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnterpriseId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnterpriseName(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvatarUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.user.UserContactDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.user.UserContactDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.user.UserContactDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserContactDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContactPhone();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEnterpriseId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEnterpriseName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAvatarUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.user.UserContactDTO.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserContactDTO} returns this
 */
proto.cherry.user.UserContactDTO.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.user.UserContactDTO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserContactDTO} returns this
 */
proto.cherry.user.UserContactDTO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contact_phone = 3;
 * @return {string}
 */
proto.cherry.user.UserContactDTO.prototype.getContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserContactDTO} returns this
 */
proto.cherry.user.UserContactDTO.prototype.setContactPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contact_email = 4;
 * @return {string}
 */
proto.cherry.user.UserContactDTO.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserContactDTO} returns this
 */
proto.cherry.user.UserContactDTO.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contact_address = 5;
 * @return {string}
 */
proto.cherry.user.UserContactDTO.prototype.getContactAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserContactDTO} returns this
 */
proto.cherry.user.UserContactDTO.prototype.setContactAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 enterprise_id = 6;
 * @return {number}
 */
proto.cherry.user.UserContactDTO.prototype.getEnterpriseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserContactDTO} returns this
 */
proto.cherry.user.UserContactDTO.prototype.setEnterpriseId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string enterprise_name = 7;
 * @return {string}
 */
proto.cherry.user.UserContactDTO.prototype.getEnterpriseName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserContactDTO} returns this
 */
proto.cherry.user.UserContactDTO.prototype.setEnterpriseName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string avatar_url = 8;
 * @return {string}
 */
proto.cherry.user.UserContactDTO.prototype.getAvatarUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.user.UserContactDTO} returns this
 */
proto.cherry.user.UserContactDTO.prototype.setAvatarUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.user.UserListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.user.UserListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.user.UserListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.user.UserListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.user.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.user.UserListed}
 */
proto.cherry.user.UserListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.user.UserListed;
  return proto.cherry.user.UserListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.user.UserListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.user.UserListed}
 */
proto.cherry.user.UserListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.user.User;
      reader.readMessage(value,proto.cherry.user.User.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.user.UserListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.user.UserListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.user.UserListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.user.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_current = 1;
 * @return {number}
 */
proto.cherry.user.UserListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserListed} returns this
 */
proto.cherry.user.UserListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.cherry.user.UserListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserListed} returns this
 */
proto.cherry.user.UserListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.user.UserListed.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserListed} returns this
 */
proto.cherry.user.UserListed.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated User records = 4;
 * @return {!Array<!proto.cherry.user.User>}
 */
proto.cherry.user.UserListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.user.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.user.User, 4));
};


/**
 * @param {!Array<!proto.cherry.user.User>} value
 * @return {!proto.cherry.user.UserListed} returns this
*/
proto.cherry.user.UserListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.user.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.user.User}
 */
proto.cherry.user.UserListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.user.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.user.UserListed} returns this
 */
proto.cherry.user.UserListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.user.UserContactListedDTO.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.user.UserContactListedDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.user.UserContactListedDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.user.UserContactListedDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserContactListedDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.user.UserContactDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.user.UserContactListedDTO}
 */
proto.cherry.user.UserContactListedDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.user.UserContactListedDTO;
  return proto.cherry.user.UserContactListedDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.user.UserContactListedDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.user.UserContactListedDTO}
 */
proto.cherry.user.UserContactListedDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.user.UserContactDTO;
      reader.readMessage(value,proto.cherry.user.UserContactDTO.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.user.UserContactListedDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.user.UserContactListedDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.user.UserContactListedDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserContactListedDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.user.UserContactDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_current = 1;
 * @return {number}
 */
proto.cherry.user.UserContactListedDTO.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserContactListedDTO} returns this
 */
proto.cherry.user.UserContactListedDTO.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.cherry.user.UserContactListedDTO.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserContactListedDTO} returns this
 */
proto.cherry.user.UserContactListedDTO.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.user.UserContactListedDTO.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserContactListedDTO} returns this
 */
proto.cherry.user.UserContactListedDTO.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated UserContactDTO records = 4;
 * @return {!Array<!proto.cherry.user.UserContactDTO>}
 */
proto.cherry.user.UserContactListedDTO.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.user.UserContactDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.user.UserContactDTO, 4));
};


/**
 * @param {!Array<!proto.cherry.user.UserContactDTO>} value
 * @return {!proto.cherry.user.UserContactListedDTO} returns this
*/
proto.cherry.user.UserContactListedDTO.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.user.UserContactDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.user.UserContactDTO}
 */
proto.cherry.user.UserContactListedDTO.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.user.UserContactDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.user.UserContactListedDTO} returns this
 */
proto.cherry.user.UserContactListedDTO.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.user.UserDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.user.UserDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.user.UserDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    platform: jspb.Message.getFieldWithDefault(msg, 2, 0),
    extrasMap: (f = msg.getExtrasMap()) ? f.toObject(includeInstance, undefined) : [],
    createAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.user.UserDevice}
 */
proto.cherry.user.UserDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.user.UserDevice;
  return proto.cherry.user.UserDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.user.UserDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.user.UserDevice}
 */
proto.cherry.user.UserDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {!proto.cherry.user.DevicePlatform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 3:
      var value = msg.getExtrasMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.user.UserDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.user.UserDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.user.UserDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getExtrasMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional int64 user_id = 1;
 * @return {number}
 */
proto.cherry.user.UserDevice.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserDevice} returns this
 */
proto.cherry.user.UserDevice.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DevicePlatform platform = 2;
 * @return {!proto.cherry.user.DevicePlatform}
 */
proto.cherry.user.UserDevice.prototype.getPlatform = function() {
  return /** @type {!proto.cherry.user.DevicePlatform} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.cherry.user.DevicePlatform} value
 * @return {!proto.cherry.user.UserDevice} returns this
 */
proto.cherry.user.UserDevice.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * map<string, string> extras = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.cherry.user.UserDevice.prototype.getExtrasMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.cherry.user.UserDevice} returns this
 */
proto.cherry.user.UserDevice.prototype.clearExtrasMap = function() {
  this.getExtrasMap().clear();
  return this;};


/**
 * optional int64 create_at = 10;
 * @return {number}
 */
proto.cherry.user.UserDevice.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserDevice} returns this
 */
proto.cherry.user.UserDevice.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 updated_at = 11;
 * @return {number}
 */
proto.cherry.user.UserDevice.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserDevice} returns this
 */
proto.cherry.user.UserDevice.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.user.UserDeviceListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.user.UserDeviceListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.user.UserDeviceListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.user.UserDeviceListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserDeviceListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.user.UserDevice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.user.UserDeviceListed}
 */
proto.cherry.user.UserDeviceListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.user.UserDeviceListed;
  return proto.cherry.user.UserDeviceListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.user.UserDeviceListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.user.UserDeviceListed}
 */
proto.cherry.user.UserDeviceListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.user.UserDevice;
      reader.readMessage(value,proto.cherry.user.UserDevice.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.user.UserDeviceListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.user.UserDeviceListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.user.UserDeviceListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.user.UserDeviceListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.user.UserDevice.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_current = 1;
 * @return {number}
 */
proto.cherry.user.UserDeviceListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserDeviceListed} returns this
 */
proto.cherry.user.UserDeviceListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.cherry.user.UserDeviceListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserDeviceListed} returns this
 */
proto.cherry.user.UserDeviceListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.user.UserDeviceListed.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.user.UserDeviceListed} returns this
 */
proto.cherry.user.UserDeviceListed.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated UserDevice records = 4;
 * @return {!Array<!proto.cherry.user.UserDevice>}
 */
proto.cherry.user.UserDeviceListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.user.UserDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.user.UserDevice, 4));
};


/**
 * @param {!Array<!proto.cherry.user.UserDevice>} value
 * @return {!proto.cherry.user.UserDeviceListed} returns this
*/
proto.cherry.user.UserDeviceListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.user.UserDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.user.UserDevice}
 */
proto.cherry.user.UserDeviceListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.user.UserDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.user.UserDeviceListed} returns this
 */
proto.cherry.user.UserDeviceListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * @enum {number}
 */
proto.cherry.user.UserStatus = {
  NORMAL: 0,
  ACTIVE: 1,
  DISABLE: 99
};

/**
 * @enum {number}
 */
proto.cherry.user.UserSex = {
  UNKNOWN: 0,
  MALE: 1,
  FEMALE: 2
};

/**
 * @enum {number}
 */
proto.cherry.user.DevicePlatform = {
  WEB: 0,
  ANDROID: 1,
  IOS: 2
};

goog.object.extend(exports, proto.cherry.user);
