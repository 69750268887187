// source: cherry/product/product.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var cherry_cherry_pb = require('../../cherry/cherry_pb.js');
goog.object.extend(proto, cherry_cherry_pb);
goog.exportSymbol('proto.cherry.product.Brand', null, global);
goog.exportSymbol('proto.cherry.product.BrandListed', null, global);
goog.exportSymbol('proto.cherry.product.BrandQuery', null, global);
goog.exportSymbol('proto.cherry.product.ItemAttr', null, global);
goog.exportSymbol('proto.cherry.product.ItemAttr.ItemAttrType', null, global);
goog.exportSymbol('proto.cherry.product.ItemAttrListed', null, global);
goog.exportSymbol('proto.cherry.product.ItemAttrQuery', null, global);
goog.exportSymbol('proto.cherry.product.ItemCategory', null, global);
goog.exportSymbol('proto.cherry.product.ItemCategoryListed', null, global);
goog.exportSymbol('proto.cherry.product.ItemCategoryQuery', null, global);
goog.exportSymbol('proto.cherry.product.ProductCategory', null, global);
goog.exportSymbol('proto.cherry.product.ProductCategoryListed', null, global);
goog.exportSymbol('proto.cherry.product.ProductCategoryQuery', null, global);
goog.exportSymbol('proto.cherry.product.Sku', null, global);
goog.exportSymbol('proto.cherry.product.SkuAttr', null, global);
goog.exportSymbol('proto.cherry.product.SkuCreateReq', null, global);
goog.exportSymbol('proto.cherry.product.SkuListed', null, global);
goog.exportSymbol('proto.cherry.product.SkuQuery', null, global);
goog.exportSymbol('proto.cherry.product.Spu', null, global);
goog.exportSymbol('proto.cherry.product.SpuAttrOption', null, global);
goog.exportSymbol('proto.cherry.product.SpuAttrType', null, global);
goog.exportSymbol('proto.cherry.product.SpuAttrs', null, global);
goog.exportSymbol('proto.cherry.product.SpuAttrsReq', null, global);
goog.exportSymbol('proto.cherry.product.SpuListed', null, global);
goog.exportSymbol('proto.cherry.product.SpuQuery', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.ItemCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.ItemCategory.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.ItemCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.ItemCategory.displayName = 'proto.cherry.product.ItemCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.ItemCategoryQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.ItemCategoryQuery.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.ItemCategoryQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.ItemCategoryQuery.displayName = 'proto.cherry.product.ItemCategoryQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.ItemCategoryListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.ItemCategoryListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.ItemCategoryListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.ItemCategoryListed.displayName = 'proto.cherry.product.ItemCategoryListed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.ItemAttr = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.ItemAttr.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.ItemAttr, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.ItemAttr.displayName = 'proto.cherry.product.ItemAttr';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.ItemAttrQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.ItemAttrQuery.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.ItemAttrQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.ItemAttrQuery.displayName = 'proto.cherry.product.ItemAttrQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.ItemAttrListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.ItemAttrListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.ItemAttrListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.ItemAttrListed.displayName = 'proto.cherry.product.ItemAttrListed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.ProductCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cherry.product.ProductCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.ProductCategory.displayName = 'proto.cherry.product.ProductCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.ProductCategoryQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.ProductCategoryQuery.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.ProductCategoryQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.ProductCategoryQuery.displayName = 'proto.cherry.product.ProductCategoryQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.ProductCategoryListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.ProductCategoryListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.ProductCategoryListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.ProductCategoryListed.displayName = 'proto.cherry.product.ProductCategoryListed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.SkuAttr = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cherry.product.SkuAttr, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.SkuAttr.displayName = 'proto.cherry.product.SkuAttr';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.SpuAttrOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cherry.product.SpuAttrOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.SpuAttrOption.displayName = 'proto.cherry.product.SpuAttrOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.SpuAttrType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.SpuAttrType.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.SpuAttrType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.SpuAttrType.displayName = 'proto.cherry.product.SpuAttrType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.Spu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.Spu.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.Spu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.Spu.displayName = 'proto.cherry.product.Spu';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.SpuAttrsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.SpuAttrsReq.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.SpuAttrsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.SpuAttrsReq.displayName = 'proto.cherry.product.SpuAttrsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.SpuAttrs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.SpuAttrs.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.SpuAttrs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.SpuAttrs.displayName = 'proto.cherry.product.SpuAttrs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.SpuQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.SpuQuery.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.SpuQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.SpuQuery.displayName = 'proto.cherry.product.SpuQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.SpuListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.SpuListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.SpuListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.SpuListed.displayName = 'proto.cherry.product.SpuListed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.Sku = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.Sku.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.Sku, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.Sku.displayName = 'proto.cherry.product.Sku';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.SkuCreateReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.SkuCreateReq.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.SkuCreateReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.SkuCreateReq.displayName = 'proto.cherry.product.SkuCreateReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.SkuQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.SkuQuery.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.SkuQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.SkuQuery.displayName = 'proto.cherry.product.SkuQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.SkuListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.SkuListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.SkuListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.SkuListed.displayName = 'proto.cherry.product.SkuListed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.Brand = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cherry.product.Brand, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.Brand.displayName = 'proto.cherry.product.Brand';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.BrandQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cherry.product.BrandQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.BrandQuery.displayName = 'proto.cherry.product.BrandQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cherry.product.BrandListed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cherry.product.BrandListed.repeatedFields_, null);
};
goog.inherits(proto.cherry.product.BrandListed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cherry.product.BrandListed.displayName = 'proto.cherry.product.BrandListed';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.ItemCategory.repeatedFields_ = [5,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.ItemCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.ItemCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.ItemCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    parentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imgUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    deleted: jspb.Message.getFieldWithDefault(msg, 10, 0),
    imgUrlsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.ItemCategory}
 */
proto.cherry.product.ItemCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.ItemCategory;
  return proto.cherry.product.ItemCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.ItemCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.ItemCategory}
 */
proto.cherry.product.ItemCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeleted(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addImgUrls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.ItemCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.ItemCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.ItemCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImgUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getDeleted();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getImgUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.product.ItemCategory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 parent_id = 2;
 * @return {number}
 */
proto.cherry.product.ItemCategory.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cherry.product.ItemCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string img_url = 4;
 * @return {string}
 */
proto.cherry.product.ItemCategory.prototype.getImgUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.setImgUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string tags = 5;
 * @return {!Array<string>}
 */
proto.cherry.product.ItemCategory.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional int32 status = 7;
 * @return {number}
 */
proto.cherry.product.ItemCategory.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 create_at = 8;
 * @return {number}
 */
proto.cherry.product.ItemCategory.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.cherry.product.ItemCategory.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 deleted = 10;
 * @return {number}
 */
proto.cherry.product.ItemCategory.prototype.getDeleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated string img_urls = 11;
 * @return {!Array<string>}
 */
proto.cherry.product.ItemCategory.prototype.getImgUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.setImgUrlsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.addImgUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ItemCategory} returns this
 */
proto.cherry.product.ItemCategory.prototype.clearImgUrlsList = function() {
  return this.setImgUrlsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.ItemCategoryQuery.repeatedFields_ = [4,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.ItemCategoryQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.ItemCategoryQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.ItemCategoryQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemCategoryQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    parentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    createbegin: jspb.Message.getFieldWithDefault(msg, 6, 0),
    createend: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 8, 0),
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 9, 0),
    sortBysList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.ItemCategoryQuery}
 */
proto.cherry.product.ItemCategoryQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.ItemCategoryQuery;
  return proto.cherry.product.ItemCategoryQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.ItemCategoryQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.ItemCategoryQuery}
 */
proto.cherry.product.ItemCategoryQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatebegin(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateend(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addSortBys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.ItemCategoryQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.ItemCategoryQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.ItemCategoryQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemCategoryQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCreatebegin();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCreateend();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getSortBysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.product.ItemCategoryQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 parent_id = 2;
 * @return {number}
 */
proto.cherry.product.ItemCategoryQuery.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cherry.product.ItemCategoryQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string tags = 4;
 * @return {!Array<string>}
 */
proto.cherry.product.ItemCategoryQuery.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional int32 status = 5;
 * @return {number}
 */
proto.cherry.product.ItemCategoryQuery.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 createBegin = 6;
 * @return {number}
 */
proto.cherry.product.ItemCategoryQuery.prototype.getCreatebegin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.setCreatebegin = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 createEnd = 7;
 * @return {number}
 */
proto.cherry.product.ItemCategoryQuery.prototype.getCreateend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.setCreateend = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 page_size = 8;
 * @return {number}
 */
proto.cherry.product.ItemCategoryQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 page_current = 9;
 * @return {number}
 */
proto.cherry.product.ItemCategoryQuery.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated string sort_bys = 10;
 * @return {!Array<string>}
 */
proto.cherry.product.ItemCategoryQuery.prototype.getSortBysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.setSortBysList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.addSortBys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ItemCategoryQuery} returns this
 */
proto.cherry.product.ItemCategoryQuery.prototype.clearSortBysList = function() {
  return this.setSortBysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.ItemCategoryListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.ItemCategoryListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.ItemCategoryListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.ItemCategoryListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemCategoryListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 2, 0),
    total: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.product.ItemCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.ItemCategoryListed}
 */
proto.cherry.product.ItemCategoryListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.ItemCategoryListed;
  return proto.cherry.product.ItemCategoryListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.ItemCategoryListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.ItemCategoryListed}
 */
proto.cherry.product.ItemCategoryListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 4:
      var value = new proto.cherry.product.ItemCategory;
      reader.readMessage(value,proto.cherry.product.ItemCategory.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.ItemCategoryListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.ItemCategoryListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.ItemCategoryListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemCategoryListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.product.ItemCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.cherry.product.ItemCategoryListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategoryListed} returns this
 */
proto.cherry.product.ItemCategoryListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_current = 2;
 * @return {number}
 */
proto.cherry.product.ItemCategoryListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategoryListed} returns this
 */
proto.cherry.product.ItemCategoryListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total = 3;
 * @return {number}
 */
proto.cherry.product.ItemCategoryListed.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemCategoryListed} returns this
 */
proto.cherry.product.ItemCategoryListed.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated ItemCategory records = 4;
 * @return {!Array<!proto.cherry.product.ItemCategory>}
 */
proto.cherry.product.ItemCategoryListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.product.ItemCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.ItemCategory, 4));
};


/**
 * @param {!Array<!proto.cherry.product.ItemCategory>} value
 * @return {!proto.cherry.product.ItemCategoryListed} returns this
*/
proto.cherry.product.ItemCategoryListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.product.ItemCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ItemCategory}
 */
proto.cherry.product.ItemCategoryListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.product.ItemCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ItemCategoryListed} returns this
 */
proto.cherry.product.ItemCategoryListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.ItemAttr.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.ItemAttr.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.ItemAttr.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.ItemAttr} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemAttr.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deleted: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    typesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.ItemAttr}
 */
proto.cherry.product.ItemAttr.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.ItemAttr;
  return proto.cherry.product.ItemAttr.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.ItemAttr} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.ItemAttr}
 */
proto.cherry.product.ItemAttr.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeleted(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 8:
      var values = /** @type {!Array<!proto.cherry.product.ItemAttr.ItemAttrType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.ItemAttr.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.ItemAttr.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.ItemAttr} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemAttr.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeleted();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.cherry.product.ItemAttr.ItemAttrType = {
  UNKNOWN: 0,
  TRADE: 1,
  SUPPLY_CHAIN: 2
};

/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cherry.product.ItemAttr.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttr} returns this
 */
proto.cherry.product.ItemAttr.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.product.ItemAttr.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.ItemAttr} returns this
 */
proto.cherry.product.ItemAttr.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 status = 3;
 * @return {number}
 */
proto.cherry.product.ItemAttr.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttr} returns this
 */
proto.cherry.product.ItemAttr.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 deleted = 4;
 * @return {number}
 */
proto.cherry.product.ItemAttr.prototype.getDeleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttr} returns this
 */
proto.cherry.product.ItemAttr.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 create_at = 6;
 * @return {number}
 */
proto.cherry.product.ItemAttr.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttr} returns this
 */
proto.cherry.product.ItemAttr.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 updated_at = 7;
 * @return {number}
 */
proto.cherry.product.ItemAttr.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttr} returns this
 */
proto.cherry.product.ItemAttr.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated ItemAttrType types = 8;
 * @return {!Array<!proto.cherry.product.ItemAttr.ItemAttrType>}
 */
proto.cherry.product.ItemAttr.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.cherry.product.ItemAttr.ItemAttrType>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.cherry.product.ItemAttr.ItemAttrType>} value
 * @return {!proto.cherry.product.ItemAttr} returns this
 */
proto.cherry.product.ItemAttr.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.cherry.product.ItemAttr.ItemAttrType} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ItemAttr} returns this
 */
proto.cherry.product.ItemAttr.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ItemAttr} returns this
 */
proto.cherry.product.ItemAttr.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.ItemAttrQuery.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.ItemAttrQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.ItemAttrQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.ItemAttrQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemAttrQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deleted: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sortBysList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    typesList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.ItemAttrQuery}
 */
proto.cherry.product.ItemAttrQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.ItemAttrQuery;
  return proto.cherry.product.ItemAttrQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.ItemAttrQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.ItemAttrQuery}
 */
proto.cherry.product.ItemAttrQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeleted(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addSortBys(value);
      break;
    case 8:
      var values = /** @type {!Array<!proto.cherry.product.ItemAttr.ItemAttrType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.ItemAttrQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.ItemAttrQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.ItemAttrQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemAttrQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDeleted();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSortBysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      8,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cherry.product.ItemAttrQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.product.ItemAttrQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 status = 3;
 * @return {number}
 */
proto.cherry.product.ItemAttrQuery.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 deleted = 4;
 * @return {number}
 */
proto.cherry.product.ItemAttrQuery.prototype.getDeleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 page_current = 5;
 * @return {number}
 */
proto.cherry.product.ItemAttrQuery.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 page_size = 6;
 * @return {number}
 */
proto.cherry.product.ItemAttrQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated string sort_bys = 7;
 * @return {!Array<string>}
 */
proto.cherry.product.ItemAttrQuery.prototype.getSortBysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.setSortBysList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.addSortBys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.clearSortBysList = function() {
  return this.setSortBysList([]);
};


/**
 * repeated ItemAttr.ItemAttrType types = 8;
 * @return {!Array<!proto.cherry.product.ItemAttr.ItemAttrType>}
 */
proto.cherry.product.ItemAttrQuery.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.cherry.product.ItemAttr.ItemAttrType>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<!proto.cherry.product.ItemAttr.ItemAttrType>} value
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!proto.cherry.product.ItemAttr.ItemAttrType} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ItemAttrQuery} returns this
 */
proto.cherry.product.ItemAttrQuery.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.ItemAttrListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.ItemAttrListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.ItemAttrListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.ItemAttrListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemAttrListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageSize: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.product.ItemAttr.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.ItemAttrListed}
 */
proto.cherry.product.ItemAttrListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.ItemAttrListed;
  return proto.cherry.product.ItemAttrListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.ItemAttrListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.ItemAttrListed}
 */
proto.cherry.product.ItemAttrListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.product.ItemAttr;
      reader.readMessage(value,proto.cherry.product.ItemAttr.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.ItemAttrListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.ItemAttrListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.ItemAttrListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ItemAttrListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.product.ItemAttr.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_size = 1;
 * @return {number}
 */
proto.cherry.product.ItemAttrListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttrListed} returns this
 */
proto.cherry.product.ItemAttrListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_current = 2;
 * @return {number}
 */
proto.cherry.product.ItemAttrListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttrListed} returns this
 */
proto.cherry.product.ItemAttrListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.product.ItemAttrListed.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ItemAttrListed} returns this
 */
proto.cherry.product.ItemAttrListed.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated ItemAttr records = 4;
 * @return {!Array<!proto.cherry.product.ItemAttr>}
 */
proto.cherry.product.ItemAttrListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.product.ItemAttr>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.ItemAttr, 4));
};


/**
 * @param {!Array<!proto.cherry.product.ItemAttr>} value
 * @return {!proto.cherry.product.ItemAttrListed} returns this
*/
proto.cherry.product.ItemAttrListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.product.ItemAttr=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ItemAttr}
 */
proto.cherry.product.ItemAttrListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.product.ItemAttr, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ItemAttrListed} returns this
 */
proto.cherry.product.ItemAttrListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.ProductCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.ProductCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.ProductCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ProductCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    parentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    enterpriseId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    merchantId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sort: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    deleted: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    spuTotalCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    spuEnableCount: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.ProductCategory}
 */
proto.cherry.product.ProductCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.ProductCategory;
  return proto.cherry.product.ProductCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.ProductCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.ProductCategory}
 */
proto.cherry.product.ProductCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnterpriseId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMerchantId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeleted(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpuTotalCount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSpuEnableCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.ProductCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.ProductCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.ProductCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ProductCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnterpriseId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMerchantId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDeleted();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getSpuTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getSpuEnableCount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 parent_id = 2;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cherry.product.ProductCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 enterprise_id = 4;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getEnterpriseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setEnterpriseId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 merchant_id = 5;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getMerchantId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 sort = 6;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 status = 7;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 deleted = 8;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getDeleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 create_at = 9;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 updated_at = 10;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 spu_total_count = 11;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getSpuTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setSpuTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 spu_enable_count = 12;
 * @return {number}
 */
proto.cherry.product.ProductCategory.prototype.getSpuEnableCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategory} returns this
 */
proto.cherry.product.ProductCategory.prototype.setSpuEnableCount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.ProductCategoryQuery.repeatedFields_ = [8,9,10,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.ProductCategoryQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.ProductCategoryQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.ProductCategoryQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ProductCategoryQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    parentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    enterpriseId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    merchantId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    deleted: jspb.Message.getFieldWithDefault(msg, 7, 0),
    idsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    enterpriseIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    merchantIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 11, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 12, 0),
    sortBysList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.ProductCategoryQuery}
 */
proto.cherry.product.ProductCategoryQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.ProductCategoryQuery;
  return proto.cherry.product.ProductCategoryQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.ProductCategoryQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.ProductCategoryQuery}
 */
proto.cherry.product.ProductCategoryQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnterpriseId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMerchantId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeleted(value);
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEnterpriseIds(values[i]);
      }
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addMerchantIds(values[i]);
      }
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addSortBys(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.ProductCategoryQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.ProductCategoryQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.ProductCategoryQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ProductCategoryQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnterpriseId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMerchantId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getDeleted();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      8,
      f
    );
  }
  f = message.getEnterpriseIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
  f = message.getMerchantIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getSortBysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 parent_id = 2;
 * @return {number}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 enterprise_id = 4;
 * @return {number}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getEnterpriseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setEnterpriseId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 merchant_id = 5;
 * @return {number}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getMerchantId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 status = 6;
 * @return {number}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 deleted = 7;
 * @return {number}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getDeleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated int64 ids = 8;
 * @return {!Array<number>}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * repeated int64 enterprise_ids = 9;
 * @return {!Array<number>}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getEnterpriseIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setEnterpriseIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.addEnterpriseIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.clearEnterpriseIdsList = function() {
  return this.setEnterpriseIdsList([]);
};


/**
 * repeated int64 merchant_ids = 10;
 * @return {!Array<number>}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getMerchantIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setMerchantIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.addMerchantIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.clearMerchantIdsList = function() {
  return this.setMerchantIdsList([]);
};


/**
 * optional int32 page_current = 11;
 * @return {number}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 page_size = 12;
 * @return {number}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated string sort_bys = 13;
 * @return {!Array<string>}
 */
proto.cherry.product.ProductCategoryQuery.prototype.getSortBysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.setSortBysList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.addSortBys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ProductCategoryQuery} returns this
 */
proto.cherry.product.ProductCategoryQuery.prototype.clearSortBysList = function() {
  return this.setSortBysList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.ProductCategoryListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.ProductCategoryListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.ProductCategoryListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.ProductCategoryListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ProductCategoryListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.product.ProductCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.ProductCategoryListed}
 */
proto.cherry.product.ProductCategoryListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.ProductCategoryListed;
  return proto.cherry.product.ProductCategoryListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.ProductCategoryListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.ProductCategoryListed}
 */
proto.cherry.product.ProductCategoryListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.product.ProductCategory;
      reader.readMessage(value,proto.cherry.product.ProductCategory.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.ProductCategoryListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.ProductCategoryListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.ProductCategoryListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.ProductCategoryListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.product.ProductCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_current = 1;
 * @return {number}
 */
proto.cherry.product.ProductCategoryListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryListed} returns this
 */
proto.cherry.product.ProductCategoryListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.cherry.product.ProductCategoryListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryListed} returns this
 */
proto.cherry.product.ProductCategoryListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.product.ProductCategoryListed.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.ProductCategoryListed} returns this
 */
proto.cherry.product.ProductCategoryListed.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated ProductCategory records = 4;
 * @return {!Array<!proto.cherry.product.ProductCategory>}
 */
proto.cherry.product.ProductCategoryListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.product.ProductCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.ProductCategory, 4));
};


/**
 * @param {!Array<!proto.cherry.product.ProductCategory>} value
 * @return {!proto.cherry.product.ProductCategoryListed} returns this
*/
proto.cherry.product.ProductCategoryListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.product.ProductCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.ProductCategory}
 */
proto.cherry.product.ProductCategoryListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.product.ProductCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.ProductCategoryListed} returns this
 */
proto.cherry.product.ProductCategoryListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.SkuAttr.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.SkuAttr.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.SkuAttr} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SkuAttr.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    itemAttrId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 5, 0),
    code: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.SkuAttr}
 */
proto.cherry.product.SkuAttr.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.SkuAttr;
  return proto.cherry.product.SkuAttr.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.SkuAttr} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.SkuAttr}
 */
proto.cherry.product.SkuAttr.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setItemAttrId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.SkuAttr.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.SkuAttr.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.SkuAttr} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SkuAttr.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getItemAttrId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cherry.product.SkuAttr.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuAttr} returns this
 */
proto.cherry.product.SkuAttr.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 item_attr_id = 3;
 * @return {number}
 */
proto.cherry.product.SkuAttr.prototype.getItemAttrId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuAttr} returns this
 */
proto.cherry.product.SkuAttr.prototype.setItemAttrId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.cherry.product.SkuAttr.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.SkuAttr} returns this
 */
proto.cherry.product.SkuAttr.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 sort = 5;
 * @return {number}
 */
proto.cherry.product.SkuAttr.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuAttr} returns this
 */
proto.cherry.product.SkuAttr.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string code = 6;
 * @return {string}
 */
proto.cherry.product.SkuAttr.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.SkuAttr} returns this
 */
proto.cherry.product.SkuAttr.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.SpuAttrOption.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.SpuAttrOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.SpuAttrOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuAttrOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.SpuAttrOption}
 */
proto.cherry.product.SpuAttrOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.SpuAttrOption;
  return proto.cherry.product.SpuAttrOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.SpuAttrOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.SpuAttrOption}
 */
proto.cherry.product.SpuAttrOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.SpuAttrOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.SpuAttrOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.SpuAttrOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuAttrOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cherry.product.SpuAttrOption.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuAttrOption} returns this
 */
proto.cherry.product.SpuAttrOption.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.product.SpuAttrOption.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.SpuAttrOption} returns this
 */
proto.cherry.product.SpuAttrOption.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sort = 3;
 * @return {number}
 */
proto.cherry.product.SpuAttrOption.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuAttrOption} returns this
 */
proto.cherry.product.SpuAttrOption.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.SpuAttrType.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.SpuAttrType.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.SpuAttrType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.SpuAttrType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuAttrType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sort: jspb.Message.getFieldWithDefault(msg, 3, 0),
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.cherry.product.SpuAttrOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.SpuAttrType}
 */
proto.cherry.product.SpuAttrType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.SpuAttrType;
  return proto.cherry.product.SpuAttrType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.SpuAttrType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.SpuAttrType}
 */
proto.cherry.product.SpuAttrType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSort(value);
      break;
    case 4:
      var value = new proto.cherry.product.SpuAttrOption;
      reader.readMessage(value,proto.cherry.product.SpuAttrOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.SpuAttrType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.SpuAttrType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.SpuAttrType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuAttrType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSort();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.product.SpuAttrOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.cherry.product.SpuAttrType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuAttrType} returns this
 */
proto.cherry.product.SpuAttrType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.product.SpuAttrType.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.SpuAttrType} returns this
 */
proto.cherry.product.SpuAttrType.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sort = 3;
 * @return {number}
 */
proto.cherry.product.SpuAttrType.prototype.getSort = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuAttrType} returns this
 */
proto.cherry.product.SpuAttrType.prototype.setSort = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated SpuAttrOption options = 4;
 * @return {!Array<!proto.cherry.product.SpuAttrOption>}
 */
proto.cherry.product.SpuAttrType.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.cherry.product.SpuAttrOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.SpuAttrOption, 4));
};


/**
 * @param {!Array<!proto.cherry.product.SpuAttrOption>} value
 * @return {!proto.cherry.product.SpuAttrType} returns this
*/
proto.cherry.product.SpuAttrType.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.product.SpuAttrOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SpuAttrOption}
 */
proto.cherry.product.SpuAttrType.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.product.SpuAttrOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SpuAttrType} returns this
 */
proto.cherry.product.SpuAttrType.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.Spu.repeatedFields_ = [3,6,14,20,21,23,24,25,26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.Spu.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.Spu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.Spu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.Spu.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    skuCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    itemCategoryIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imgUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    pricingUnit: jspb.Message.getFieldWithDefault(msg, 7, 0),
    sellUnit: jspb.Message.getFieldWithDefault(msg, 8, 0),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    createAt: jspb.Message.getFieldWithDefault(msg, 10, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 11, 0),
    priceRange: (f = msg.getPriceRange()) && cherry_cherry_pb.PriceRange.toObject(includeInstance, f),
    detailContent: jspb.Message.getFieldWithDefault(msg, 13, ""),
    imgUrlsList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    merchantId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    enterpriseId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 17, 0),
    stallId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    skuLaunchCount: jspb.Message.getFieldWithDefault(msg, 19, 0),
    categoryIdsList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    placeAreaIdsList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    brandId: jspb.Message.getFieldWithDefault(msg, 22, 0),
    attrsList: jspb.Message.toObjectList(msg.getAttrsList(),
    proto.cherry.product.SpuAttrType.toObject, includeInstance),
    itemCategoryNamesList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f,
    categoryNamesList: (f = jspb.Message.getRepeatedField(msg, 25)) == null ? undefined : f,
    placeAreaNamesList: (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
    stallName: jspb.Message.getFieldWithDefault(msg, 27, ""),
    merchantName: jspb.Message.getFieldWithDefault(msg, 28, ""),
    brandName: jspb.Message.getFieldWithDefault(msg, 29, ""),
    skuEnableCount: jspb.Message.getFieldWithDefault(msg, 30, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.Spu}
 */
proto.cherry.product.Spu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.Spu;
  return proto.cherry.product.Spu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.Spu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.Spu}
 */
proto.cherry.product.Spu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSkuCount(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addItemCategoryIds(values[i]);
      }
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgUrl(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 7:
      var value = /** @type {!proto.cherry.PricingUnit} */ (reader.readEnum());
      msg.setPricingUnit(value);
      break;
    case 8:
      var value = /** @type {!proto.cherry.SellUnit} */ (reader.readEnum());
      msg.setSellUnit(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 12:
      var value = new cherry_cherry_pb.PriceRange;
      reader.readMessage(value,cherry_cherry_pb.PriceRange.deserializeBinaryFromReader);
      msg.setPriceRange(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailContent(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addImgUrls(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMerchantId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnterpriseId(value);
      break;
    case 17:
      var value = /** @type {!proto.cherry.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStallId(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSkuLaunchCount(value);
      break;
    case 20:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategoryIds(values[i]);
      }
      break;
    case 21:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlaceAreaIds(values[i]);
      }
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBrandId(value);
      break;
    case 23:
      var value = new proto.cherry.product.SpuAttrType;
      reader.readMessage(value,proto.cherry.product.SpuAttrType.deserializeBinaryFromReader);
      msg.addAttrs(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.addItemCategoryNames(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.addCategoryNames(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.addPlaceAreaNames(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setStallName(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerchantName(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setBrandName(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSkuEnableCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.Spu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.Spu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.Spu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.Spu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSkuCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getItemCategoryIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImgUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getPricingUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getSellUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getPriceRange();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      cherry_cherry_pb.PriceRange.serializeBinaryToWriter
    );
  }
  f = message.getDetailContent();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getImgUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getMerchantId();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getEnterpriseId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getStallId();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getSkuLaunchCount();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getCategoryIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      20,
      f
    );
  }
  f = message.getPlaceAreaIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      21,
      f
    );
  }
  f = message.getBrandId();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getAttrsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.cherry.product.SpuAttrType.serializeBinaryToWriter
    );
  }
  f = message.getItemCategoryNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      24,
      f
    );
  }
  f = message.getCategoryNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      25,
      f
    );
  }
  f = message.getPlaceAreaNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      26,
      f
    );
  }
  f = message.getStallName();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getMerchantName();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getBrandName();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getSkuEnableCount();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sku_count = 2;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getSkuCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setSkuCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 item_category_ids = 3;
 * @return {!Array<number>}
 */
proto.cherry.product.Spu.prototype.getItemCategoryIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setItemCategoryIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.addItemCategoryIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.clearItemCategoryIdsList = function() {
  return this.setItemCategoryIdsList([]);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.cherry.product.Spu.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string img_url = 5;
 * @return {string}
 */
proto.cherry.product.Spu.prototype.getImgUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setImgUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string tags = 6;
 * @return {!Array<string>}
 */
proto.cherry.product.Spu.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional cherry.PricingUnit pricing_unit = 7;
 * @return {!proto.cherry.PricingUnit}
 */
proto.cherry.product.Spu.prototype.getPricingUnit = function() {
  return /** @type {!proto.cherry.PricingUnit} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.cherry.PricingUnit} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setPricingUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional cherry.SellUnit sell_unit = 8;
 * @return {!proto.cherry.SellUnit}
 */
proto.cherry.product.Spu.prototype.getSellUnit = function() {
  return /** @type {!proto.cherry.SellUnit} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.cherry.SellUnit} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setSellUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int32 status = 9;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 create_at = 10;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 updated_at = 11;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional cherry.PriceRange price_range = 12;
 * @return {?proto.cherry.PriceRange}
 */
proto.cherry.product.Spu.prototype.getPriceRange = function() {
  return /** @type{?proto.cherry.PriceRange} */ (
    jspb.Message.getWrapperField(this, cherry_cherry_pb.PriceRange, 12));
};


/**
 * @param {?proto.cherry.PriceRange|undefined} value
 * @return {!proto.cherry.product.Spu} returns this
*/
proto.cherry.product.Spu.prototype.setPriceRange = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.clearPriceRange = function() {
  return this.setPriceRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.cherry.product.Spu.prototype.hasPriceRange = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string detail_content = 13;
 * @return {string}
 */
proto.cherry.product.Spu.prototype.getDetailContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setDetailContent = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated string img_urls = 14;
 * @return {!Array<string>}
 */
proto.cherry.product.Spu.prototype.getImgUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setImgUrlsList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.addImgUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.clearImgUrlsList = function() {
  return this.setImgUrlsList([]);
};


/**
 * optional int64 merchant_id = 15;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getMerchantId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 enterprise_id = 16;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getEnterpriseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setEnterpriseId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional cherry.Currency currency = 17;
 * @return {!proto.cherry.Currency}
 */
proto.cherry.product.Spu.prototype.getCurrency = function() {
  return /** @type {!proto.cherry.Currency} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.cherry.Currency} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional int64 stall_id = 18;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getStallId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setStallId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 sku_launch_count = 19;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getSkuLaunchCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setSkuLaunchCount = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * repeated int64 category_ids = 20;
 * @return {!Array<number>}
 */
proto.cherry.product.Spu.prototype.getCategoryIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setCategoryIdsList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.addCategoryIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.clearCategoryIdsList = function() {
  return this.setCategoryIdsList([]);
};


/**
 * repeated int64 place_area_ids = 21;
 * @return {!Array<number>}
 */
proto.cherry.product.Spu.prototype.getPlaceAreaIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setPlaceAreaIdsList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.addPlaceAreaIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.clearPlaceAreaIdsList = function() {
  return this.setPlaceAreaIdsList([]);
};


/**
 * optional int32 brand_id = 22;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getBrandId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setBrandId = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * repeated SpuAttrType attrs = 23;
 * @return {!Array<!proto.cherry.product.SpuAttrType>}
 */
proto.cherry.product.Spu.prototype.getAttrsList = function() {
  return /** @type{!Array<!proto.cherry.product.SpuAttrType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.SpuAttrType, 23));
};


/**
 * @param {!Array<!proto.cherry.product.SpuAttrType>} value
 * @return {!proto.cherry.product.Spu} returns this
*/
proto.cherry.product.Spu.prototype.setAttrsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.cherry.product.SpuAttrType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SpuAttrType}
 */
proto.cherry.product.Spu.prototype.addAttrs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.cherry.product.SpuAttrType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.clearAttrsList = function() {
  return this.setAttrsList([]);
};


/**
 * repeated string item_category_names = 24;
 * @return {!Array<string>}
 */
proto.cherry.product.Spu.prototype.getItemCategoryNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setItemCategoryNamesList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.addItemCategoryNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.clearItemCategoryNamesList = function() {
  return this.setItemCategoryNamesList([]);
};


/**
 * repeated string category_names = 25;
 * @return {!Array<string>}
 */
proto.cherry.product.Spu.prototype.getCategoryNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 25));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setCategoryNamesList = function(value) {
  return jspb.Message.setField(this, 25, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.addCategoryNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 25, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.clearCategoryNamesList = function() {
  return this.setCategoryNamesList([]);
};


/**
 * repeated string place_area_names = 26;
 * @return {!Array<string>}
 */
proto.cherry.product.Spu.prototype.getPlaceAreaNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 26));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setPlaceAreaNamesList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.addPlaceAreaNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.clearPlaceAreaNamesList = function() {
  return this.setPlaceAreaNamesList([]);
};


/**
 * optional string stall_name = 27;
 * @return {string}
 */
proto.cherry.product.Spu.prototype.getStallName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setStallName = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string merchant_name = 28;
 * @return {string}
 */
proto.cherry.product.Spu.prototype.getMerchantName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setMerchantName = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string brand_name = 29;
 * @return {string}
 */
proto.cherry.product.Spu.prototype.getBrandName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setBrandName = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional int32 sku_enable_count = 30;
 * @return {number}
 */
proto.cherry.product.Spu.prototype.getSkuEnableCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Spu} returns this
 */
proto.cherry.product.Spu.prototype.setSkuEnableCount = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.SpuAttrsReq.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.SpuAttrsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.SpuAttrsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.SpuAttrsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuAttrsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    spuId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attrsList: jspb.Message.toObjectList(msg.getAttrsList(),
    proto.cherry.product.SpuAttrType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.SpuAttrsReq}
 */
proto.cherry.product.SpuAttrsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.SpuAttrsReq;
  return proto.cherry.product.SpuAttrsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.SpuAttrsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.SpuAttrsReq}
 */
proto.cherry.product.SpuAttrsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSpuId(value);
      break;
    case 2:
      var value = new proto.cherry.product.SpuAttrType;
      reader.readMessage(value,proto.cherry.product.SpuAttrType.deserializeBinaryFromReader);
      msg.addAttrs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.SpuAttrsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.SpuAttrsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.SpuAttrsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuAttrsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpuId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAttrsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cherry.product.SpuAttrType.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 spu_id = 1;
 * @return {number}
 */
proto.cherry.product.SpuAttrsReq.prototype.getSpuId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuAttrsReq} returns this
 */
proto.cherry.product.SpuAttrsReq.prototype.setSpuId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SpuAttrType attrs = 2;
 * @return {!Array<!proto.cherry.product.SpuAttrType>}
 */
proto.cherry.product.SpuAttrsReq.prototype.getAttrsList = function() {
  return /** @type{!Array<!proto.cherry.product.SpuAttrType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.SpuAttrType, 2));
};


/**
 * @param {!Array<!proto.cherry.product.SpuAttrType>} value
 * @return {!proto.cherry.product.SpuAttrsReq} returns this
*/
proto.cherry.product.SpuAttrsReq.prototype.setAttrsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cherry.product.SpuAttrType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SpuAttrType}
 */
proto.cherry.product.SpuAttrsReq.prototype.addAttrs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cherry.product.SpuAttrType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SpuAttrsReq} returns this
 */
proto.cherry.product.SpuAttrsReq.prototype.clearAttrsList = function() {
  return this.setAttrsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.SpuAttrs.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.SpuAttrs.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.SpuAttrs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.SpuAttrs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuAttrs.toObject = function(includeInstance, msg) {
  var f, obj = {
    spuId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attrsList: jspb.Message.toObjectList(msg.getAttrsList(),
    proto.cherry.product.SpuAttrType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.SpuAttrs}
 */
proto.cherry.product.SpuAttrs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.SpuAttrs;
  return proto.cherry.product.SpuAttrs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.SpuAttrs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.SpuAttrs}
 */
proto.cherry.product.SpuAttrs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSpuId(value);
      break;
    case 2:
      var value = new proto.cherry.product.SpuAttrType;
      reader.readMessage(value,proto.cherry.product.SpuAttrType.deserializeBinaryFromReader);
      msg.addAttrs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.SpuAttrs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.SpuAttrs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.SpuAttrs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuAttrs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpuId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAttrsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.cherry.product.SpuAttrType.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 spu_id = 1;
 * @return {number}
 */
proto.cherry.product.SpuAttrs.prototype.getSpuId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuAttrs} returns this
 */
proto.cherry.product.SpuAttrs.prototype.setSpuId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated SpuAttrType attrs = 2;
 * @return {!Array<!proto.cherry.product.SpuAttrType>}
 */
proto.cherry.product.SpuAttrs.prototype.getAttrsList = function() {
  return /** @type{!Array<!proto.cherry.product.SpuAttrType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.SpuAttrType, 2));
};


/**
 * @param {!Array<!proto.cherry.product.SpuAttrType>} value
 * @return {!proto.cherry.product.SpuAttrs} returns this
*/
proto.cherry.product.SpuAttrs.prototype.setAttrsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.cherry.product.SpuAttrType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SpuAttrType}
 */
proto.cherry.product.SpuAttrs.prototype.addAttrs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.cherry.product.SpuAttrType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SpuAttrs} returns this
 */
proto.cherry.product.SpuAttrs.prototype.clearAttrsList = function() {
  return this.setAttrsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.SpuQuery.repeatedFields_ = [5,10,14,15,17,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.SpuQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.SpuQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.SpuQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    parentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imgUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    pricingUnit: jspb.Message.getFieldWithDefault(msg, 6, 0),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createBegin: jspb.Message.getFieldWithDefault(msg, 8, 0),
    createEnd: jspb.Message.getFieldWithDefault(msg, 9, 0),
    itemCategoryIdsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    fetchSkuCount: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 12, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 13, 0),
    sortBysList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    idsList: (f = jspb.Message.getRepeatedField(msg, 15)) == null ? undefined : f,
    merchantId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    categoryIdsList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    placeAreaIdsList: (f = jspb.Message.getRepeatedField(msg, 18)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.SpuQuery}
 */
proto.cherry.product.SpuQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.SpuQuery;
  return proto.cherry.product.SpuQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.SpuQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.SpuQuery}
 */
proto.cherry.product.SpuQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setParentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 6:
      var value = /** @type {!proto.cherry.PricingUnit} */ (reader.readEnum());
      msg.setPricingUnit(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateBegin(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateEnd(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addItemCategoryIds(values[i]);
      }
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFetchSkuCount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addSortBys(value);
      break;
    case 15:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMerchantId(value);
      break;
    case 17:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCategoryIds(values[i]);
      }
      break;
    case 18:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPlaceAreaIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.SpuQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.SpuQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.SpuQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getParentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImgUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getPricingUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCreateBegin();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCreateEnd();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getItemCategoryIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getFetchSkuCount();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getSortBysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      15,
      f
    );
  }
  f = message.getMerchantId();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getCategoryIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      17,
      f
    );
  }
  f = message.getPlaceAreaIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      18,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.product.SpuQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 parent_id = 2;
 * @return {number}
 */
proto.cherry.product.SpuQuery.prototype.getParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cherry.product.SpuQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string img_url = 4;
 * @return {string}
 */
proto.cherry.product.SpuQuery.prototype.getImgUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setImgUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string tags = 5;
 * @return {!Array<string>}
 */
proto.cherry.product.SpuQuery.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional cherry.PricingUnit pricing_unit = 6;
 * @return {!proto.cherry.PricingUnit}
 */
proto.cherry.product.SpuQuery.prototype.getPricingUnit = function() {
  return /** @type {!proto.cherry.PricingUnit} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.cherry.PricingUnit} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setPricingUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional int32 status = 7;
 * @return {number}
 */
proto.cherry.product.SpuQuery.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 create_begin = 8;
 * @return {number}
 */
proto.cherry.product.SpuQuery.prototype.getCreateBegin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setCreateBegin = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 create_end = 9;
 * @return {number}
 */
proto.cherry.product.SpuQuery.prototype.getCreateEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setCreateEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated int64 item_category_ids = 10;
 * @return {!Array<number>}
 */
proto.cherry.product.SpuQuery.prototype.getItemCategoryIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setItemCategoryIdsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.addItemCategoryIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.clearItemCategoryIdsList = function() {
  return this.setItemCategoryIdsList([]);
};


/**
 * optional bool fetch_sku_count = 11;
 * @return {boolean}
 */
proto.cherry.product.SpuQuery.prototype.getFetchSkuCount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setFetchSkuCount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 page_current = 12;
 * @return {number}
 */
proto.cherry.product.SpuQuery.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 page_size = 13;
 * @return {number}
 */
proto.cherry.product.SpuQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * repeated string sort_bys = 14;
 * @return {!Array<string>}
 */
proto.cherry.product.SpuQuery.prototype.getSortBysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setSortBysList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.addSortBys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.clearSortBysList = function() {
  return this.setSortBysList([]);
};


/**
 * repeated int64 ids = 15;
 * @return {!Array<number>}
 */
proto.cherry.product.SpuQuery.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 15));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 15, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 15, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int64 merchant_id = 16;
 * @return {number}
 */
proto.cherry.product.SpuQuery.prototype.getMerchantId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * repeated int64 category_ids = 17;
 * @return {!Array<number>}
 */
proto.cherry.product.SpuQuery.prototype.getCategoryIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setCategoryIdsList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.addCategoryIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.clearCategoryIdsList = function() {
  return this.setCategoryIdsList([]);
};


/**
 * repeated int64 place_area_ids = 18;
 * @return {!Array<number>}
 */
proto.cherry.product.SpuQuery.prototype.getPlaceAreaIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 18));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.setPlaceAreaIdsList = function(value) {
  return jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.addPlaceAreaIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SpuQuery} returns this
 */
proto.cherry.product.SpuQuery.prototype.clearPlaceAreaIdsList = function() {
  return this.setPlaceAreaIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.SpuListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.SpuListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.SpuListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.SpuListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.product.Spu.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.SpuListed}
 */
proto.cherry.product.SpuListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.SpuListed;
  return proto.cherry.product.SpuListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.SpuListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.SpuListed}
 */
proto.cherry.product.SpuListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.product.Spu;
      reader.readMessage(value,proto.cherry.product.Spu.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.SpuListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.SpuListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.SpuListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SpuListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.product.Spu.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_current = 1;
 * @return {number}
 */
proto.cherry.product.SpuListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuListed} returns this
 */
proto.cherry.product.SpuListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.cherry.product.SpuListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuListed} returns this
 */
proto.cherry.product.SpuListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.product.SpuListed.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SpuListed} returns this
 */
proto.cherry.product.SpuListed.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Spu records = 4;
 * @return {!Array<!proto.cherry.product.Spu>}
 */
proto.cherry.product.SpuListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.product.Spu>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.Spu, 4));
};


/**
 * @param {!Array<!proto.cherry.product.Spu>} value
 * @return {!proto.cherry.product.SpuListed} returns this
*/
proto.cherry.product.SpuListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.product.Spu=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Spu}
 */
proto.cherry.product.SpuListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.product.Spu, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SpuListed} returns this
 */
proto.cherry.product.SpuListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.Sku.repeatedFields_ = [5,13,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.Sku.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.Sku.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.Sku} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.Sku.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    spuId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    imgUrl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    detailContent: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    createAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    quantity: jspb.Message.getFieldWithDefault(msg, 11, 0),
    enterpriseId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    imgUrlsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    attrsList: jspb.Message.toObjectList(msg.getAttrsList(),
    proto.cherry.product.SkuAttr.toObject, includeInstance),
    netWeight: jspb.Message.getFieldWithDefault(msg, 15, 0),
    grossWeight: jspb.Message.getFieldWithDefault(msg, 16, 0),
    code: jspb.Message.getFieldWithDefault(msg, 17, ""),
    deleted: jspb.Message.getFieldWithDefault(msg, 18, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 19, 0),
    pricingUnit: jspb.Message.getFieldWithDefault(msg, 20, 0),
    sellUnit: jspb.Message.getFieldWithDefault(msg, 21, 0),
    merchantId: jspb.Message.getFieldWithDefault(msg, 22, 0),
    stallId: jspb.Message.getFieldWithDefault(msg, 23, 0),
    launch: jspb.Message.getFieldWithDefault(msg, 24, 0),
    defaultName: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.Sku}
 */
proto.cherry.product.Sku.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.Sku;
  return proto.cherry.product.Sku.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.Sku} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.Sku}
 */
proto.cherry.product.Sku.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSpuId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailContent(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreateAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnterpriseId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addImgUrls(value);
      break;
    case 14:
      var value = new proto.cherry.product.SkuAttr;
      reader.readMessage(value,proto.cherry.product.SkuAttr.deserializeBinaryFromReader);
      msg.addAttrs(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNetWeight(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGrossWeight(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeleted(value);
      break;
    case 19:
      var value = /** @type {!proto.cherry.Currency} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    case 20:
      var value = /** @type {!proto.cherry.PricingUnit} */ (reader.readEnum());
      msg.setPricingUnit(value);
      break;
    case 21:
      var value = /** @type {!proto.cherry.SellUnit} */ (reader.readEnum());
      msg.setSellUnit(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMerchantId(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStallId(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLaunch(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.Sku.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.Sku.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.Sku} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.Sku.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSpuId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImgUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getDetailContent();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCreateAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getEnterpriseId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getImgUrlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getAttrsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.cherry.product.SkuAttr.serializeBinaryToWriter
    );
  }
  f = message.getNetWeight();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getGrossWeight();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDeleted();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getPricingUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getSellUnit();
  if (f !== 0.0) {
    writer.writeEnum(
      21,
      f
    );
  }
  f = message.getMerchantId();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getStallId();
  if (f !== 0) {
    writer.writeInt64(
      23,
      f
    );
  }
  f = message.getLaunch();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getDefaultName();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 spu_id = 2;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getSpuId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setSpuId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.cherry.product.Sku.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string img_url = 4;
 * @return {string}
 */
proto.cherry.product.Sku.prototype.getImgUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setImgUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string tags = 5;
 * @return {!Array<string>}
 */
proto.cherry.product.Sku.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string detail_content = 6;
 * @return {string}
 */
proto.cherry.product.Sku.prototype.getDetailContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setDetailContent = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 status = 7;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 create_at = 8;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getCreateAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setCreateAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional double price = 10;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional int64 quantity = 11;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 enterprise_id = 12;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getEnterpriseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setEnterpriseId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated string img_urls = 13;
 * @return {!Array<string>}
 */
proto.cherry.product.Sku.prototype.getImgUrlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setImgUrlsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.addImgUrls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.clearImgUrlsList = function() {
  return this.setImgUrlsList([]);
};


/**
 * repeated SkuAttr attrs = 14;
 * @return {!Array<!proto.cherry.product.SkuAttr>}
 */
proto.cherry.product.Sku.prototype.getAttrsList = function() {
  return /** @type{!Array<!proto.cherry.product.SkuAttr>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.SkuAttr, 14));
};


/**
 * @param {!Array<!proto.cherry.product.SkuAttr>} value
 * @return {!proto.cherry.product.Sku} returns this
*/
proto.cherry.product.Sku.prototype.setAttrsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.cherry.product.SkuAttr=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SkuAttr}
 */
proto.cherry.product.Sku.prototype.addAttrs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.cherry.product.SkuAttr, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.clearAttrsList = function() {
  return this.setAttrsList([]);
};


/**
 * optional int64 net_weight = 15;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getNetWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setNetWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 gross_weight = 16;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getGrossWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setGrossWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string code = 17;
 * @return {string}
 */
proto.cherry.product.Sku.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int32 deleted = 18;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getDeleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional cherry.Currency currency = 19;
 * @return {!proto.cherry.Currency}
 */
proto.cherry.product.Sku.prototype.getCurrency = function() {
  return /** @type {!proto.cherry.Currency} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.cherry.Currency} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional cherry.PricingUnit pricing_unit = 20;
 * @return {!proto.cherry.PricingUnit}
 */
proto.cherry.product.Sku.prototype.getPricingUnit = function() {
  return /** @type {!proto.cherry.PricingUnit} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.cherry.PricingUnit} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setPricingUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional cherry.SellUnit sell_unit = 21;
 * @return {!proto.cherry.SellUnit}
 */
proto.cherry.product.Sku.prototype.getSellUnit = function() {
  return /** @type {!proto.cherry.SellUnit} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {!proto.cherry.SellUnit} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setSellUnit = function(value) {
  return jspb.Message.setProto3EnumField(this, 21, value);
};


/**
 * optional int64 merchant_id = 22;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getMerchantId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int64 stall_id = 23;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getStallId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setStallId = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 launch = 24;
 * @return {number}
 */
proto.cherry.product.Sku.prototype.getLaunch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setLaunch = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string default_name = 25;
 * @return {string}
 */
proto.cherry.product.Sku.prototype.getDefaultName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Sku} returns this
 */
proto.cherry.product.Sku.prototype.setDefaultName = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.SkuCreateReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.SkuCreateReq.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.SkuCreateReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.SkuCreateReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SkuCreateReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    skusList: jspb.Message.toObjectList(msg.getSkusList(),
    proto.cherry.product.Sku.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.SkuCreateReq}
 */
proto.cherry.product.SkuCreateReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.SkuCreateReq;
  return proto.cherry.product.SkuCreateReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.SkuCreateReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.SkuCreateReq}
 */
proto.cherry.product.SkuCreateReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cherry.product.Sku;
      reader.readMessage(value,proto.cherry.product.Sku.deserializeBinaryFromReader);
      msg.addSkus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.SkuCreateReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.SkuCreateReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.SkuCreateReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SkuCreateReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSkusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cherry.product.Sku.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Sku skus = 1;
 * @return {!Array<!proto.cherry.product.Sku>}
 */
proto.cherry.product.SkuCreateReq.prototype.getSkusList = function() {
  return /** @type{!Array<!proto.cherry.product.Sku>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.Sku, 1));
};


/**
 * @param {!Array<!proto.cherry.product.Sku>} value
 * @return {!proto.cherry.product.SkuCreateReq} returns this
*/
proto.cherry.product.SkuCreateReq.prototype.setSkusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cherry.product.Sku=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Sku}
 */
proto.cherry.product.SkuCreateReq.prototype.addSkus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cherry.product.Sku, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SkuCreateReq} returns this
 */
proto.cherry.product.SkuCreateReq.prototype.clearSkusList = function() {
  return this.setSkusList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.SkuQuery.repeatedFields_ = [3,5,6,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.SkuQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.SkuQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.SkuQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SkuQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tagsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    sortBysList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    idsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    pageSize: jspb.Message.getFieldWithDefault(msg, 7, 0),
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 8, 0),
    spuIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    deleted: jspb.Message.getFieldWithDefault(msg, 11, 0),
    launch: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.SkuQuery}
 */
proto.cherry.product.SkuQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.SkuQuery;
  return proto.cherry.product.SkuQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.SkuQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.SkuQuery}
 */
proto.cherry.product.SkuQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addSortBys(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIds(values[i]);
      }
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSpuIds(values[i]);
      }
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeleted(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLaunch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.SkuQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.SkuQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.SkuQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SkuQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getSortBysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getSpuIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getDeleted();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getLaunch();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.product.SkuQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.product.SkuQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string tags = 3;
 * @return {!Array<string>}
 */
proto.cherry.product.SkuQuery.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated string sort_bys = 5;
 * @return {!Array<string>}
 */
proto.cherry.product.SkuQuery.prototype.getSortBysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setSortBysList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.addSortBys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.clearSortBysList = function() {
  return this.setSortBysList([]);
};


/**
 * repeated int64 ids = 6;
 * @return {!Array<number>}
 */
proto.cherry.product.SkuQuery.prototype.getIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};


/**
 * optional int32 page_size = 7;
 * @return {number}
 */
proto.cherry.product.SkuQuery.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 page_current = 8;
 * @return {number}
 */
proto.cherry.product.SkuQuery.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated int64 spu_ids = 9;
 * @return {!Array<number>}
 */
proto.cherry.product.SkuQuery.prototype.getSpuIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setSpuIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.addSpuIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.clearSpuIdsList = function() {
  return this.setSpuIdsList([]);
};


/**
 * optional int32 status = 10;
 * @return {number}
 */
proto.cherry.product.SkuQuery.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 deleted = 11;
 * @return {number}
 */
proto.cherry.product.SkuQuery.prototype.getDeleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 launch = 12;
 * @return {number}
 */
proto.cherry.product.SkuQuery.prototype.getLaunch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuQuery} returns this
 */
proto.cherry.product.SkuQuery.prototype.setLaunch = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.SkuListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.SkuListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.SkuListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.SkuListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SkuListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.product.Sku.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.SkuListed}
 */
proto.cherry.product.SkuListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.SkuListed;
  return proto.cherry.product.SkuListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.SkuListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.SkuListed}
 */
proto.cherry.product.SkuListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.product.Sku;
      reader.readMessage(value,proto.cherry.product.Sku.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.SkuListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.SkuListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.SkuListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.SkuListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.product.Sku.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_current = 1;
 * @return {number}
 */
proto.cherry.product.SkuListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuListed} returns this
 */
proto.cherry.product.SkuListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.cherry.product.SkuListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuListed} returns this
 */
proto.cherry.product.SkuListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.product.SkuListed.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.SkuListed} returns this
 */
proto.cherry.product.SkuListed.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Sku records = 4;
 * @return {!Array<!proto.cherry.product.Sku>}
 */
proto.cherry.product.SkuListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.product.Sku>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.Sku, 4));
};


/**
 * @param {!Array<!proto.cherry.product.Sku>} value
 * @return {!proto.cherry.product.SkuListed} returns this
*/
proto.cherry.product.SkuListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.product.Sku=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Sku}
 */
proto.cherry.product.SkuListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.product.Sku, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.SkuListed} returns this
 */
proto.cherry.product.SkuListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.Brand.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.Brand.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.Brand} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.Brand.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enterpriseId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    img: jspb.Message.getFieldWithDefault(msg, 4, ""),
    imgUrl: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.Brand}
 */
proto.cherry.product.Brand.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.Brand;
  return proto.cherry.product.Brand.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.Brand} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.Brand}
 */
proto.cherry.product.Brand.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnterpriseId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImg(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setImgUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.Brand.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.Brand.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.Brand} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.Brand.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnterpriseId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getImg();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getImgUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.product.Brand.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Brand} returns this
 */
proto.cherry.product.Brand.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.product.Brand.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Brand} returns this
 */
proto.cherry.product.Brand.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 enterprise_id = 3;
 * @return {number}
 */
proto.cherry.product.Brand.prototype.getEnterpriseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.Brand} returns this
 */
proto.cherry.product.Brand.prototype.setEnterpriseId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string img = 4;
 * @return {string}
 */
proto.cherry.product.Brand.prototype.getImg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Brand} returns this
 */
proto.cherry.product.Brand.prototype.setImg = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string img_url = 5;
 * @return {string}
 */
proto.cherry.product.Brand.prototype.getImgUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.Brand} returns this
 */
proto.cherry.product.Brand.prototype.setImgUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.BrandQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.BrandQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.BrandQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.BrandQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enterpriseId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    merchantId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.BrandQuery}
 */
proto.cherry.product.BrandQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.BrandQuery;
  return proto.cherry.product.BrandQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.BrandQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.BrandQuery}
 */
proto.cherry.product.BrandQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEnterpriseId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMerchantId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.BrandQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.BrandQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.BrandQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.BrandQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnterpriseId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMerchantId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cherry.product.BrandQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.BrandQuery} returns this
 */
proto.cherry.product.BrandQuery.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cherry.product.BrandQuery.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.cherry.product.BrandQuery} returns this
 */
proto.cherry.product.BrandQuery.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 enterprise_id = 3;
 * @return {number}
 */
proto.cherry.product.BrandQuery.prototype.getEnterpriseId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.BrandQuery} returns this
 */
proto.cherry.product.BrandQuery.prototype.setEnterpriseId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 merchant_id = 4;
 * @return {number}
 */
proto.cherry.product.BrandQuery.prototype.getMerchantId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.BrandQuery} returns this
 */
proto.cherry.product.BrandQuery.prototype.setMerchantId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cherry.product.BrandListed.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cherry.product.BrandListed.prototype.toObject = function(opt_includeInstance) {
  return proto.cherry.product.BrandListed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cherry.product.BrandListed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.BrandListed.toObject = function(includeInstance, msg) {
  var f, obj = {
    pageCurrent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageSize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalRecords: jspb.Message.getFieldWithDefault(msg, 3, 0),
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.cherry.product.Brand.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cherry.product.BrandListed}
 */
proto.cherry.product.BrandListed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cherry.product.BrandListed;
  return proto.cherry.product.BrandListed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cherry.product.BrandListed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cherry.product.BrandListed}
 */
proto.cherry.product.BrandListed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageSize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRecords(value);
      break;
    case 4:
      var value = new proto.cherry.product.Brand;
      reader.readMessage(value,proto.cherry.product.Brand.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cherry.product.BrandListed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cherry.product.BrandListed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cherry.product.BrandListed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cherry.product.BrandListed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPageCurrent();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalRecords();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.cherry.product.Brand.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page_current = 1;
 * @return {number}
 */
proto.cherry.product.BrandListed.prototype.getPageCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.BrandListed} returns this
 */
proto.cherry.product.BrandListed.prototype.setPageCurrent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_size = 2;
 * @return {number}
 */
proto.cherry.product.BrandListed.prototype.getPageSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.BrandListed} returns this
 */
proto.cherry.product.BrandListed.prototype.setPageSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 total_records = 3;
 * @return {number}
 */
proto.cherry.product.BrandListed.prototype.getTotalRecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.cherry.product.BrandListed} returns this
 */
proto.cherry.product.BrandListed.prototype.setTotalRecords = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Brand records = 4;
 * @return {!Array<!proto.cherry.product.Brand>}
 */
proto.cherry.product.BrandListed.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.cherry.product.Brand>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cherry.product.Brand, 4));
};


/**
 * @param {!Array<!proto.cherry.product.Brand>} value
 * @return {!proto.cherry.product.BrandListed} returns this
*/
proto.cherry.product.BrandListed.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.cherry.product.Brand=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cherry.product.Brand}
 */
proto.cherry.product.BrandListed.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.cherry.product.Brand, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.cherry.product.BrandListed} returns this
 */
proto.cherry.product.BrandListed.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


goog.object.extend(exports, proto.cherry.product);
