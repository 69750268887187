import { PlusOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Descriptions, Form, Input, Row, Select, Table, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { namespace, ISpuSpec, ISku } from './model'
import { useDispatch, useSelector } from 'react-redux'
import useSearchParams from '@/hooks/useSearchParams'
import { useHistory } from 'react-router-dom'
import { GOODS_ADD_SKUS } from '@/router/config/member-manage/path'

interface IOption {
  id: number
  name: string
}
interface ISpec {
  id: number
  name: string
  optionsList?: IOption[]
}
// 全排列生成
function cartesianProductOf(...data) {
  return data.reduce(
    function(a, b) {
      var ret = []
      a.forEach(function(a) {
        b.forEach(function(b) {
          ret.push(a.concat([b]))
        })
      })
      return ret
    },
    [[]],
  )
}

// 生成列配置
function generateColumns(specs: ISpec[]) {
  const columns = []
  if (!specs || specs.length === 0) {
    return
  }
  for (const spec of specs) {
    if (spec.optionsList && spec.optionsList.length > 0) {
      columns.push({
        title: spec.name,
        key: `col-${spec.id}`,
        dataIndex: spec.id,
      })
    }
  }
  return columns
}
// 格式化远程sku数据filter
function formatSkuAttrsDataFromOrigin(skus: ISku[]) {
  const result = []
  if (!skus || !skus.length) {
    return result
  }
  for (const sku of skus) {
    if (sku.attrsList && sku.attrsList.length) {
      const filter = []
      for (const attr of sku.attrsList) {
        filter.push(`${attr.itemAttrId}/${attr.id}`)
      }
      result.push(filter)
    }
  }
  return result
}

function arrayDiff(match: string[], filter: string[]) {
  for (const item of match) {
    if (!filter.includes(item)) {
      return false
    }
  }
  return true
}

function makeDiff(rowData: any[], filters: string[][]) {
  if (!filters || !filters.length) {
    return rowData
  }
  for (const row of rowData) {
    const match: string[] = row.filter
    for (const filter of filters) {
      if (match.length !== filter.length) {
        continue
      }
      const isEqual = arrayDiff(match, filter)
      if (isEqual) {
        row.invalid = true
      }
    }
  }
  return rowData.filter(data => !data.invalid)
}

// 生成行数据
function generateRowData(specs: ISpec[], skus?: ISku[]) {
  let result = []
  if (!specs || specs.length === 0) {
    return result
  }
  const specOptionList = []
  // 处理数据
  for (const spec of specs) {
    if (spec.optionsList && spec.optionsList.length > 0) {
      specOptionList.push(spec.optionsList.map(item => ({ ...item, dataIndex: spec.id })))
    }
  }
  // 生成全排列
  const combinations: any[] = cartesianProductOf(...specOptionList)
  //生成表格数据
  for (const row of combinations) {
    const rowData = {}
    for (const cell of row) {
      // tip: filter字段用于后续的筛选已经选择的数据
      rowData[cell['dataIndex']] = cell.name
      if (rowData['filter']) {
        rowData['filter'].push(`${cell['dataIndex']}/${cell.id}`)
      } else {
        rowData['filter'] = [`${cell['dataIndex']}/${cell.id}`]
      }
    }
    result.push(rowData)
  }
  const filters = formatSkuAttrsDataFromOrigin(skus)
  // 表格数据和线上数据对比排空
  return makeDiff(result, filters)
}

const Item = Descriptions.Item
const FormItem = Form.Item

// 表的列配置和表数据生成的时机为：首次渲染、以后每次成功修改商品规格后

interface SearchParams {
  goodsId: number
  merchantId: number
  step: number
}

const SpecManage: React.FC = () => {
  const [columns, setColumns] = useState<any[]>([])
  const [dataSource, setDataSource] = useState<any[]>([])
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [visible, setVisible] = useState(false)
  const history = useHistory()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { spu, skus, itemAttrList }: ISpuSpec = useSelector(state => state[namespace])
  const { goodsId: id, merchantId } = useSearchParams<SearchParams>()
  function handleFinish(vals: any) {
    dispatch({
      type: `${namespace}/updateSpu`,
      payload: {
        id,
        attrsList: vals.specs,
      },
      callback: () => {
        // 请求spu
        dispatch({
          type: `${namespace}/findSpu`,
          payload: {
            id,
          },
        })
        // 请求已选择的sku列表
        dispatch({
          type: `${namespace}/findSkuList`,
          payload: {
            spuidsList: [id],
            pageSize: 1000,
          },
        })
        setVisible(true)
      },
    })
  }
  useEffect(() => {
    // 请求spu
    dispatch({
      type: `${namespace}/findSpu`,
      payload: {
        id,
      },
    })
    // 请求已选择的sku列表
    dispatch({
      type: `${namespace}/findSkuList`,
      payload: {
        spuIdsList: [id],
        pageSize: 1000,
      },
    })
    // 请求已选择的sku列表
    dispatch({
      type: `${namespace}/findAttrs`,
    })
  }, [])
  useEffect(() => {
    setColumns(generateColumns(spu?.attrsList))
    setDataSource(generateRowData(spu?.attrsList, skus))
  }, [skus, spu?.attrsList])
  // 设置表单数据
  useEffect(() => {
    spu && form.setFieldsValue({ specs: spu.attrsList })
  }, [spu?.attrsList])
  function handleChangeSelection(selectedKeys: any[], selectedRows: any[]) {
    setSelectedKeys(selectedKeys)
    setSelectedRows(selectedRows)
  }
  function addSkuList() {
    if (!selectedRows.length) {
      setVisible(false)
      return
    }
    const skusList = selectedRows.map(row => {
      const attrsList = row.filter.map(item => ({ itemAttrId: item.split('/')[0], id: item.split('/')[1] }))
      return {
        spuId: spu.id,
        stallId: spu.stallId,
        attrsList,
      }
    })
    dispatch({
      type: `${namespace}/createSkuList`,
      payload: {
        skusList,
      },
      callback: () => {
        // 请求已选择的sku列表
        // dispatch({
        //   type: `${namespace}/findSkuList`,
        //   payload: {
        //     spuIdsList: [id],
        //     pageSize: 1000,
        //   },
        // })
        // setVisible(false)
        // // 重置已选择行
        // setSelectedKeys([])
        // setSelectedRows([])
        history.push(`${GOODS_ADD_SKUS}?goodsId=${id}&step=2&merchantId=${merchantId}`)
      },
    })
  }
  return (
    <>
      <Descriptions title="商品信息" column={5}>
        <Item label="销售名称">{spu?.name}</Item>
        <Item label="商户">{spu?.merchantName}</Item>
        <Item label="产地">{spu?.placeAreaNamesList.join('/')}</Item>
        <Item label="品牌">{spu?.brandName}</Item>
        <Item label="平台分类">{spu?.itemCategoryNamesList.join('/')}</Item>
        <Item label="销售分类">{spu?.categoryNamesList.join('/')}</Item>
      </Descriptions>
      <p className={styles.descTitle}>商品规格</p>
      <Alert
        className={styles.alert}
        showIcon
        type="info"
        message="最多可设置三种规格类型，每种规格类型最多设置十种规格选项，删除已存在的规格类型或规格选项将创建新的SPU。"
      />
      <Form form={form} onFinish={handleFinish}>
        <Form.List
          name="specs"
          rules={[
            {
              validator: async (_, specList) => {
                // 判断至少包含一种规格类型
                if (!specList || specList.length < 1) {
                  return Promise.reject(new Error('至少选择一项规格类型！'))
                }
                // 判断多个规格类型不一样
                let lastSpecId = 0
                for (const spec of specList) {
                  if (!spec) {
                    continue
                  }
                  if (spec.id === lastSpecId) {
                    return Promise.reject(new Error('请选择不同类型的规格选项！'))
                  }
                  lastSpecId = spec.id
                }
              },
            },
          ]}>
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map(field => (
                  <>
                    <FormItem
                      className={styles.col}
                      wrapperCol={{ xxl: { span: 8 }, xl: { span: 10 }, md: { span: 18 } }}
                      key={`type-${field.key}`}
                      label="规格类型"
                      required>
                      <FormItem noStyle name={[field.name, 'id']} rules={[{ required: true, message: '请选择规格类型' }]}>
                        <Select>
                          {itemAttrList.map(({ id, name }) => (
                            <Select.Option value={id} key={id}>
                              {name}
                            </Select.Option>
                          ))}
                        </Select>
                      </FormItem>
                      <Button style={{ marginLeft: 6 }} onClick={() => remove(field.name)} type="link">
                        删除规格类型
                      </Button>
                    </FormItem>
                    <Row>
                      <Form.List
                        rules={[
                          {
                            validator: async (_, optionsList) => {
                              if (!optionsList || optionsList.length < 1 || optionsList.length > 10) {
                                return Promise.reject(new Error('至少填写一项规格选项！'))
                              }
                            },
                          },
                        ]}
                        name={[field.name, 'optionsList']}
                        key={`items-${field.key}`}>
                        {(innerFields, { add: innerAdd, remove: innerRemove }, { errors }) => (
                          <>
                            {innerFields.map((innerField, index) => (
                              <Col xxl={{ span: 4 }} xl={{ span: 6 }} md={{ span: 12 }} className={styles.col}>
                                <Form.Item label={index === 0 ? '规格选项' : ''} required={true} key={innerField.key}>
                                  <Form.Item
                                    name={[innerField.name, 'name']}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: '请输入规格选项',
                                      },
                                    ]}
                                    noStyle>
                                    <Input placeholder="请输入规格选项" />
                                  </Form.Item>
                                  <Form.Item name={[innerField.name, 'id']} hidden noStyle>
                                    <Input />
                                  </Form.Item>
                                  {innerFields.length > 1 ? (
                                    <Button type="link" style={{ marginLeft: 6 }} onClick={() => innerRemove(innerField.name)}>
                                      删除
                                    </Button>
                                  ) : null}
                                </Form.Item>
                              </Col>
                            ))}
                            <Col xxl={{ span: 4 }} xl={{ span: 6 }} md={{ span: 12 }}>
                              <Form.Item>
                                <Button
                                  disabled={innerFields.length >= 10}
                                  style={{ marginLeft: 6 }}
                                  type="dashed"
                                  onClick={() => innerAdd({ name: '', id: 0 })}
                                  icon={<PlusOutlined />}>
                                  新增规格选项
                                </Button>
                                <Form.ErrorList errors={errors} />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </>
                ))}
                <Form.Item>
                  <Button disabled={fields.length >= 3} type="primary" onClick={() => add()} icon={<PlusOutlined />}>
                    新增规格类型
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )
          }}
        </Form.List>
        <FormItem>
          <Button type="primary" htmlType="submit" style={{ marginRight: 14 }}>
            保存商品规格
          </Button>
          <Button type="primary" onClick={() => setVisible(true)}>
            查看未添加SKU列表
          </Button>
        </FormItem>
      </Form>
      <Modal
        className={styles.skuSelectionArea}
        title="未添加SKU列表"
        visible={visible}
        onOk={addSkuList}
        onCancel={() => setVisible(false)}>
        <Table
          size="small"
          rowSelection={{ selectedRowKeys: selectedKeys, onChange: handleChangeSelection }}
          dataSource={dataSource}
          columns={columns}
          pagination={{ pageSize: 10 }}
          rowKey={record => record.filter.join('')}
        />
      </Modal>
    </>
  )
}

export default SpecManage
