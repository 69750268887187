import routeConfig from '../config'
import { IRouteConfig } from '../typing'

export function findRouteConfigByPathName(pathname: string, config = routeConfig): IRouteConfig {
  for (const route of config) {
    if (route.path === pathname && route.exact) {
      return route
    }
    if (route.routes) {
      return findRouteConfigByPathName(pathname, route.routes)
    }
  }
}
