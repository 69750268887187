import { MEMBER_MANAGEMENT, PROVIDER_MANAGEMENT } from '@/router/config/member-manage/path'
import { META_DATA_MANAGE, SYS_CLASSFICATION } from '@/router/config/metadata-manage/path'
import { UserOutlined } from '@ant-design/icons'

export interface IMenuItem {
  key: string
  title: string
  icon?: React.ComponentType<any>
  children?: IMenuItem[]
}

const MenuConfig: IMenuItem[] = [
  {
    key: MEMBER_MANAGEMENT,
    title: '会员管理',
    icon: UserOutlined,
    children: [
      {
        key: PROVIDER_MANAGEMENT,
        title: '供应商管理',
      },
    ],
  },
  {
    key: META_DATA_MANAGE,
    title: '元数据管理',
    icon: UserOutlined,
    children: [
      {
        key: SYS_CLASSFICATION,
        title: '系统分类',
      },
    ],
  },
]

export default MenuConfig
