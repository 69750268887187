import React, { useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import routes from './config/index'
import RouteWithSubRoutes from './routeWithSubRoute'
import NoDataPage from '@/pages/other/404'
import ErroryBoundary from '@/components/error-boundary'
import { setHistory } from '@/utils/request/getHistory'

const Router: React.FC = () => {
  const history = useHistory()
  useEffect(() => {
    setHistory(history)
  }, [])
  return (
    <ErroryBoundary>
      <Switch>
        {routes.map(route => {
          return <RouteWithSubRoutes key={route.path} {...route} />
        })}
        <Route component={NoDataPage} />
      </Switch>
    </ErroryBoundary>
  )
}

export default Router
