/**
 * @fileoverview gRPC-Web generated client stub for cherry.user
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as cherry_user_user_pb from '../../cherry/user/user_pb';


export class UserServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoFindUserList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_user_user_pb.UserListed,
    (request: cherry_user_user_pb.UserQuery) => {
      return request.serializeBinary();
    },
    cherry_user_user_pb.UserListed.deserializeBinary
  );

  findUserList(
    request: cherry_user_user_pb.UserQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_user_user_pb.UserListed>;

  findUserList(
    request: cherry_user_user_pb.UserQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_user_user_pb.UserListed) => void): grpcWeb.ClientReadableStream<cherry_user_user_pb.UserListed>;

  findUserList(
    request: cherry_user_user_pb.UserQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_user_user_pb.UserListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.user.UserService/FindUserList',
        request,
        metadata || {},
        this.methodInfoFindUserList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.user.UserService/FindUserList',
    request,
    metadata || {},
    this.methodInfoFindUserList);
  }

  methodInfoFindUser = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_user_user_pb.User,
    (request: cherry_user_user_pb.UserQuery) => {
      return request.serializeBinary();
    },
    cherry_user_user_pb.User.deserializeBinary
  );

  findUser(
    request: cherry_user_user_pb.UserQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_user_user_pb.User>;

  findUser(
    request: cherry_user_user_pb.UserQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_user_user_pb.User) => void): grpcWeb.ClientReadableStream<cherry_user_user_pb.User>;

  findUser(
    request: cherry_user_user_pb.UserQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_user_user_pb.User) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.user.UserService/FindUser',
        request,
        metadata || {},
        this.methodInfoFindUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.user.UserService/FindUser',
    request,
    metadata || {},
    this.methodInfoFindUser);
  }

  methodInfoCreateUser = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_user_user_pb.User,
    (request: cherry_user_user_pb.User) => {
      return request.serializeBinary();
    },
    cherry_user_user_pb.User.deserializeBinary
  );

  createUser(
    request: cherry_user_user_pb.User,
    metadata: grpcWeb.Metadata | null): Promise<cherry_user_user_pb.User>;

  createUser(
    request: cherry_user_user_pb.User,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_user_user_pb.User) => void): grpcWeb.ClientReadableStream<cherry_user_user_pb.User>;

  createUser(
    request: cherry_user_user_pb.User,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_user_user_pb.User) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.user.UserService/CreateUser',
        request,
        metadata || {},
        this.methodInfoCreateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.user.UserService/CreateUser',
    request,
    metadata || {},
    this.methodInfoCreateUser);
  }

  methodInfoUpdateUser = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_user_user_pb.User,
    (request: cherry_user_user_pb.User) => {
      return request.serializeBinary();
    },
    cherry_user_user_pb.User.deserializeBinary
  );

  updateUser(
    request: cherry_user_user_pb.User,
    metadata: grpcWeb.Metadata | null): Promise<cherry_user_user_pb.User>;

  updateUser(
    request: cherry_user_user_pb.User,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_user_user_pb.User) => void): grpcWeb.ClientReadableStream<cherry_user_user_pb.User>;

  updateUser(
    request: cherry_user_user_pb.User,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_user_user_pb.User) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.user.UserService/UpdateUser',
        request,
        metadata || {},
        this.methodInfoUpdateUser,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.user.UserService/UpdateUser',
    request,
    metadata || {},
    this.methodInfoUpdateUser);
  }

  methodInfoSaveUserDevice = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_user_user_pb.UserDevice,
    (request: cherry_user_user_pb.UserDevice) => {
      return request.serializeBinary();
    },
    cherry_user_user_pb.UserDevice.deserializeBinary
  );

  saveUserDevice(
    request: cherry_user_user_pb.UserDevice,
    metadata: grpcWeb.Metadata | null): Promise<cherry_user_user_pb.UserDevice>;

  saveUserDevice(
    request: cherry_user_user_pb.UserDevice,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_user_user_pb.UserDevice) => void): grpcWeb.ClientReadableStream<cherry_user_user_pb.UserDevice>;

  saveUserDevice(
    request: cherry_user_user_pb.UserDevice,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_user_user_pb.UserDevice) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.user.UserService/SaveUserDevice',
        request,
        metadata || {},
        this.methodInfoSaveUserDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.user.UserService/SaveUserDevice',
    request,
    metadata || {},
    this.methodInfoSaveUserDevice);
  }

  methodInfoFindUserDeviceList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_user_user_pb.UserDeviceListed,
    (request: cherry_user_user_pb.UserQuery) => {
      return request.serializeBinary();
    },
    cherry_user_user_pb.UserDeviceListed.deserializeBinary
  );

  findUserDeviceList(
    request: cherry_user_user_pb.UserQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_user_user_pb.UserDeviceListed>;

  findUserDeviceList(
    request: cherry_user_user_pb.UserQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_user_user_pb.UserDeviceListed) => void): grpcWeb.ClientReadableStream<cherry_user_user_pb.UserDeviceListed>;

  findUserDeviceList(
    request: cherry_user_user_pb.UserQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_user_user_pb.UserDeviceListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.user.UserService/FindUserDeviceList',
        request,
        metadata || {},
        this.methodInfoFindUserDeviceList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.user.UserService/FindUserDeviceList',
    request,
    metadata || {},
    this.methodInfoFindUserDeviceList);
  }

}

