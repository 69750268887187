import { itemServiceClient, productServiceClient } from '@/clients'
import { ItemCategoryQuery, ItemCategoryListed, ItemCategory } from '@/proto/cherry/item/item_pb'
import makeRequest from '@/utils/request/makeRequest'
import { message } from 'antd'
import { execFunction, Model } from 'wbd-frontend-kit'
export const namespace = 'metadata-manage-sys-classfication'

interface ICategoryCascader {
  title: string
  key: string | number
  children?: ICategoryCascader[]
}
export interface ISysClassfication {
  itemCategorys: ICategoryCascader[]
  itemCategory: ItemCategory.AsObject
}

const initState: ISysClassfication = {
  itemCategorys: [],
  itemCategory: null,
}

function findParentCategory(result: ICategoryCascader[], row: ItemCategory.AsObject) {
  if (!result || !result.length) {
    return null
  }
  for (const item of result) {
    // 直接找到
    if (item.key === row.parentId) {
      return item
    }
    // 叶节点
    if (!item.children) {
      continue
    }
    const fined = findParentCategory(item.children, row)
    if (fined) {
      return fined
    }
  }
}

// 支持理论上无限层级的的数据格式化
function formatCategorysData(categorys: ItemCategory.AsObject[]) {
  const result: ICategoryCascader[] = []
  if (!categorys || !categorys.length) {
    return result
  }
  for (const row of categorys) {
    const parent = findParentCategory(result, row)
    if (!parent) {
      result.push({ title: row.name, key: row.id, children: [] })
    } else {
      parent.children.push({ title: row.name, key: row.id, children: [] })
    }
  }
  return result
}

export default {
  namespace,
  state: initState,
  effects: {
    // 获取标准分类
    *queryItemCategoryList(_, { call, put }) {
      const req = makeRequest<ItemCategoryQuery>(ItemCategoryQuery)
      try {
        const res: ItemCategoryListed = yield call(itemServiceClient.findItemCategoryTree.bind(itemServiceClient), req)
        const obj = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            itemCategorys: formatCategorysData(obj.recordsList),
          },
        })
      } catch (error) {}
    },
    // 获取单个分类信息
    *queryItemCategory({ payload }, { call, put }) {
      // params: id
      const req = makeRequest<ItemCategoryQuery>(ItemCategoryQuery, payload)
      try {
        const res: ItemCategory = yield call(productServiceClient.findItemCategory.bind(productServiceClient), req)
        const obj = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            itemCategory: {
              ...obj,
              imgUrl: [
                {
                  name: 'avatar.jpg',
                  url: obj.imgUrl,
                  status: 'done',
                  uid: '-1',
                },
              ],
            },
          },
        })
      } catch (error) {}
    },
    // 更新单个分类信息
    *updateItemCategory({ payload, callback }, { call, put }) {
      // params: id
      const req = makeRequest<ItemCategory>(ItemCategory, payload)
      try {
        const res: ItemCategory = yield call(productServiceClient.updateItemCategory.bind(productServiceClient), req)
        const obj = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            itemCategory: {
              ...obj,
              imgUrl: [
                {
                  name: 'avatar.jpg',
                  url: obj.imgUrl,
                  status: 'done',
                  uid: '-1',
                },
              ],
            },
          },
        })
        execFunction(callback)
      } catch (error) {}
    },
    // 删除单个分类信息
    *deleteItemCategory({ payload }, { call, put }) {
      // params: id
      const req = makeRequest<ItemCategory>(ItemCategory, payload)
      try {
        yield call(productServiceClient.updateItemCategory.bind(productServiceClient), req)
        message.success('删除分类成功！')
        yield put({
          type: 'setState',
          payload: {
            itemCategory: null,
          },
        })
        yield put({
          type: 'queryItemCategoryList',
        })
      } catch (error) {}
    },
    // 创建单个分类信息
    *createItemCategory({ payload, callback }, { call, put }) {
      // params: id
      const req = makeRequest<ItemCategory>(ItemCategory, payload)
      try {
        const res: ItemCategory = yield call(productServiceClient.createItemCategory.bind(productServiceClient), req)
        const obj = res.toObject()
        if (obj.id) {
          execFunction(callback)
        }
      } catch (error) {}
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload }
    },
  },
} as Model
