import { execFunction } from 'wbd-frontend-kit'
import { productServiceClient } from '@/clients'
import { SkuQuery, SkuListed, Sku, Spu, SpuQuery } from '@/proto/cherry/product/product_pb'
import makeRequest from '@/utils/request/makeRequest'

export const namespace = 'member-provider-goods-add-skumanage'
export interface IDvaState {
  spu: Spu.AsObject
  skuList: Sku.AsObject[]
  totalCount: number
}
const State: IDvaState = {
  spu: new Spu().toObject(),
  skuList: [],
  totalCount: 0,
}

export default {
  namespace,
  state: State,
  effects: {
    *findSpu({ payload }, { call, put }) {
      try {
        const req = makeRequest<SpuQuery>(SpuQuery, payload)
        const res: Spu = yield call(productServiceClient.findSpu.bind(productServiceClient), req)
        const spu = res.toObject()
        const newSpu = {
          ...spu,
          imgUrl: [
            {
              name: 'avatar.png',
              url: spu.imgUrl,
              status: 'done',
              uid: '-1',
            },
          ],
        }
        yield put({
          type: 'setState',
          payload: {
            spu: newSpu,
          },
        })
      } catch (error) {}
    },
    *fetchSkuList({ payload }, { call, put }) {
      try {
        const req = makeRequest<SkuQuery>(SkuQuery, payload)
        const res: SkuListed = yield call(productServiceClient.findSkuList.bind(productServiceClient), req)
        const obj = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            skuList: obj.recordsList,
            totalCount: obj.totalRecords,
          },
        })
      } catch (e) {}
    },
    *updateSpu({ payload, callback }, { call }) {
      try {
        const req = makeRequest<Spu>(Spu, payload)
        const res: Spu = yield call(productServiceClient.updateSpu.bind(productServiceClient), req)
        const obj = res.toObject()
        if (obj.id) {
          execFunction(callback)
        }
      } catch (error) {}
    },
    *updateSku({ payload, callback }, { call }) {
      try {
        const req = makeRequest<Sku>(Sku, payload)
        const res: Sku = yield call(productServiceClient.updateSku.bind(productServiceClient), req)
        const obj = res.toObject()
        if (obj.id) {
          execFunction(callback)
        }
      } catch (error) {}
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload }
    },
  },
}
