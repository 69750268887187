import { IRouteConfig } from '../../typing'
import Layout from '@/pages/layout'
import { META_DATA_MANAGE, OSS_UPLOAD, SYS_CLASSFICATION } from './path'
import { SysClassfication, OssUpload } from '@/pages/metadata-manage'

const routes: IRouteConfig = {
  path: META_DATA_MANAGE,
  component: Layout,
  title: '元数据管理',
  routes: [
    {
      path: SYS_CLASSFICATION,
      component: SysClassfication,
      title: '系统分类',
      exact: true,
      auth: true,
    },
    {
      path: OSS_UPLOAD,
      component: OssUpload,
      title: '上传',
      exact: true,
      auth: true,
    },
  ],
}

export default routes
