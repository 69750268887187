import { execFunction, Model } from 'wbd-frontend-kit'
import { Spu } from '@/proto/cherry/product/product_pb'
import { StallQuery, StallListed, Stall, MerchantQuery, Merchant } from '@/proto/cherry/enterprise/enterprise_pb'
import makeRequest from '@/utils/request/makeRequest'

import { enterpriseServiceClient, productServiceClient } from '@/clients'

export type ISpu = Spu.AsObject
export type IShop = Stall.AsObject

export const namespace = 'member-provider-goods-add-basic'
export interface ISpuDetail {
  shops: IShop[]
  merchantName: string
}
const initState: ISpuDetail = {
  shops: [],
  merchantName: '',
}

export default {
  namespace,
  state: initState,
  effects: {
    *findShop({ payload }, { call, put }) {
      try {
        const req = makeRequest<StallQuery>(StallQuery, payload)
        const res: StallListed = yield call(enterpriseServiceClient.findStallList.bind(enterpriseServiceClient), req)
        const shops = res.toObject()
        yield put({
          type: 'setState',
          payload: {
            shops: shops.recordsList,
          },
        })
      } catch (error) {}
    },
    *findMerchant({ payload }, { call, put }) {
      try {
        const req = makeRequest<MerchantQuery>(MerchantQuery, payload)
        const res: Merchant = yield call(enterpriseServiceClient.findMerchant.bind(enterpriseServiceClient), req)
        const merchantName = res.getName()
        yield put({
          type: 'setState',
          payload: {
            merchantName,
          },
        })
      } catch (error) {}
    },
    *createSpu({ payload, callback }, { call }) {
      try {
        const req = makeRequest<Spu>(Spu, payload)
        const res: Spu = yield call(productServiceClient.createSpu.bind(productServiceClient), req)
        const spu = res.toObject()
        if (spu.id) {
          execFunction(callback, spu.id)
        }
      } catch (error) {}
    },
  },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload }
    },
  },
} as Model
