/**
 * @fileoverview gRPC-Web generated client stub for cherry.enterprise
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as cherry_enterprise_enterprise_pb from '../../cherry/enterprise/enterprise_pb';


export class EnterpriseServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodInfoFindEnterpriseList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.EnterpriseListed,
    (request: cherry_enterprise_enterprise_pb.EnterpriseQuery) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.EnterpriseListed.deserializeBinary
  );

  findEnterpriseList(
    request: cherry_enterprise_enterprise_pb.EnterpriseQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.EnterpriseListed>;

  findEnterpriseList(
    request: cherry_enterprise_enterprise_pb.EnterpriseQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.EnterpriseListed) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.EnterpriseListed>;

  findEnterpriseList(
    request: cherry_enterprise_enterprise_pb.EnterpriseQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.EnterpriseListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/FindEnterpriseList',
        request,
        metadata || {},
        this.methodInfoFindEnterpriseList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/FindEnterpriseList',
    request,
    metadata || {},
    this.methodInfoFindEnterpriseList);
  }

  methodInfoFindEnterprise = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.Enterprise,
    (request: cherry_enterprise_enterprise_pb.EnterpriseQuery) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.Enterprise.deserializeBinary
  );

  findEnterprise(
    request: cherry_enterprise_enterprise_pb.EnterpriseQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.Enterprise>;

  findEnterprise(
    request: cherry_enterprise_enterprise_pb.EnterpriseQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Enterprise) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.Enterprise>;

  findEnterprise(
    request: cherry_enterprise_enterprise_pb.EnterpriseQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Enterprise) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/FindEnterprise',
        request,
        metadata || {},
        this.methodInfoFindEnterprise,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/FindEnterprise',
    request,
    metadata || {},
    this.methodInfoFindEnterprise);
  }

  methodInfoCreateEnterprise = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.Enterprise,
    (request: cherry_enterprise_enterprise_pb.Enterprise) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.Enterprise.deserializeBinary
  );

  createEnterprise(
    request: cherry_enterprise_enterprise_pb.Enterprise,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.Enterprise>;

  createEnterprise(
    request: cherry_enterprise_enterprise_pb.Enterprise,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Enterprise) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.Enterprise>;

  createEnterprise(
    request: cherry_enterprise_enterprise_pb.Enterprise,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Enterprise) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/CreateEnterprise',
        request,
        metadata || {},
        this.methodInfoCreateEnterprise,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/CreateEnterprise',
    request,
    metadata || {},
    this.methodInfoCreateEnterprise);
  }

  methodInfoUpdateEnterprise = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.Enterprise,
    (request: cherry_enterprise_enterprise_pb.Enterprise) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.Enterprise.deserializeBinary
  );

  updateEnterprise(
    request: cherry_enterprise_enterprise_pb.Enterprise,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.Enterprise>;

  updateEnterprise(
    request: cherry_enterprise_enterprise_pb.Enterprise,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Enterprise) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.Enterprise>;

  updateEnterprise(
    request: cherry_enterprise_enterprise_pb.Enterprise,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Enterprise) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/UpdateEnterprise',
        request,
        metadata || {},
        this.methodInfoUpdateEnterprise,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/UpdateEnterprise',
    request,
    metadata || {},
    this.methodInfoUpdateEnterprise);
  }

  methodInfoCreateMerchant = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.Merchant,
    (request: cherry_enterprise_enterprise_pb.Merchant) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.Merchant.deserializeBinary
  );

  createMerchant(
    request: cherry_enterprise_enterprise_pb.Merchant,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.Merchant>;

  createMerchant(
    request: cherry_enterprise_enterprise_pb.Merchant,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Merchant) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.Merchant>;

  createMerchant(
    request: cherry_enterprise_enterprise_pb.Merchant,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Merchant) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/CreateMerchant',
        request,
        metadata || {},
        this.methodInfoCreateMerchant,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/CreateMerchant',
    request,
    metadata || {},
    this.methodInfoCreateMerchant);
  }

  methodInfoFindMerchantList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.MerchantListed,
    (request: cherry_enterprise_enterprise_pb.MerchantQuery) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.MerchantListed.deserializeBinary
  );

  findMerchantList(
    request: cherry_enterprise_enterprise_pb.MerchantQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.MerchantListed>;

  findMerchantList(
    request: cherry_enterprise_enterprise_pb.MerchantQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.MerchantListed) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.MerchantListed>;

  findMerchantList(
    request: cherry_enterprise_enterprise_pb.MerchantQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.MerchantListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/FindMerchantList',
        request,
        metadata || {},
        this.methodInfoFindMerchantList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/FindMerchantList',
    request,
    metadata || {},
    this.methodInfoFindMerchantList);
  }

  methodInfoFindMerchant = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.Merchant,
    (request: cherry_enterprise_enterprise_pb.MerchantQuery) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.Merchant.deserializeBinary
  );

  findMerchant(
    request: cherry_enterprise_enterprise_pb.MerchantQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.Merchant>;

  findMerchant(
    request: cherry_enterprise_enterprise_pb.MerchantQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Merchant) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.Merchant>;

  findMerchant(
    request: cherry_enterprise_enterprise_pb.MerchantQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Merchant) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/FindMerchant',
        request,
        metadata || {},
        this.methodInfoFindMerchant,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/FindMerchant',
    request,
    metadata || {},
    this.methodInfoFindMerchant);
  }

  methodInfoUpdateMerchant = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.Merchant,
    (request: cherry_enterprise_enterprise_pb.Merchant) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.Merchant.deserializeBinary
  );

  updateMerchant(
    request: cherry_enterprise_enterprise_pb.Merchant,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.Merchant>;

  updateMerchant(
    request: cherry_enterprise_enterprise_pb.Merchant,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Merchant) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.Merchant>;

  updateMerchant(
    request: cherry_enterprise_enterprise_pb.Merchant,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Merchant) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/UpdateMerchant',
        request,
        metadata || {},
        this.methodInfoUpdateMerchant,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/UpdateMerchant',
    request,
    metadata || {},
    this.methodInfoUpdateMerchant);
  }

  methodInfoCreateStall = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.Stall,
    (request: cherry_enterprise_enterprise_pb.Stall) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.Stall.deserializeBinary
  );

  createStall(
    request: cherry_enterprise_enterprise_pb.Stall,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.Stall>;

  createStall(
    request: cherry_enterprise_enterprise_pb.Stall,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Stall) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.Stall>;

  createStall(
    request: cherry_enterprise_enterprise_pb.Stall,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Stall) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/CreateStall',
        request,
        metadata || {},
        this.methodInfoCreateStall,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/CreateStall',
    request,
    metadata || {},
    this.methodInfoCreateStall);
  }

  methodInfoUpdateStall = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.Stall,
    (request: cherry_enterprise_enterprise_pb.Stall) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.Stall.deserializeBinary
  );

  updateStall(
    request: cherry_enterprise_enterprise_pb.Stall,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.Stall>;

  updateStall(
    request: cherry_enterprise_enterprise_pb.Stall,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Stall) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.Stall>;

  updateStall(
    request: cherry_enterprise_enterprise_pb.Stall,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Stall) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/UpdateStall',
        request,
        metadata || {},
        this.methodInfoUpdateStall,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/UpdateStall',
    request,
    metadata || {},
    this.methodInfoUpdateStall);
  }

  methodInfoFindStallList = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.StallListed,
    (request: cherry_enterprise_enterprise_pb.StallQuery) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.StallListed.deserializeBinary
  );

  findStallList(
    request: cherry_enterprise_enterprise_pb.StallQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.StallListed>;

  findStallList(
    request: cherry_enterprise_enterprise_pb.StallQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.StallListed) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.StallListed>;

  findStallList(
    request: cherry_enterprise_enterprise_pb.StallQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.StallListed) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/FindStallList',
        request,
        metadata || {},
        this.methodInfoFindStallList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/FindStallList',
    request,
    metadata || {},
    this.methodInfoFindStallList);
  }

  methodInfoFindStall = new grpcWeb.AbstractClientBase.MethodInfo(
    cherry_enterprise_enterprise_pb.Stall,
    (request: cherry_enterprise_enterprise_pb.StallQuery) => {
      return request.serializeBinary();
    },
    cherry_enterprise_enterprise_pb.Stall.deserializeBinary
  );

  findStall(
    request: cherry_enterprise_enterprise_pb.StallQuery,
    metadata: grpcWeb.Metadata | null): Promise<cherry_enterprise_enterprise_pb.Stall>;

  findStall(
    request: cherry_enterprise_enterprise_pb.StallQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Stall) => void): grpcWeb.ClientReadableStream<cherry_enterprise_enterprise_pb.Stall>;

  findStall(
    request: cherry_enterprise_enterprise_pb.StallQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.Error,
               response: cherry_enterprise_enterprise_pb.Stall) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/cherry.enterprise.EnterpriseService/FindStall',
        request,
        metadata || {},
        this.methodInfoFindStall,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/cherry.enterprise.EnterpriseService/FindStall',
    request,
    metadata || {},
    this.methodInfoFindStall);
  }

}

