export const INDEX = '/member'
export const MEMBER_MANAGEMENT = `${INDEX}`
export const MEMBER_MANAGEMENT_ADD = `${INDEX}/add`

export const PROVIDER_MANAGEMENT = `${INDEX}/provider`
export const PROVIDER_CLASSIFICATION_MANAGEMENT = `${INDEX}/provider/classification`
export const PROVIDER_GOODS_MANAGEMENT = `${INDEX}/provider/goods`
export const GOODS_DETAIL = `${PROVIDER_GOODS_MANAGEMENT}/detail`
export const SPEC_MANAGEMENT = `${PROVIDER_GOODS_MANAGEMENT}/spec`
export const SKU_MANAGEMENT = `${PROVIDER_GOODS_MANAGEMENT}/skus`
export const GOODS_ADD = `${PROVIDER_GOODS_MANAGEMENT}/add`
export const GOODS_ADD_BASIC = `${GOODS_ADD}/basic`
export const GOODS_ADD_SPECS = `${GOODS_ADD}/specs`
export const GOODS_ADD_SKUS = `${GOODS_ADD}/skus`
